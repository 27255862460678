import React from "react";
import CustomInputUploadFileForm from "../../../common/inputs/CustomInputUploadFileForm";
import { Input } from "antd";

interface Props {
    label: string;
    name: string;
    htmlFor: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DocumentNameInput: React.FC<Props> = ({
    label,
    name,
    htmlFor,
    onChange
}) => {
    return (
        <CustomInputUploadFileForm
            Component={Input}
            label={label}
            htmlFor={htmlFor}
            name={name}
            props={{ onChange: onChange }}
            block
            required
            rules={[
                { required: true, message: '' },
                {
                    pattern: /^[-\w^&'@{}[\],$=!#().%+~ ]+$/,
                    message: 'Invalid file name',
                },
                {
                    max: 100,
                    message: 'Value should be less than 100 characters',
                },
            ]}
        />
    );
}

export default DocumentNameInput;