import React, { useState } from 'react';
import { Form } from 'antd';
import { CSVLink } from 'react-csv';
import ModalTitle from '../../../common/modalTitle/ModalTitle';
import {
    MFRProject,
    ProjectReferences,
    ProjectTableFields,
} from '../../../../models/project/types';
import { Text, CloseIcon } from '../../../../styles/common.styles';
import { blueDark01 } from '../../../../styles/colors';
import { ExportProjectModal, ExportProjectSpace, ExportSpin } from './ExportProject.styles';
import ModalFooter from '../../../common/modalFooter/ModalFooter';
import { GetProjectInformationResponse } from '../../../../redux/actions/projectActions/types';
import CustomSelect from '../../../common/select/CustomSelect';
import { SelectData } from '../../../../models/common/types';
import { useAppDispatch } from '../../../../hooks/redux';
import { getProjectProductsAction } from '../../../../redux/actions/productActions';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { ExternalProduct, InternalProduct, Product } from '../../../../models/product/types';
import { GetProductsFromTransparencyCatalogAPIResponse } from '../../../../redux/actions/transparencyCatalogActions/types';
import { CSVHeader } from './ExportProject.mockData';
import { ExportProjectType } from '../../../../models/project/enums';
import { Division } from '../../../../models/productDivision/types';
import { alertService } from '../../../../services';
import { getExternalProductsAction, getCompaniesOfProducts } from '../../../../redux/actions/transparencyCatalogActions';

interface Props {
    isOpen: boolean;
    projectReferences: ProjectReferences | null;
    project: ProjectTableFields | GetProjectInformationResponse | MFRProject | null;
    options: Array<SelectData>;
    division?: Division<Product>;
    wrapLinesInDropdown?: boolean;
    reload: (modal: string) => void;
    closeModal: (data: { key: 'csv' }) => void;
}

interface State {
    exportData: Array<any>;
    fileName: string;
    pending: boolean;
}

const ExportProject: React.FC<Props> = ({
    isOpen,
    projectReferences,
    options,
    project,
    division,
    wrapLinesInDropdown,
    closeModal,
}) => {
    const dispatch = useAppDispatch();

    const [state, setState] = useState<State>({
        exportData: Array<any>(),
        fileName: 'export products',
        pending: false,
    });

    const handleCancel = () => {
        closeModal({ key: 'csv' });
    };

    const onSubmit = async (data: { exportType: string }) => {
        setState({ ...state, pending: true });
        getProductIds(data.exportType);
    };

    const getProductIds = async (exportType: string) => {
        if (project) {
            const { payload } = (await dispatch(
                getProjectProductsAction(project.id),
            )) as ActionResponse<Array<InternalProduct>>;
            if (payload.data.length) {
                getProducts(payload.data, exportType);
            } else {
                debugger;
                console.log('There are no products to export');
                alertService.warn('There are no products to export');
                setState({ ...state, pending: false });
            }
        }
    };

    const getCompanyData = async (externalProducts: ExternalProduct[]) => {
        const companies = await getCompaniesOfProducts(externalProducts, dispatch);
        return externalProducts.map((ep) => ({
            ...ep,
            companyLevel: companies.find((c) => c.id === ep.companyID)?.level,
            listingUrl: companies.find((c) => c.id === ep.companyID)?.listingUrl,
            websiteUrl: companies.find((c) => c.id === ep.companyID)?.websiteUrl,
        }));
    };

    const getProducts = async (internalProducts: Array<InternalProduct>, exportType: string) => {
        const productIds = internalProducts.map((p) => p.productId);
        const { payload } = (await dispatch(
            getExternalProductsAction({ productIds }),
        )) as ActionResponse<GetProductsFromTransparencyCatalogAPIResponse>;

        if (payload.data) {
            let availableProducts = Object.values(payload.data.mfSection).reduce(
                (agg, item: any) => agg.concat(item),
                Array<ExternalProduct>(),
            );

            availableProducts = await getCompanyData(availableProducts);

/*
            let currentProducts0 = availableProducts.reduce((agg, value) => {
                const internalProduct = internalProducts.find(
                    (product) => product.productId === value.id,
                );
                if (internalProduct) {
                    agg.push({
                        ...value,
                        ...internalProduct,
                        dateAdded: internalProduct.dateAdded,
                        lastUpdated: internalProduct.lastUpdated,
                        product: internalProduct,
                    });
                }
                return agg;
            }, Array<Product>());
*/

            let currentProducts = internalProducts.reduce((agg, value) => {
                const availableProduct = availableProducts.find(
                    (product) => product.id === value.productId,
                );
                if (availableProduct) {
                    agg.push({
                        ...availableProduct,
                        ...value,
                        dateAdded: value.dateAdded,
                        lastUpdated: value.lastUpdated,
                        product: value,
                        projectProductId: value.id,
                    });
                }
                return agg;
            }, Array<Product>());

            const positiveDesignId = projectReferences?.allBasisOfDesigns.find(
                (a) => a.name === 'Yes',
            )?.id;
            switch (+exportType as ExportProjectType) {
                case ExportProjectType.INSTALLED_PRODUCTS_ALL_DIVISIONS_ALL_PRODUCTS:
                    currentProducts = currentProducts.filter((cp) => cp.isInstalled);
                    break;
                case ExportProjectType.ALL_DIVISIONS_ALL_PRODUCTS:
                    break;
                case ExportProjectType.INSTALLED_PRODUCTS_ALL_DIVISIONS_BASIS_DESIGN:
                    currentProducts = currentProducts.filter(
                        (cp) => cp.isInstalled && cp.basisOfDesignId?.toString() === positiveDesignId?.toString(),
                    );
                    break;
                case ExportProjectType.ALL_DIVISIONS_BASIS_DESIGN:
                    currentProducts = currentProducts.filter(
                        (cp) => cp.basisOfDesignId?.toString() === positiveDesignId?.toString(),
                    );
                    break;
                case ExportProjectType.DIVISION_ALL_PRODUCTS:
                    currentProducts = currentProducts.filter(
                        (cp) => cp.mfDivision.number?.toString() === division?.id?.toString(),
                    );
                    break;
                case ExportProjectType.INSTALLED_PRODUCTS_DIVISION_ALL_PRODUCTS:
                    currentProducts = currentProducts.filter(
                        (cp) => cp.isInstalled && cp.mfDivision.number?.toString() === division?.id?.toString(),
                    );
                    break;
                case ExportProjectType.DIVISION_BASIS_DESIGN:
                    currentProducts = currentProducts.filter(
                        (cp) =>
                            cp.mfDivision.number?.toString() === division?.id?.toString() &&
                            cp.basisOfDesignId?.toString() === positiveDesignId?.toString(),
                    );
                    break;
                case ExportProjectType.INSTALLED_PRODUCTS_DIVISION_BASIS_DESIGN:
                    currentProducts = currentProducts.filter(
                        (cp) =>
                            cp.isInstalled &&
                            cp.mfDivision.number?.toString() === division?.id?.toString() &&
                            cp.basisOfDesignId?.toString() === positiveDesignId?.toString(),
                    );
                    break;

                default:
                    break;
            }
            setTimeout(() => {

                const sName = options.find((o) => o.id === exportType)?.name;
                let fileNameCalc;
                let divNumber = '';
                if (division) {
                    divNumber = division.id.substr(0,2);
                    divNumber = divNumber.startsWith('0') ? divNumber.slice(1) : divNumber;
                }
                switch (sName) {
                    case 'All divisions, all products':
                        fileNameCalc = project.name + '-All Products';
                        break;
                    case 'All divisions, basis of design only':
                        fileNameCalc = project.name + '-All Products-BoD';
                        break;
                    case 'Installed products - all divisions, all products':
                        fileNameCalc = project.name + '-Installed Products-All Products';
                        break;
                    case 'Installed products - all divisions, basis of design only':
                        fileNameCalc = project.name + '-Installed Products-All Products-BoD';
                        break;
                    case division.name + ', all products':
                        fileNameCalc = project.name + '-Division ' + divNumber + '-All Products';
                        break;
                    case division.name + ', basis of design only':
                        fileNameCalc = project.name + '-Division ' + divNumber + '-All Products-BoD';
                        break;
                    case `Installed products - ${division.name}, all products`:
                        fileNameCalc = project.name + '-Division ' + divNumber + '-Installed Products-All Products';
                        break;
                    case `Installed products - ${division.name}, basis of design only`:
                        fileNameCalc = project.name + '-Division ' + divNumber + '-Installed Products-All Products-BoD';
                        break;
                    default:
                        break;
                }

                if (fileNameCalc.indexOf('.csv') === -1) {
                    fileNameCalc += ".csv";
                }

                for (let i = 0; i < currentProducts.length; i++) {
                    currentProducts[i].productNameCorrected = currentProducts[i].productName.replace(/\"/g, "\"\"");
                    currentProducts[i].descriptionCorrected = currentProducts[i].description?.replace(/\"/g, "\"\"");
                    if (currentProducts[i].regulations !== undefined &&
                      currentProducts[i].regulations !== null &&
                      currentProducts[i].regulations.indexOf('Perkins&Will Precautionary List Free') > -1) {
                        currentProducts[i].hpdScopeResultsCorrected = 'Perkins&Will PL Free';
                    } else {
                        currentProducts[i].hpdScopeResultsCorrected = currentProducts[i].hpdScopeResults;
                    }
                }

                currentProducts = currentProducts.map((p) => ({
                        ...p,
                        masterFormat: `${p.mfSection.number} ${p.mfSection.label}`,
                        basisOfDesign: projectReferences?.allBasisOfDesigns.find(
                            (a) => a.id.toString() === p.basisOfDesignId?.toString(),
                        )?.name,
                        substitutions: projectReferences?.allProductSubstitutions.find(
                            (s) => s.id.toString() === p.substitutionTypeId?.toString(),
                        )?.name,
                    }));


                currentProducts = currentProducts.sort(
                    function(a, b) {
                        if (a.masterFormat === b.masterFormat) {
                            // Company is only important when MasterFormat are the same
                            return a.company < b.company ? -1 : 1;
                        }
                        return a.masterFormat < b.masterFormat ? -1 : 1;
                    }
                );
//.sort((a, b) => (a.masterFormat < b.masterFormat ? -1 : 1))

                setState({
                    ...state,
                    fileName: fileNameCalc || state.fileName,
                    exportData: currentProducts,
                });
                const link = document.getElementById('csv-link') as any;
                link.click();
            }, 500);
        }
    };

    return (
        <ExportProjectModal
            open={isOpen}
            onCancel={handleCancel}
            closeIcon={<CloseIcon />}
            centered
            footer={null}
        >
            <ExportSpin spinning={state.pending}>
                <ModalTitle title="Export to CSV" icon="ic-modal-title-export.svg" />
                <div>
                    <Text weight={400} size={15} height={20} color={blueDark01}>
                        Export to CSV includes a spreadsheet of product name, manufacturer name,
                         MasterFormat number, URL to their Transparency Catalog listing page,
                         optionally added attributes (pricing, BOD, substitutions), and the types
                         of transparency documentation for that product.
                    </Text>
                    <CSVLink
                        id="csv-link"
                        filename={state.fileName}
                        data={state.exportData}
                        headers={CSVHeader}
                    >
                        Download me
                    </CSVLink>
                </div>
                <Form onFinish={onSubmit}>
                    <ExportProjectSpace>
                        <CustomSelect
                            id="ModalSelect"
                            label="Export type:"
                            name="exportType"
                            options={options}
                            placeholder="Select one"
                            rules={[{ required: true, message: '' }]}
                            block
                            wrapLines={wrapLinesInDropdown}
                        />
                    </ExportProjectSpace>
                    <ModalFooter action="Export" cancel={handleCancel} submit={() => {}} />
                </Form>
            </ExportSpin>
        </ExportProjectModal>
    );
};

export default ExportProject;
