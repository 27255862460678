import { Divider, Space } from 'antd';
import styled from 'styled-components';
import { BottomPaginationContainer } from '../../../../components/common/table/CustomTable.styles';
import { blueLink, grayLight01, grayLight03, grayDark05, blueDark01 } from '../../../../styles/colors';
import { InvisibleButton, SpaceStart, Text } from '../../../../styles/common.styles';
import ChangePageSize from '../../../../components/common/pagination/ChangePageSize';

export const FilterContainer = styled(Space)`
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .ant-space-item:last-child {
        width: 150px;
        button {
            width: 100%;
        }
    }
    .ant-select:last-child {
        width: 140px;
    }

`;

export const ProjectNotificationsPageDivider = styled(Divider)`
    border-left: 5px solid ${grayLight01};
    margin: 10px 0 !important;
`;

export const ActivityItemContainer = styled(SpaceStart)`
    gap: 5rem !important;
    align-items: baseline;
`;

export const ActivityEmailContainer = styled(SpaceStart)`
    margin-left: 20px;
`;

export const ActivityEventItem = styled(Text)`
    &:hover {
        text-decoration: underline;
    }
`;

export const ResendInvitationSection = styled('div')`
    background-color: #f8f2c6;
    border-radius: 3px;
    padding: 10px 10rem 10px 10px;
    max-width: 640px;
    margin-top: -15px;
    margin-bottom: 10px;
    &.single {
        margin-left: 28px;
        margin: 0;
    }
    @media screen and (max-width: 860px) {
        padding: 10px;
    }
`;

export const ResendInvitationText = styled('div')`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000;
`;

export const ResendInvitationButton = styled(InvisibleButton)`
    margin-left: 22px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${blueLink};
    background-color: transparent;
    &:hover {
        color: ${blueLink};
    }
    &:focus {
        color: ${blueLink};
    }
`;

export const ResendInvitationTextBox = styled('div')`
    display: flex;
    align-items: baseline;
    gap: 5px;
`;

export const ProjectNotificationsItemDivider = styled(Divider)`
    border-left: 5px solid ${grayLight03};
    margin: 0 0 10px 0 !important;
`;

export const NotificationContainer = styled('div')`
    border-top: 1px solid ${grayLight01};
    border-bottom: 0.5px solid ${grayLight01};
    &.clicked {
        border-top: 1px solid ${grayDark05};
    }
`;

export const CustomTopContainer = styled('div')`
    input {
        height: 32px;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 13px;
        line-height: 16px;
        color: ${blueDark01};
        padding: 8px 13px;
    }
    .ant-space-item:last-child {
        width: 16px !important;
    }
`;

export const CustomBottomContainer = styled(BottomPaginationContainer)`
    justify-content: end !important;
`;

export const ChangePageSizeContainer = styled(ChangePageSize)`
    .ant-space-item:last-child {
        width: 0px !important;
    }
`;

export const CustomTableSpace = styled(Space)`
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;
