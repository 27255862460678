import { DatePickerProps, Image } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { UploadsTableFields } from '../../Uploads.types';
import ProgramTipsController from '../../../../favoritProducts/favoriteProductItem/productItemHeader/tips/materialPrograms/ProgramTipsController';
import {
    CalendarActionContainer,
    ClearExpireImage,
    EditDatePicker,
    EditFileNameInput,
    EditExpirationDateInput,
    FileNameText,
    TipContainer,
    UpdateUploadsButton,
    UploadColumnContainer,
    UploadCmfContainer,
    UploadCmfImage,
    UploadDescriptionContent,
    UploadDescriptionInput,
    UploadDescriptionInputContainer,
    UploadDescriptionInputError,
    UploadDocumentNameContainer,
    UploadExpiresContainer,
    UploadsActions,
    UploadsDatePickerContainer,
    UploadsDescription,
    UploadsDocumentType,
    UploadsNameContainer,
    UploadsRowContainer,
} from './UploadsRow.styles';
import { blueDark01, blueLink, grayDark03 } from '../../../../../styles/colors';
import { dayLeft, toShortLocalDate } from '../../../../../helpers/dateHelper';
import { formatBytes } from '../../../../../helpers/capacityHelper';
import { useAppDispatch } from '../../../../../hooks/redux';
import {
    deleteFavoriteFileAction,
    updateFavoriteFileAction,
} from '../../../../../redux/actions/favoriteProductsActions';
import { FavoriteType } from '../../../../../models/product/enums';
import { MergedFavoriteProduct } from '../../../../favoritProducts/FavoriteProducts.types';
import { ActionResponse, CommonResponse } from '../../../../../models/actionResponse/types';
import { FavoriteProduct, Product } from '../../../../../models/product/types';
import { usePermissions } from '../../../../../hooks/permissions';
import { SelectData } from '../../../../../models/common/types';
import {
    deleteFileAction,
    retrieveUploadFileAction,
    updateProductFileAction,
} from '../../../../../redux/actions/productActions';
import { BoldText, Text } from '../../../../../styles/common.styles';
import { useModalState } from '../../../../../hooks/modalState';
import DeleteModal from '../../../../common/deleteModal/DeleteModal';
import { MFRProject } from '../../../../../models/project/types';
import WarningBanner from '../../../../../components/warningBanner/WarningBanner';
import { UploadType } from '../../UploadsInfo';
import { getCSSClassNameByUploadType } from '../UploadTable';

interface Props {
    file: UploadsTableFields;
    isOdd: boolean;
    favoriteType?: FavoriteType;
    product: MergedFavoriteProduct | Product;
    project?: MFRProject;
    allDocumentTypes: Array<SelectData> | undefined;
    uploadType: UploadType
    removeFile: (file: UploadsTableFields) => void;
}

export const deleteFile = async (
    favoriteType: FavoriteType | undefined,
    file: UploadsTableFields,
    product: MergedFavoriteProduct | Product,
    dispatch: any
) => {
    if (favoriteType !== undefined) {
        return await dispatch(deleteFavoriteFileAction({
            productFavoriteId: product.id,
            favoriteType: favoriteType,
            ProjectProductUploadId: file.id,
        }));
    } else {
        return await dispatch(deleteFileAction({
            projectId: product.product.projectId,
            projectProductUploadId: file.id,
        }));
    }
};

export const updateFile = async (
    favoriteType: FavoriteType | undefined,
    file: UploadsTableFields,
    product: MergedFavoriteProduct | Product,
    dispatch: any
) => {
    if (favoriteType !== undefined) {
       return await dispatch(updateFavoriteFileAction({
            favoriteType: favoriteType,
            productFavoriteId: product.id,
            alternativeText: file.alternativeText,
            description: file.description,
            projectProductUploadId: file.id,
            documentName: file.documentName,
            expirationOn: file.expiredOn,
        }))
    } else {
        return await dispatch(updateProductFileAction({
            productName: product.productName,
            projectProductId: product.product.id,
            projectId: product.product.projectId,
            productId: product.product.productId,
            alternativeText: file.alternativeText,
            description: file.description,
            projectProductUploadId: file.id,
            documentName: file.documentName,
            expirationOn: file.expiredOn,
        }))
    }
};

const UploadsRow: React.FC<Props> = ({
    file,
    isOdd,
    favoriteType,
    product,
    project,
    allDocumentTypes,
    uploadType,
    removeFile,
}) => {
    const dispatch = useAppDispatch();

    const { checkProductActionPermission } = usePermissions();

    const { isOpen, onToggle } = useModalState();

    const [state, setState] = useState({
        showDescription: false,
        editMode: false,
        file: file,
    });

    const [errors, setErrors] = useState({
        fileName: false,
        expirationDate: false,
        description: false,
    });

    const handleShowDescription = () => {
        setState({ ...state, showDescription: !state.showDescription });
    };

    const switchEditMode = () => {
        setState({ ...state, editMode: !state.editMode, showDescription: !state.editMode });
    };

    const removeDocument = async () => {
        const { payload } = (await deleteFile(favoriteType, file, product, dispatch)) as CommonResponse;
        if (payload?.message) {
            removeFile(file);
        }
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = { ...state.file };
        file.documentName = event.currentTarget.value;
        if (!file.documentName) {
            setErrors({ ...errors, fileName: true });
        } else {
            setErrors({ ...errors, fileName: false });
        }
        setState({ ...state, file });
    };

    const submitChange = async () => {
        if (errors.fileName || errors.description) {
            return;
        }

        const { payload } = (await updateFile(favoriteType, state.file, product, dispatch)) as ActionResponse<FavoriteProduct>;
        if (payload?.data) {
            setState({ ...state, editMode: false });
        }
    };

    const handleExpirationDate: DatePickerProps['onChange'] = (date: any) => {
        const currentFile = state.file;
        currentFile.expiredOn = date ? new Date(date?._d).toJSON() : file.expiredOn;
;
        setState({ ...state, file: currentFile });
    };

    const handleExpirationDateInInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = { ...state.file };
        file.expiredOn = event.currentTarget.value;
        setState({ ...state, file });
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const currentFile = state.file;
        currentFile.description = event.target.value;
        if (event.target.value.length > 3000) {
            setErrors({ ...errors, description: true });
        } else {
            setErrors({ ...errors, description: false });
        }
        setState({ ...state, file: currentFile });
    };

    const uploadDocument = async () => {
        const blob = await retrieveUploadFileAction({ fileName: file.filePath });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${file.documentName}`;
        link.click();
    };

    const daysBeforeExpiration = 30;
    const warningPrefix = 'Reminder:';
    let showReminderBanner = false;
    let reminderText: string = '';
    if (state.file.expiredOn) {
        const fileExpirationDate = new Date(state.file.expiredOn);
        const daysLeft = Math.floor(dayLeft(state.file.expiredOn));
        showReminderBanner = !!state.file.expiredOn && daysLeft <= daysBeforeExpiration;
        const alreadyExpired = daysLeft < 0;
        if (showReminderBanner) {
            if (alreadyExpired) {
                reminderText = `this file has expired.`;
            } else {
                reminderText = `this file expires on ${toShortLocalDate(fileExpirationDate)}.`;
            }
        }
    }

    const clearExpirationDate = () => {
        const file = state.file;
        file.expiredOn = null;
        setState({ ...state, file });
    };

    const getInspectAreaImage = (impactAreaName : string) => {
       switch(impactAreaName) {
          case 'HUMAN HEALTH':
              return 'src/assets/images/health_red_notext.png';
          case 'CLIMATE HEALTH':
              return 'src/assets/images/climate_blue_notext.png';
          case 'CIRCULARITY':
              return 'src/assets/images/circularity_blue_notext.png';
          case 'SOCIAL HEALTH & EQUITY':
              return 'src/assets/images/social_purple_notext.png';
          case 'ECOSYSTEM HEALTH':
              return 'src/assets/images/ecosystem_green_notext.png';
       }
       return '';
    };

    return (
        <div className={isOdd ? 'row container' : 'container'} id={file.id.toString()}>
            <UploadsRowContainer>
                <UploadDocumentNameContainer className={getCSSClassNameByUploadType(uploadType)}>
                    <UploadsNameContainer>
                        <Image
                            preview={false}
                            src="src/assets/images/file.svg"
                            className={state.editMode ? 'file-image' : ''}
                            alt="file"
                        />
                        {state.editMode ? (
                            <EditFileNameInput
                                value={state.file.documentName}
                                onChange={handleInput}
                                className={errors.fileName ? 'error' : ''}
                            />
                        ) : (
                            <FileNameText
                                weight={400}
                                size={12}
                                height={14}
                                color={blueLink}
                                onClick={uploadDocument}
                            >
                                {state.file.documentName}
                            </FileNameText>
                        )}
                    </UploadsNameContainer>
                    <UploadsDocumentType>
                        <div>
                        {
                            allDocumentTypes?.find(
                                (documentType) =>
                                    documentType.id.toString() ===
                                    state.file.documentTypeId.toString(),
                            )?.name
                        }
                        {state.file?.cmfImpactAreas?.length > 0 && state.file?.cmfImpactAreas[0].name &&
                            <TipContainer className="ec3">&nbsp;&nbsp;&nbsp;&nbsp;
                                <ProgramTipsController
                                    materialProgram='cmfImpactAreas'
                                    dataInput={state.file?.cmfImpactAreas}
                                />
                            </TipContainer>
                        }
                        </div>
                    </UploadsDocumentType>
                    <UploadsDescription onClick={handleShowDescription}>
                        <BoldText weight={400} size={12} height={14} color={blueLink}>
                            Description
                        </BoldText>
                        <Image
                            src="src/assets/images/chevron-down.svg"
                            preview={false}
                            className={state.showDescription ? '' : 'rotate'}
                            alt="open"
                        />
                    </UploadsDescription>
                </UploadDocumentNameContainer>

                <UploadCmfContainer>
                    {(state.file?.cmfImpactAreas?.length === 0 ||
                      (state.file?.cmfImpactAreas?.length > 0 && state.file?.cmfImpactAreas[0].impactAreaId > 0)) && (
                        state.file?.cmfImpactAreas?.map((impactArea, i) => (
                          <UploadCmfImage>
                            <Image
                                preview={false}
                                src={getInspectAreaImage(impactArea.name?.toUpperCase())}
                                style={{ cursor: 'pointer' }}
                                title={impactArea.name}
                                width={16} height={16}
                            />
                          </UploadCmfImage>
                      ))
                    )}
                </UploadCmfContainer>

               <UploadExpiresContainer className={getCSSClassNameByUploadType(uploadType)}>
                     <UploadsDatePickerContainer className={state.editMode ? 'edit' : ''}>
                        {state.editMode ? (
                            <EditExpirationDateInput
                                value={toShortLocalDate(state.file.expiredOn)}
                                onChange={handleExpirationDateInInput}
                                className={errors.expirationDate ? 'error' : ''}
                            />
                        ) : (
                            <Text
                                weight={400}
                                size={12}
                                height={14}
                                color={state.editMode ? blueDark01 : grayDark03}
                            >
                                {toShortLocalDate(state.file.expiredOn)}
                            </Text>
                        )}
                        <CalendarActionContainer>
                            {state.editMode && (
                                <>
                                    {state.file.expiredOn && (
                                        <ClearExpireImage
                                            src="src/assets/images/circle-xmark.svg"
                                            preview={false}
                                            alt="calendar"
                                            title="clear"
                                            onClick={clearExpirationDate}
                                        />
                                    )}
                                    <EditDatePicker
                                        onChange={handleExpirationDate}
                                        allowClear={false}
                                        disabledDate={(current: any) => {
                                            const customDate = moment().format('YYYY-MM-DD');
                                            return (
                                                current &&
                                                current < moment(customDate, 'YYYY-MM-DD')
                                            );
                                        }}
					value={state && state.file && state.file.expiredOn ? moment(state.file.expiredOn) :  null}
                                        suffixIcon={
                                            <>
                                                <Image
                                                    src="src/assets/images/calendar-day.svg"
                                                    preview={false}
                                                    alt="calendar"
                                                />
                                            </>
                                        }
                                    />
                                </>
                            )}
                        </CalendarActionContainer>
                    </UploadsDatePickerContainer>
                </UploadExpiresContainer>

                <UploadColumnContainer>
                    <Text weight={400} size={12} height={14} color={grayDark03}>
                        {toShortLocalDate(file.createdOn)}
                    </Text>
                </UploadColumnContainer>

                <UploadColumnContainer className={getCSSClassNameByUploadType(uploadType)}>
                    <Text weight={400} size={12} height={14} color={grayDark03}>
                        {formatBytes(file.size)}
                    </Text>
                </UploadColumnContainer>
                {!checkProductActionPermission(favoriteType, project?.userRole) && (
                    <UploadsActions>
                        {state.editMode ? (
                            <Image
                                preview={false}
                                src="src/assets/images/file-edit-active.svg"
                                style={{ cursor: 'pointer' }}
                                onClick={switchEditMode}
                                alt="edit"
                            />
                        ) : (
                            <Image
                                preview={false}
                                src="src/assets/images/file-edit.svg"
                                style={{ cursor: 'pointer' }}
                                onClick={switchEditMode}
                                alt="edit"
                            />
                        )}
                        <Image
                            src="src/assets/images/trash-grey.svg"
                            preview={false}
                            style={{ cursor: 'pointer' }}
                            onClick={onToggle}
                            alt="edit"
                        />
                    </UploadsActions>
                )}
                {checkProductActionPermission(favoriteType, project?.userRole) && (
                    <UploadsActions>
                    </UploadsActions>
                )}
            </UploadsRowContainer>
            <UploadDescriptionContent className={state.showDescription ? 'clicked' : ''}>
                <UploadDescriptionInputContainer>
                    <UploadDescriptionInput
                        disabled={!state.editMode}
                        value={state.file.description}
                        onChange={handleDescriptionChange}
                        className={errors.description ? 'error' : ''}
                    />
                    {errors.description && (
                        <UploadDescriptionInputError>
                            Value should be less than 3000 characters
                        </UploadDescriptionInputError>
                    )}
                </UploadDescriptionInputContainer>
                {state.editMode && (
                    <UpdateUploadsButton onClick={submitChange}>Save</UpdateUploadsButton>
                )}
            </UploadDescriptionContent>
            {showReminderBanner && (
                <WarningBanner
                    Text={reminderText}
                    FullWidth
                    Clicked={state.showDescription}
                    Prefix={warningPrefix}
                ></WarningBanner>
            )}
            <DeleteModal
                isOpen={isOpen}
                closeModal={onToggle}
                reload={removeDocument}
                title="document"
                text={state.file.documentName}
            />
        </div>
    );
};

export default UploadsRow;
