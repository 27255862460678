import React from 'react';
import { Checkbox } from 'antd';
import { Section, GroupedProduct } from '../../../models/productDivision/types';
import { blueDark01, blueLink } from '../../../styles/colors';
import Switcher from '../../common/switcher/Switcher';
import { MergedFavoriteProduct } from '../FavoriteProducts.types';
import {
    FavoriteCheckboxContainer,
    FavoriteProductsContainer,
    FavoriteSectionItemContainer,
    FavoriteSectionItemDivider,
    FavoriteSectionItemTitleContainer,
    FavoriteSectionItemWrapper,
    FavoriteSectionTitle,
    ProductScrollableContainer,
    MFRTitleContainer,
} from './FavoriteSectionItem.styles';
import { BoldText, Text } from '../../../styles/common.styles';
import FavoriteProductItem from '../favoriteProductItem/FavoriteProductItem';
import { ProductReferences } from '../../../redux/actions/productActions/types';
import { FavoriteType } from '../../../models/product/enums';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Product } from '../../../models/product/types';

interface Props {
    section: Section<MergedFavoriteProduct>;
    references: ProductReferences | null;
    favoriteType: FavoriteType;
    handleClicked: (id: string) => void;
    selectAllProducts: (sectionId: string, checked: boolean) => void;
    selectProduct: (product: MergedFavoriteProduct, checked: boolean) => void;
}

const FavoriteSectionItem: React.FC<Props> = ({
    section,
    references,
    favoriteType,
    handleClicked,
    selectAllProducts,
    selectProduct,
}) => {
    const handleCheckbox = (data: CheckboxChangeEvent) => {
        selectAllProducts(section.id, data.target.checked);
    };

    let masterFormatProducts = Array<GroupedProduct<Product>>();

    for (let i = 0; i < section.products.length; i++) {
            section.products[i].lowestLevelNumber =
                            section.products[i]?.mfSectionLevel3?.number ||
                            section.products[i]?.mfSectionLevel2?.number ||
                            section.products[i]?.mfSectionLevel1?.number ||
                            section.products[i].mfSection?.number;
            section.products[i].lowestLevelLabel =
                            section.products[i].mfSectionLevel3?.label ||
                            section.products[i].mfSectionLevel2?.label ||
                            section.products[i].mfSectionLevel1?.label ||
                            section.products[i].mfSection?.label;
    }
    const groupedMap = section.products.reduce(
        (entryMap, e) =>
            entryMap.set(e.lowestLevelNumber as any, [
                ...(entryMap.get(e.lowestLevelNumber as any) || []),
                e,
            ]),
        new Map<string | undefined, Array<Product>>(),
    );
    for (const [key, value] of groupedMap.entries()) {
        if (value && value.length > 0 && value[0]) {
            value[0].lowestLevelNumber =
                            value[0]?.mfSectionLevel3?.number ||
                            value[0]?.mfSectionLevel2?.number ||
                            value[0]?.mfSectionLevel1?.number ||
                            value[0].mfSection?.number;
            value[0].lowestLevelLabel =
                            value[0].mfSectionLevel3?.label ||
                            value[0].mfSectionLevel2?.label ||
                            value[0].mfSectionLevel1?.label ||
                            value[0].mfSection?.label;
         }
        masterFormatProducts.push({
            products: value,
            number: key,
            label: value[0].lowestLevelLabel,
        });
    }
    masterFormatProducts = sortByKey(masterFormatProducts, 'number');

    function sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key];
            var y = b[key];

        if (typeof x == "string")
        {
            x = (""+x).toLowerCase();
        }
        if (typeof y == "string")
        {
            y = (""+y).toLowerCase();
        }

        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
     });
   }

    return (
        <>
            <FavoriteSectionItemDivider />
            <FavoriteSectionItemWrapper>
                <FavoriteSectionItemContainer>
                    <FavoriteSectionItemTitleContainer
                        onClick={() => {
                            handleClicked(section.id);
                        }}
                    >
                        <Switcher
                            id={section.id}
                            clicked={section.clicked}
                            handleSwitch={() => handleClicked(section.id)}
                            width={20}
                            height={20}
                            small
                        />
                        <FavoriteSectionTitle className="section-id hover">
                            {section.id}
                        </FavoriteSectionTitle>
                        <FavoriteSectionTitle className="hover">
                            {section.name}
                        </FavoriteSectionTitle>
                        <Text
                            weight={400}
                            size={16}
                            height={20}
                            color={blueDark01}
                            className="hover"
                        >
                            ({section.products.length})
                        </Text>
                    </FavoriteSectionItemTitleContainer>
                </FavoriteSectionItemContainer>
            </FavoriteSectionItemWrapper>
            <FavoriteCheckboxContainer
                className={section.clicked && section.products.length > 1 ? 'clicked show' : ''}
            >
                <Checkbox
                    onChange={handleCheckbox}
                    checked={section.products.every((product) => product.selected)}
                />
                <BoldText weight={400} size={14} height={16} color={blueLink}>
                    Select all
                </BoldText>
            </FavoriteCheckboxContainer>
            <FavoriteProductsContainer className={section.clicked ? 'clicked' : ''}>
                {masterFormatProducts.map((group, j) => (
                    <ProductScrollableContainer key={j} className={section.products.length > 1 ? 'extraPadding' : ''}>
                        <FavoriteSectionItemDivider/>
                        <MFRTitleContainer>
                            <FavoriteSectionTitle className="section-id mfr">{group.number}</FavoriteSectionTitle>
                            <FavoriteSectionTitle className="mfr">{`${group.label}`}</FavoriteSectionTitle>{' '}
                            <Text
                                weight={400}
                                size={14}
                                height={18}
                                color={blueDark01}
                                className="hover"
                            >
                                ({group.products.length})
                            </Text>
                        </MFRTitleContainer>
                        {sortByKey(group.products, 'company').map((product: MergedFavoriteProduct, i: number) => (
                                <FavoriteProductItem
                                    key={product.id}
                                    product={product}
                                    productsCount={
                                        section.products.filter(
                                            (p) => p.mfSection.number === product.mfSection.number,
                                        ).length
                                    }
                                    references={references}
                                    favoriteType={favoriteType}
                                    showProductItemHeader={i === 0 && j === 0}
                                    selectProduct={selectProduct}
                                />
                            ),
                        )}
                    </ProductScrollableContainer>
                 ))}
            </FavoriteProductsContainer>
        </>
    );
};

export default FavoriteSectionItem;
