import { Checkbox, Form, FormInstance, Input } from 'antd';
import React from 'react';
import { blueDark01 } from '../../../styles/colors';
import { BlueTitle, SecondaryButton, SpaceBetween, Text } from '../../../styles/common.styles';
import CustomInput from '../../../components/common/inputs/CustomInput';
import CustomSelect from '../../../components/common/select/CustomSelect';
import {
    OrganizationInfoContainer,
    OrganizationInfoForm,
    ServicesContainer,
    ServiceText,
} from './OrganizationInfo.styles';
import { OrganizationData } from '../../../models/organization/types';
import { SettingReferences } from '../SettingPage.types';

interface Props {
    organizationData: OrganizationData | undefined;
    references: SettingReferences;
    services: Array<{ id: number; text?: string }>;
    error: boolean;
    handleServicesSelect: (event: any) => void;
    onSubmit: (data: { organizationName: string; organizationSizeId: number }) => void;
    onFailed: () => void;
    form: FormInstance<OrganizationData>;
}
const OrganizationInfo: React.FC<Props> = ({
    references,
    services,
    error,
    handleServicesSelect,
    onSubmit,
    onFailed,
    form,
}) => {
    const renderServices = () => {
        const firstColumnCount = Math.round(references.allServices.length/2);
        let allServices1 = references.allServices;
        let allServices2 = references.allServices;
        allServices1 = allServices1.slice(0,firstColumnCount);
        allServices2 = allServices2.slice(firstColumnCount,references.allServices.length);
        return (
            <>
                <Text
                    weight={400}
                    size={14}
                    height={18}
                    color={blueDark01}
                    style={{ marginTop: '10px' }}
                >
                    Select all that apply.
                </Text>
                <table border="0" width="600px">
                <tr><td valign="top" width="50%">
                {allServices1.map((service: any) => (
                    <ServicesContainer key={service.id}>
                        <Checkbox
                            name={service.id}
                            onChange={handleServicesSelect}
                            checked={services.findIndex((item) => item.id === service.id) !== -1}
                        />
                        <Text weight={400} size={14} height={18} color={blueDark01}>
                            {service.name}
                        </Text>
                    </ServicesContainer>
                ))}
                </td><td valign="top" width="50%">
                {allServices2.map((service: any) => (
                  <>
                    <ServicesContainer key={service.id}>
                        <Checkbox
                            name={service.id}
                            onChange={handleServicesSelect}
                            checked={services.findIndex((item) => item.id === service.id) !== -1}
                        />
                        <Text weight={400} size={14} height={18} color={blueDark01}>
                            {service.name}
                        </Text>
                    </ServicesContainer>
                    <ServicesContainer key={service.id} style={{marginTop: -5 + 'px'}}>
                        {service.hasText && services.findIndex((item) => item.id === service.id) !==-1 && (
                            <>
                               <Form.Item name="text">
                                    <Input style={{marginLeft: 25 + 'px', marginTop: 5 + 'px'}}
                                        disabled={
                                            services.findIndex((item) => item.id === service.id) ===
                                            -1
                                        }
                                    />
                                </Form.Item>
                            </>
                        )}
                    </ServicesContainer>
                  </>
                ))}
                </td></tr>
                </table>
                {error && (
                    <span className="ant-form-item-explain-error">
                        Should be at least one selected option
                    </span>
                )}
            </>
        );
    };

    const submitContainer = () => {
        return (
            <SpaceBetween>
                <SecondaryButton htmlType="submit">Save</SecondaryButton>
            </SpaceBetween>
        );
    };

    return (
        <OrganizationInfoContainer>
            <OrganizationInfoForm
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
                onFinish={onSubmit}
                onFinishFailed={onFailed}
                autoComplete={'false'}
                form={form}
            >
                <BlueTitle> About your organization</BlueTitle>
                <CustomInput
                    Component={Input}
                    label="Organization name"
                    name="organizationName"
                    required
                    rules={[{ required: true, message: '' }]}
                />

                <CustomSelect
                    label="Organization size"
                    name="organizationSizeId"
                    options={references.allOrganizationSizes}
                    placeholder="Select organization size"
                    required
                    rules={[{ required: true, message: '' }]}
                />
                <CustomInput
                    Component={React.memo(renderServices)}
                    label="Organization type*"
                    name="services"
                />
                <BlueTitle> About your office</BlueTitle>
                <ServiceText weight={400} height={18} size={14} color={blueDark01}>
                    {' '}
                    Tell us about <strong> the office you’re associated with </strong>at your
                    organization.
                </ServiceText>
                <div className="right-label">
                    <CustomSelect
                        label="Office country"
                        name="officeCountryId"
                        options={references.allCountriesAndStates}
                        placeholder="Select country"
                        props={{
                            filterOption: (input: string, option: any) =>
                                (option?.children.toLowerCase() ?? '').includes(
                                    input.toLowerCase(),
                                ),
                            showSearch: true,
                        }}
                    />
                </div>
                {references.allStates.length > 0 ? (
                    <CustomSelect
                        label="Office state/province"
                        name="officeStateId"
                        options={references.allStates}
                        placeholder="Select state or province"
                        props={{
                            filterOption: (input: string, option: any) =>
                                (option?.children.toLowerCase() ?? '').includes(
                                    input.toLowerCase(),
                                ),
                            showSearch: true,
                        }}
                    />
                ) : (
                    <CustomInput
                        Component={Input}
                        label="Office state/province"
                        name="officeStateName"
                    />
                )}
                <div className="right-label">
                    <CustomInput Component={Input} label="Office city" name="officeCity" />
                </div>

                <div className="button-container">
                    <CustomInput Component={submitContainer} label="button" name="" />
                </div>
            </OrganizationInfoForm>
        </OrganizationInfoContainer>
    );
};

export default OrganizationInfo;
