import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import ExistingProductsModal from '../../components/existingProductsModal/ExistingProductsModal';
import FavoriteProductsAction from '../../components/favoriteProductActions/FavoriteProductActions';
import FavoriteProducts from '../../components/favoritProducts/FavoriteProducts';
import { MergedFavoriteProduct } from '../../components/favoritProducts/FavoriteProducts.types';
import StartProject from '../../components/projects/startProject/StartProject';
import { useModalState } from '../../hooks/modalState';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { ActionResponse } from '../../models/actionResponse/types';
import { FavoriteType } from '../../models/product/enums';
import { ExternalProduct } from '../../models/product/types';
import {
    addProductsFromFavoriteAction,
    getOfficeFavoritesAction,
} from '../../redux/actions/favoriteProductsActions';
import { AddToFavoriteResponse } from '../../redux/actions/favoriteProductsActions/types';
import { addProductsFromFavorite } from '../../redux/actions/productActions';
import { AddProductsFromFavoriteResponse } from '../../redux/actions/productActions/types';
import { addPages, removePage } from '../../redux/reducers/navigationReducer/NavigationSlice';

interface Params {
    projectId: string;
    productId?: string;
}
const OrganizationFavoriteProducts: React.FC = () => {
    const selector = useAppSelector((combinedState) => combinedState);
    const { authReducer } = selector;
    const history = useHistory();
    const { onOpen, onClose, isOpen } = useModalState();
    const dispatch = useAppDispatch();
    const [state, setState] = useState({
        selectedProducts: Array<MergedFavoriteProduct>(),
        projectId: 0,
        existingProducts: Array<{ id: number; name: string }>(),
        deletedProducts: Array<MergedFavoriteProduct>(),
        filteredDivision: Array<Division<MergedFavoriteProduct>>(),
        destination: 'project',
    });
    const [modals, setModals] = useState({ warning: false });

    const [favoriteProducts, setFavoriteProducts] = useState(Array<ExternalProduct>);
    const [isFilterMode, setIsFilterMode] = useState(false);
    const [isFilteringInProgress, setIsFilteringInProgress] = useState(false);

    const params = useParams<Params>();

    useEffect(() => {
        window.scroll(0, 0);
        dispatch(
            addPages([
                {
                    key: NavigationKey.DASHBOARD,
                    value: `${authReducer.user?.firstName}’s Dashboard`,
                },
                {
                    key: NavigationKey.FAVORITES,
                    value: `${
                        authReducer.user?.organization?.name + ' Product Library'
                    }`,
                },
            ]),
        );
        return () => {
            dispatch(removePage());
        };
    }, []);

    const selectProduct = (product: MergedFavoriteProduct, checked: boolean) => {
        let currentSelectedProducts = state.selectedProducts;
        if (checked) {
            currentSelectedProducts.push(product);
        } else {
            currentSelectedProducts = currentSelectedProducts.filter(
                (currentProduct) => currentProduct.id !== product.id,
            );
        }
        setState({ ...state, selectedProducts: currentSelectedProducts });
    };

    const addProductsToProject = () => {
        const projectId = parseInt(params?.projectId);
        if (projectId) {
            addProducts(+params.projectId);
        } else {
            setState({ ...state, destination: 'project' });
            onOpen();
        }
    };

    const addProducts = async (projectId: number) => {
        const { payload } = (await dispatch(
            addProductsFromFavorite({
                projectId: projectId,
                favoriteIds: state.selectedProducts.map((product) => product.id),
            }),
        )) as ActionResponse<AddProductsFromFavoriteResponse>;
        if (payload.data) {
            if (payload.data.existingProducts && payload.data.existingProducts.length) {
                const existingProducts = state.selectedProducts
                    .filter((product) =>
                        payload.data.existingProducts.some(
                            (id) => id === product.product.productId,
                        ),
                    )
                    .map((product) => ({ name: product.productName, id: product.id }));
                setState({ ...state, projectId, existingProducts });
                setModals({ ...modals, warning: true });
            } else {
                history.push(`/project-info/${projectId}/0/2`);
            }
        }
    };

    const addToFavorite = async () => {
        const { payload } = (await dispatch(
            addProductsFromFavoriteAction({
                favoriteIds: state.selectedProducts.map((product) => product.id),
                toFavoriteType: FavoriteType.MyFavorite,
            }),
        )) as ActionResponse<AddToFavoriteResponse>;
        if (payload.data) {
            if (payload.data.existingProducts && payload.data.existingProducts.length) {
                const existingProducts = state.selectedProducts
                    .filter((product) =>
                        payload.data.existingProducts.some(
                            (id) => id === product.product.productId,
                        ),
                    )
                    .map((product) => ({ name: product.productName, id: product.id }));
                setState({
                    ...state,
                    existingProducts,
                    destination: `your favorite library`,
                });
                setModals({ ...modals, warning: true });
            } else {
                history.push(`/my-favorites`);
            }
        }
    };

    const goToProducts = () => {
        setModals({ ...modals, warning: false });
        if (state.destination === 'project') {
            history.push(`/project-info/${state.projectId}/0/2`);
        } else {
            history.push(`/my-favorites`);
        }
    };

    const removeFromFavorites = () => {
        setState({
            ...state,
            selectedProducts: Array<MergedFavoriteProduct>(),
            deletedProducts: state.selectedProducts,
        });
    };

    const setSelectedProducts = (selectedProducts: Array<MergedFavoriteProduct>) => {
        setState({ ...state, selectedProducts: selectedProducts });
    };

    const setFilteredDivisions = (divisions: Array<Division<MergedFavoriteProduct>>) => {
        const copyDivision = JSON.parse(JSON.stringify(divisions)) as Array<Division<MergedFavoriteProduct>>;
        const filteredDivision = copyDivision;
        setState({ ...state, filteredDivision: filteredDivision });
        return filteredDivision;
    };

    const setFilteredMode = (filterMode: boolean) => {
        setIsFilterMode(filterMode ? true : false);
        return filterMode;
    };

    const setFilteringInProgress = (filterInProgress: boolean) => {
        setIsFilteringInProgress(filterInProgress);
        return filterInProgress;
    };

    return (
        <>
            <PageHeader
                title={`${authReducer.user?.organization?.name + ' Favorite Products Library'}`}
                children={
                    <FavoriteProductsAction
                        showActions
                        selectedProducts={state.selectedProducts}
                        favoriteType={FavoriteType.OfficeFavorite}
                        products={favoriteProducts}
                    />
                }
            />
            <FavoriteProducts
                action={getOfficeFavoritesAction}
                favoriteType={FavoriteType.OfficeFavorite}
                removedProducts={state.deletedProducts}
                selectedProducts={state.selectedProducts}
                productId={params.productId}
                setFavoriteProducts={setFavoriteProducts}
                handleSelectProduct={selectProduct}
                setSelectedProducts={setSelectedProducts}
                removeFavorites={removeFromFavorites}
                addProductsToProject={addProductsToProject}
                addProductsToFavorite={addToFavorite}
                isFilterMode={isFilterMode}
                isFilteringInProgress={isFilteringInProgress}
                filteredDivisions={state.filteredDivision}
                setFilteredMode={setFilteredMode}
                setFilteredDivisions={setFilteredDivisions}
                setFilteringInProgress={setFilteringInProgress}
            />
            {isOpen && (
                <StartProject
                    isOpen={isOpen}
                    handleCancel={onClose}
                    showProjects
                    fromFavorite
                    addProductsToProject={addProducts}
                />
            )}
            {modals.warning && (
                <ExistingProductsModal
                    isOpen={modals.warning}
                    closeModal={goToProducts}
                    existingProducts={state.existingProducts}
                    addedTo={state.destination}
                    addedFrom={'office favorite products library'}
                    currentPage="favorites"
                />
            )}
        </>
    );
};

export default OrganizationFavoriteProducts;
