import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import ChangeUsersModal from '../../components/ChangeUsersModal/ChangeUsersModal';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import PriceHelper from '../../helpers/priceHelper';
import { useModalState } from '../../hooks/modalState';
import { usePermissions } from '../../hooks/permissions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { SubscriptionTypes } from '../../models/subscription/enums';
import {
    createTrialSubscriptionAction,
    getCheckoutUpdateLinkAction,
} from '../../redux/actions/checkoutActions';
import { getSubscriptionAction } from '../../redux/actions/subscriptionActions';
import { SubscriptionPlan } from '../../redux/actions/subscriptionActions/types';
import { changeSubscription } from '../../redux/reducers/authReducer/AuthSlice';
import { addPages } from '../../redux/reducers/navigationReducer/NavigationSlice';
import { setSubscriptionUpdating } from '../../redux/reducers/subscriptionReducer/SubscriptionSlice';
import { alertService } from '../../services';
import { PageContainer, PrimaryButton, SpaceCenter } from '../../styles/common.styles';
import {
    BuyButton,
    PlanCard,
    PlanCardHeader,
    PlanContainer,
    PlanContent,
    PlanDescription,
    PlanDivider,
    PlanPrice,
    SubscriptionPlanSpin,
} from './PlanSelection.styles';

const nonIndividualSubs = ['Office'];

const PlanSelection: React.FC = () => {
    const dispatch = useAppDispatch();
    const { authReducer, subscriptionReducer } = useAppSelector((store) => store);
    const history = useHistory();
    const { onOpen, onClose, isOpen } = useModalState();
    const { getAccountSubscription } = usePermissions();

    const [subscriptionPlans, setSubscriptionPlans] = useState<Array<SubscriptionPlan>>([]);

    const helper = new PriceHelper(subscriptionPlans);

    for (let i = 0; i < subscriptionPlans.length; i++) {
        const name = subscriptionPlans[i].name;
        const condit = !nonIndividualSubs.includes(name);
        subscriptionPlans[i].price = condit ? helper.getSubscriptionPrice(name) : helper.getMultiUserSubscriptionPrice(
                  name,
                  2,
              );
    }

    useEffect(() => {
        dispatch(
            addPages([
                {
                    key: NavigationKey.DASHBOARD,
                    value: `${authReducer.user?.firstName}’s Dashboard`,
                },
                {
                    key: NavigationKey.PLAN,
                    value: `Choose plan`,
                },
            ]),
        );
        getSubscriptions();
    }, []);

    const getSubscriptions = async () => {
        const { payload } = (await dispatch(getSubscriptionAction())) as any;
        if (payload.data) {
            setSubscriptionPlans(payload.data);
        } else {
            alertService.error('Sorry, plans is not available now');
        }
    };

    const createTrialSubscription = async (planName: SubscriptionTypes) => {
        if (authReducer.user) {
            const { payload } = (await dispatch(
                createTrialSubscriptionAction({
                    planName: planName,
                    planId:
                        subscriptionPlans.find((sp) => sp.name === 'FreeTrial' + planName)?.id || 1,
                    email: authReducer.user.email,
                    firstName: authReducer.user.firstName,
                    lastName: authReducer.user.lastName,
                    usersCount: planName === SubscriptionTypes.OFFICE ? 2 : 1,
                    accountId: authReducer.user.accountId,
                }),
            )) as any;
            if (payload) {
                history.push('/dashboard');
                const currentPlanName =
                    planName === SubscriptionTypes.INDIVIDUAL
                        ? SubscriptionTypes.FREE_TRIAL_INDIVIDUAL
                        : SubscriptionTypes.FREE_TRIAL_OFFICE;
                const plan = subscriptionPlans.find((subscriptionPlan) => (subscriptionPlan.name = currentPlanName));
                dispatch(
                    changeSubscription({
                        subscriptionPlanId: plan?.id || 0,
                        subscriptionPlanName: currentPlanName || SubscriptionTypes.FREE,
                    }),
                );
                alertService.success('Congratulate, your account was upgraded');
            }
        }
    };

    const getSubscriptionLink = async (planName: SubscriptionTypes, usersCount: number) => {
        if (authReducer.user) {
            const { payload } = (await dispatch(
                getCheckoutUpdateLinkAction({
                    planName: planName,
                    customerEmail: authReducer.user.email,
                    usersCount: planName === SubscriptionTypes.OFFICE ? usersCount : 1,
                    accountId: authReducer.user.accountId,
                }),
            )) as any;
            if (payload.url) {
                dispatch(setSubscriptionUpdating(true));
                window.location.href = payload.url;
            }
        }
    };

    return (
        <>
            <PageHeader title="Choose your plan" showMenu={false}></PageHeader>
            <PageContainer>
                <Spin size="large" spinning={authReducer.pending}>
                    <PlanContainer>
                        {getAccountSubscription() !== SubscriptionTypes.FREE_TRIAL_OFFICE && (
                            <PlanCard>
                                <PlanCardHeader>Individual</PlanCardHeader>
                                <PlanContent>
                                    <PlanPrice>
                                        <SubscriptionPlanSpin
                                            spinning={subscriptionReducer.pending}
                                        >
                                            $
                                            {
                                                subscriptionPlans.find(
                                                    (plan) =>
                                                        plan.name === SubscriptionTypes.INDIVIDUAL,
                                                )?.price
                                            }{' '}
                                            / month
                                        </SubscriptionPlanSpin>
                                    </PlanPrice>
                                    <PlanDivider></PlanDivider>
                                    {/* <PlanSavePrice>$XX.00 / year (save $XX)</PlanSavePrice> */}
                                    <PlanDescription>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam.
                                    </PlanDescription>
                                    {getAccountSubscription() === SubscriptionTypes.FREE &&
                                    !authReducer.user?.usedTrialSubscription ? (
                                        <SpaceCenter>
                                            <PrimaryButton
                                                onClick={() =>
                                                    createTrialSubscription(
                                                        SubscriptionTypes.INDIVIDUAL,
                                                    )
                                                }
                                            >
                                                Try free for 14 days
                                            </PrimaryButton>
                                            <BuyButton
                                                onClick={() => {
                                                    getSubscriptionLink(
                                                        SubscriptionTypes.INDIVIDUAL,
                                                        1,
                                                    );
                                                }}
                                            >
                                                or buy now
                                            </BuyButton>
                                        </SpaceCenter>
                                    ) : (
                                        <SpaceCenter>
                                            <PrimaryButton
                                                onClick={() =>
                                                    getSubscriptionLink(
                                                        SubscriptionTypes.INDIVIDUAL,
                                                        1,
                                                    )
                                                }
                                            >
                                                Buy now
                                            </PrimaryButton>
                                        </SpaceCenter>
                                    )}
                                </PlanContent>
                            </PlanCard>
                        )}
                        <PlanCard>
                            <PlanCardHeader>Office</PlanCardHeader>
                            <PlanContent>
                                <PlanPrice>
                                    <SubscriptionPlanSpin spinning={subscriptionReducer.pending}>
                                        $
                                        {
                                            subscriptionPlans.find(
                                                (plan) => plan.name === SubscriptionTypes.OFFICE,
                                            )?.price
                                        }{' '}
                                        / month
                                    </SubscriptionPlanSpin>
                                </PlanPrice>
                                <PlanDivider></PlanDivider>
                                {/* <PlanSavePrice>$XXX.00 / year (save $XX)</PlanSavePrice> */}
                                <PlanDescription>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                    enim ad minim veniam.
                                </PlanDescription>
                                {getAccountSubscription() === SubscriptionTypes.FREE &&
                                !authReducer.user?.usedTrialSubscription ? (
                                    <SpaceCenter>
                                        <PrimaryButton
                                            onClick={() =>
                                                createTrialSubscription(SubscriptionTypes.OFFICE)
                                            }
                                        >
                                            Try free for 14 days
                                        </PrimaryButton>
                                        <BuyButton onClick={onOpen}>or buy now</BuyButton>
                                    </SpaceCenter>
                                ) : (
                                    <SpaceCenter>
                                        <PrimaryButton onClick={() => onOpen()}>
                                            Buy now
                                        </PrimaryButton>
                                    </SpaceCenter>
                                )}
                            </PlanContent>
                        </PlanCard>
                    </PlanContainer>
                </Spin>
            </PageContainer>
            <ChangeUsersModal
                isOpen={isOpen}
                handleCancel={onClose}
                subscriptionName={authReducer.user?.subscriptionPlanName || 'Free'}
                currentUsersCount={
                        subscriptionPlans.find(
                                                    (plan) =>
                                                        plan.name === authReducer.user?.subscriptionPlanName,
                                                )?.defaultMaxUserCount
                }
                updateSubscription={getSubscriptionLink}
            />
        </>
    );
};

export default PlanSelection;
