import React from "react";
import { ActionCheckboxContainer, HeaderContainer, InfoContainer, NotesContainer, ProductNameContainer, SectionNameContainer } from "./ProductOutsideTCItemHeader.styles";
import { Product } from "../../../../models/product/types";
import { MergedFavoriteProduct } from "../../FavoriteProducts.types";
import { ProductHeaderLink } from "./ProductItemHeader.styles";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface Props {
    product: Product | MergedFavoriteProduct;
    productSelected?: boolean;
    showCheckbox: boolean;
    handleProductSelection?: (data: CheckboxChangeEvent) => void;
};

const ProductOutsideTCItemHeader: React.FC<Props> = ({
    product,
    productSelected,
    showCheckbox,
    handleProductSelection
}) => {
    const getCheckbox = () => {
        if (!showCheckbox) {
            return <></>;
        }

        return (
            <Checkbox
                checked={productSelected}
                onChange={handleProductSelection}
                data-company={product.company}
                data-product={product.productName}
            />
        );
    }

    return (
        <HeaderContainer>
            <SectionNameContainer>
                {product.mfSection.number} {product.mfSection.label}
            </SectionNameContainer>
            <InfoContainer>
                <ProductNameContainer>
                    <ActionCheckboxContainer>
                        {getCheckbox()}
                        <ProductHeaderLink className={product.productUrl ? '' : 'black'} href={product.productUrl} target='_blank'>
                            {product.productName}
                        </ProductHeaderLink>
                    </ActionCheckboxContainer>
                </ProductNameContainer>
                <NotesContainer>
                    <span>
                        <span className='bold'>Note:</span> this product was manually added and is not in the Transparency Catalog.
                    </span>
                </NotesContainer>
            </InfoContainer>
        </HeaderContainer>
    );
}

export default ProductOutsideTCItemHeader;