import React, { useEffect, useState } from 'react';
import EC3Login from '../../../../../components/projects/projectActions/ec3Login/EC3Login';
import { useModalState } from '../../../../../hooks/modalState';
import { useAppDispatch } from '../../../../../hooks/redux';
import { GetProjectInformationResponse } from '../../../../../redux/actions/projectActions/types';
import { setEc3ProjectBegan } from '../../../../../redux/reducers/ec3Reducer/EC3Slice';
import { blueDark01 } from '../../../../../styles/colors';
import { ExportText, ExportTitle, ExportEC3Divider, ExportButton } from './ExportHeader.styles';
import { SubscriptionTypes } from '../../../../../models/subscription/enums';
import { usePermissions } from '../../../../../hooks/permissions';
import { EC3Export } from '../../../../../models/ec3/types';
import { alertService } from '../../../../../services';
import { getProjectProductsAction } from '../../../../../redux/actions/productActions';
import { InternalProduct } from '../../../../../models/product/types';
import { ActionResponse } from '../../../../../models/actionResponse/types';

interface Props {
    projectInfo: GetProjectInformationResponse | null;
    ec3ExportData: EC3Export;
}
const ExportHeader: React.FC<Props> = ({ projectInfo, ec3ExportData }) => {
    const ec3Login = useModalState();
    const dispatch = useAppDispatch();
    const { getAccountSubscription } = usePermissions();
    const [productIds, setProductIds] = useState<Array<InternalProduct>>([]);

    useEffect(() => {
        getProductIds();
    }, [projectInfo]);

    const getProductIds = async () => {
        if (!projectInfo) {
            return;
        }

        if (!productIds || productIds.length === 0) {

            const { payload } = (await dispatch(
                getProjectProductsAction(projectInfo.id),
            )) as ActionResponse<Array<InternalProduct>>;

            if (payload?.data) {
                setProductIds(payload.data);
            }

        }
    };

    const onExportButtonClicked = () => {
        if (!productIds?.length) {
            console.log('There are no products to export');
            alertService.warn('There are no products to export');
            return;
        }

        ec3Login.onOpen();
    }

    const exportBegun = () => {
        ec3Login.onClose();
        dispatch(setEc3ProjectBegan());
    };

    return (
        <>
            <ExportTitle>
                Export to the Embodied Carbon in Construction Calculator (EC3)
            </ExportTitle>
            <ExportText weight={400} size={14} height={18} color={blueDark01}>
                Export this project to the EC3 tool to plan and compare buildings. This can take a
                little while, but you can keep an eye on <br /> your export’s progress on this tab.
            </ExportText>
            <ExportButton
                disabled={
                    getAccountSubscription() === SubscriptionTypes.FREE
                } onClick={onExportButtonClicked}>{!ec3ExportData?.eC3Project?.link ? 'Export' : 'Export again'}</ExportButton> <ExportEC3Divider />
            {ec3Login.isOpen && projectInfo && productIds?.length && (
                <EC3Login
                    closeModal={ec3Login.onClose}
                    isOpen={ec3Login.isOpen}
                    project={projectInfo}
                    productIds={productIds}
                    reload={exportBegun}
                    activeTab={'5'}
                />
            )}
        </>
    );
};

export default ExportHeader;
