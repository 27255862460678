import React from 'react';
import { useHistory } from 'react-router-dom';

import { CloseIcon } from '../../styles/common.styles';
import { ShowProductsModal } from './ProductsModal.styles';
import ModalFooter from '../common/modalFooter/ModalFooter';

interface Props {
    projectId?: number;
    isOpen: boolean;
    closeModal: (data: { key: 'one' }) => void;
    setProductsPackageId: (productsPackageId: string) => void;
}

const ProductsModal: React.FC<Props> = ({
    projectId,
    isOpen,
    closeModal,
    setProductsPackageId,
}) => {
    const history = useHistory();

    const handleCancel = () => {
        closeModal({ key: 'one' });
    };
    const projectSplit = window.location.href.split('/');
    const source =
        `${projectSplit[0]}//${projectSplit[2]}/products/` +
        (projectId === undefined ? '#' : projectId + '/#');
    const iframeLoaded = () => {
        const iframe = document.getElementById('iframe') as HTMLIFrameElement;
        if (iframe && iframe.contentDocument && iframe.contentWindow) {
            const checkOnload = setInterval(() => {
                const button = iframe.contentDocument?.getElementById('exportBtn');
                if (button) {
                    button.addEventListener('click', () => {
                        hideIframe(iframe);

                        const oldHref = iframe.contentWindow?.location.href;
                        const interval = setInterval(() => {
                            const currentHref = iframe.contentWindow?.location.href;
                            if (currentHref !== oldHref) {
                                clearInterval(interval);
                                if (iframe.contentWindow?.location) {
                                    const location = iframe.contentWindow?.location;
                                    if (location) {
                                        if (projectId === undefined) {
                                            const regex = new RegExp(/\/([^\/]+)$/mu);
                                            const productsPackageId = regex.exec(
                                                location as unknown as string,
                                            );
                                            handleCancel();
                                            if (productsPackageId && productsPackageId[1]) {
                                                setProductsPackageId(productsPackageId[1]);
                                            }
                                        } else {
                                            history.push(iframe.contentWindow.location);
                                            window.location.reload();
                                        }
                                    }
                                }
                            }
                        }, 50);
                    });
                }
                if (button) {
                    clearInterval(checkOnload);
                }
            }, 2000);
        }
    };

    const hideIframe = (iframe: HTMLIFrameElement) => {
        iframe.style.visibility = 'hidden';
    };

    return (
        <ShowProductsModal
            open={isOpen}
            onCancel={handleCancel}
            footer={null}
            centered
            closeIcon={<CloseIcon />}
        >
            <iframe
                id="iframe"
                width={'100%'}
                height={'100%'}
                style={{ border: '2px solid #eee' }}
                title="productsIframe"
                src={source}
                onLoad={iframeLoaded}
            ></iframe>
            <script type="text/javascript" src="./script.js"></script>
            <ModalFooter
                action="Get products"
                cancel={handleCancel}
                submit={() => {}}
                actionButtonWidth={0}
            />
        </ShowProductsModal>
    );
};

export default ProductsModal;
