import { Form, UploadProps, Image, DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { scrollToTarget } from '../../../../helpers/DOM';
import { useAppDispatch } from '../../../../hooks/redux';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { SelectData } from '../../../../models/common/types';
import { FavoriteType } from '../../../../models/product/enums';
import { Product } from '../../../../models/product/types';
import {
    DraggerText,
    DraggerTextAction,
} from '../../../../pages/projectInfo/tabs/projectInformation/ProjectInformation.styles';
import { uploadFavoriteFileAction } from '../../../../redux/actions/favoriteProductsActions';
import { uploadProductFileAction } from '../../../../redux/actions/productActions';
import { alertService } from '../../../../services';
import { blueDark01, blueLink, grayDark03 } from '../../../../styles/colors';
import { BoldText, Text } from '../../../../styles/common.styles';
import CustomInputUploadFileForm from '../../../common/inputs/CustomInputUploadFileForm';
import CustomSelectUploadFileForm from '../../../common/select/CustomSelectUploadFileForm';
import CustomSelectUploadFileFormSearch from '../../../common/select/CustomSelectUploadFileFormSearch';
import { MergedFavoriteProduct } from '../../../favoritProducts/FavoriteProducts.types';
import { UploadFileData, UploadsTableFields } from '../Uploads.types';
import {
    DatePickerContainer,
    SaveUploadsButton,
    UploadChooseFile,
    UploadDraggerText,
    UploadFileDragger,
    UploadFileInputFlexRow,
    UploadFormSubText,
} from './UploadFileForm.styles';
import { UploadType } from '../UploadsInfo';
import DocumentNameInput from './DocumentNameInput';
import AlternativeTextInput from './AlternativeTextInput';
import DescriptionInput from './DescriptionInput';
import { UploadFileContainer } from '../UploadsInfo.styles';

interface Props {
    allDocumentTypes: Array<SelectData> | undefined;
    favoriteType?: FavoriteType;
    product: MergedFavoriteProduct | Product;
    setNewFile: (file: UploadsTableFields) => void;
    uploadType: UploadType
}

const UploadFileForm: React.FC<Props> = ({
    allDocumentTypes,
    favoriteType,
    product,
    setNewFile,
    uploadType,
}) => {
    const dispatch = useAppDispatch();

    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [form] = Form.useForm();
    const [fileError, setFileError] = useState(false);

    const onSubmit = async (data: UploadFileData) => {
        if (!file) {
            setFileError(true);
            return;
        }
        if (file.size < 1) {
            alertService.error('0 bytes size file cannot be attached', { fixed: true });
            return;
        }

        let result;
        const fileToSave = file;
        setFile(null);
        const usePictureTypeId = uploadType === UploadType.PICTURE && allDocumentTypes?.length;
        const documentTypeId = usePictureTypeId ? Number(allDocumentTypes[0].id) : data.documentTypeId;

        if (favoriteType !== undefined) {
            const { payload } = (await dispatch(
                uploadFavoriteFileAction({
                    file: fileToSave,
                    documentName: data.documentName,
                    documentTypeId: documentTypeId,
                    alternativeText: data.alternativeText,
                    description: data.description,
                    favoriteType: favoriteType,
                    productFavoriteId: product.id,
                    expirationOn: data?.expirationOn?._d
                        ? new Date(data?.expirationOn?._d).toJSON()
                        : undefined,
                }),
            )) as ActionResponse<UploadsTableFields>;
            if (payload) {
                result = payload;
            }
        } else {
            const { payload } = (await dispatch(
                uploadProductFileAction({
                    productId: product.product.productId,
                    projectProductId: product.product.id,
                    productName: product.productName,
                    projectId: product.product.projectId,
                    file: fileToSave,
                    documentName: data.documentName,
                    documentTypeId: documentTypeId,
                    description: data.description,
                    alternativeText: data.alternativeText,
                    expirationOn: data?.expirationOn?._d
                        ? new Date(data?.expirationOn?._d).toJSON()
                        : undefined,
                }),
            )) as ActionResponse<UploadsTableFields>;
            if (payload.data) {
                result = payload;
            }
        }

        if (result) {
            setFileName('');
            form.resetFields();
            setNewFile(result.data);
            alertService.success('New upload was added', { fixed: true });
            const scrollableDiv = document.getElementById('upload-list');
            const targetElement = document.getElementById(result.data.id.toString());
            scrollToTarget(targetElement, scrollableDiv);
        }
    };

    const extensionChecker = (value: any) => {
        const allowedPictureExtensions = ['.JPG','.JPEG','.GIF','.PNG'];
        for (var i = 0; i < allowedPictureExtensions.length; i++) {
            if (value.indexOf(allowedPictureExtensions[i]) > -1) {
                return true;
            }
        }
         return false;
    }

    const budgetFile = () => {
        const props: UploadProps = {
            name: 'file',
            multiple: false,
            beforeUpload: () => false,
            onChange(info: any) {
                if (!info?.file) {
                    alertService.error('Something went wrong');
                    return;
                }
                if (info.file.size < 1) {
                    alertService.error('0 bytes size file cannot be attached', { fixed: true });
                    return;
                }
                if (uploadType === 'document' && info.file.size > 10485760) {
                    alertService.error('Files cannot be larger than 10Mb.', { fixed: true });
                    return;
                }
                if (uploadType === 'picture' && info.file.size > 10485760) {
                    alertService.error('Files cannot be larger than 10Mb.', { fixed: true });
                    return;
                }
                if (uploadType === 'picture' && !extensionChecker(info.file.name.toUpperCase())) {
                    alertService.error('.PNG, .JPG, .GIF allowed', { fixed: true });
                    return;
                }
                setFileName(info.file.name);
                form.setFieldValue('documentName', info.file.name);
                setFile(info.file);
                setFileError(false);
            },
        };
        return (
            <>
                {' '}
                <UploadFileDragger
                    multiple={false}
                    showUploadList={false}
                    style={{marginLeft: -16 + 'px', width: 'calc(100% + 16px)'}}
                    fileList={undefined}
                    {...props}
                    className={fileError ? 'error' : ''}
                >
                    <Image src="src/assets/images/file-lines.svg" preview={false} alt="file" />
                    {!fileName ? (
                        <>
                            <UploadChooseFile weight={400} size={14} height={18} color={blueLink}>
                                Choose {uploadType === 'document' ? 'a document' : 'an image'}
                            </UploadChooseFile>
                            <UploadDraggerText
                                weight={400}
                                size={14}
                                height={18}
                                color={blueDark01}
                            >
                                or drag it here
                            </UploadDraggerText>
                        </>
                    ) : (
                        <UploadDraggerText weight={400} size={14} height={18} color={blueLink}>
                            {fileName}
                        </UploadDraggerText>
                    )}
                </UploadFileDragger>
                {fileName && (
                    <DraggerTextAction
                        multiple={false}
                        showUploadList={false}
                        fileList={undefined}
                        {...props}
                    >
                        <UploadFileInputFlexRow>
                            <BoldText weight={400} size={14} height={18} color={blueLink}>
                                Choose a {uploadType}
                            </BoldText>
                            <Text weight={400} size={14} height={18} color={blueDark01}>
                                or drag it here
                            </Text>
                        </UploadFileInputFlexRow>
                    </DraggerTextAction>
                )}
                <DraggerText style={{marginLeft: -16 + 'px'}} weight={400} size={12} height={14} color={grayDark03}>
                    {uploadType === 'document' ? `Any document type (10Mb maximum)` : `PNG, JPG, GIF (10Mb maximum)`}
                </DraggerText>
            </>
        );
    };

    const changeDocumentName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileName(event.target.value);
    };

    const onFailed = () => {
        if (!file) {
            setFileError(true);
        }
    };

    console.log('allDocumentTypes:');
    console.log(allDocumentTypes);

    return (
        <Form onFinish={onSubmit} form={form} onFinishFailed={onFailed}>
            <BoldText weight={400} size={13} height={16} color={blueDark01}>
                Upload {uploadType === 'document' ? uploadType : 'images'}
            </BoldText>
            {uploadType === 'document'  && (
                <UploadFormSubText weight={400} size={12} height={14} color={blueDark01}>
                    Upload {uploadType}s to your project
                </UploadFormSubText>
            )}
            <UploadFileContainer>
                <CustomInputUploadFileForm label="" name="isConfidential" Component={budgetFile} />
            </UploadFileContainer>
            <DocumentNameInput
                label={uploadType === 'document' ? `Document name ` : `Image name`}
                htmlFor="document_name"
                name="documentName"
                onChange={changeDocumentName}
            />
            {uploadType === 'picture' && (
                <AlternativeTextInput
                    label='Alternative text'
                    suffix='(optional)'
                    name='alternativeText'
                    placeholder='Alternative text'
                />
            )}
            {
                uploadType === UploadType.DOCUMENT &&
                <CustomSelectUploadFileFormSearch
                    label="Document type"
                    htmlFor="document_type"
                    name="documentTypeId"
                    options={allDocumentTypes.filter((documentType) => documentType.isDeleted !== true) || []}
                    placeholder="Select one"
                    rules={[{ required: true, message: '' }]}
                    block
                    required
                />
            }
            {uploadType === 'document'  && (
            <DatePickerContainer>
                <CustomInputUploadFileForm
                    Component={DatePicker}
                    label="Expiration date"
                    suffix='(optional)'
                    name="expirationOn"
                    props={{
                        placeholder: 'MM/DD/YYYY',
                        format: 'MM/DD/YYYY',
                        disabledDate: (current: any) => {
                            const customDate = moment().format('YYYY-MM-DD');
                            return current && current < moment(customDate, 'YYYY-MM-DD');
                        },
                        suffixIcon: (
                            <Image
                                src="src/assets/images/calendar-day.svg"
                                preview={false}
                                alt="calendar"
                            />
                        ),
                    }}
                    block
                />
            </DatePickerContainer>
            )}
            <DescriptionInput
                name='description'
                label='Description'
                suffix='(optional)'
                placeholder='Add description'
            />
            <SaveUploadsButton className={uploadType === UploadType.PICTURE ? 'pictureSaveBtn' : ''} htmlType="submit">
                Save
            </SaveUploadsButton>
        </Form>
    );
};

export default UploadFileForm;
