/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input } from 'antd';
import mapboxgl from '!mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import CustomInput from '../common/inputs/CustomInput';
import CustomSelect from '../common/select/CustomSelect';
import { GetProjectInformationResponse } from '../../redux/actions/projectActions/types';
import { AddressAutofill } from '@mapbox/search-js-react';
import { MailboxContainer, CustomSpace } from './MapContainer.styles';
import { states } from './MapContainerData';
import 'mapbox-gl/dist/mapbox-gl.css';

interface Props {
    projectInfo: GetProjectInformationResponse | null;
    form: any;
    disabled: boolean
}

const MapContainerWrapper: React.FC<Props> = ({ projectInfo, form, disabled }) => {
    const map = useRef(null);

    const mapContainer = useRef(null);
    const [longitude, setLongitude] = useState(-70.9);
    const [latitude, setLatitude] = useState(42.35);
    const [zoom, setZoom] = useState(1.3);
    const [boundingBox, setBoundingBox] = useState<[number, number, number, number]>(states[0].box);
    const [stateName, setStateName] = useState(states[1].name);
    const [currentMarkers, setCurrentMarkers] = useState(Array<any>());

    useEffect(() => {
        if (map.current) {
            return;
        }

        if (projectInfo) {
            const locationX = parseFloat((projectInfo as any)?.locationX);
            const locationY = parseFloat((projectInfo as any)?.locationY);
            const address = projectInfo.address;
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
                center: [locationY || -101, locationX || 52.35],
                zoom: zoom,
                accessToken:
                    'pk.eyJ1IjoidmhvbmNoYXJ1ayIsImEiOiJjbDk0NGZjOGgwNWl1M3JuM2JheTcyZjlvIn0.zfzt8Dpxl3mFGQE5Q85e-A',
            });
            if (locationY && locationX && address) {
                const marker = new mapboxgl.Marker({
                    color: '#e7453c',
                });
                marker.setLngLat([locationY, locationX]).addTo(map.current);
                setCurrentMarkers((prev) => [...prev, marker]);
            }
        }
    }, [projectInfo]);

    useEffect(() => {
        if (projectInfo) {
            form.setFieldsValue(projectInfo);
            if (projectInfo.locationX && projectInfo.locationY) {
                setLongitude(+projectInfo.locationX);
                setLatitude(+projectInfo.locationY);
                (map.current as any)?.setZoom(10);
            }
        }
    }, [projectInfo]);

    useEffect(() => {
        if (map && projectInfo) {
            (map.current as any)?.on('move', () => {
                projectInfo.locationX = (map.current as any).getCenter().lat.toFixed(5);
                projectInfo.locationY = (map.current as any).getCenter().lng.toFixed(5);
                setZoom((map.current as any).getZoom().toFixed(2));
            });
        }
    }, [map.current]);

    const handleRetrieve = (res: any) => {
        if (currentMarkers !== null) {
            currentMarkers.forEach((marker) => marker.remove());
        }
        const [_longitude, _latitude] = res.features[0].geometry.coordinates;
        setLongitude(_longitude);
        setLatitude(_latitude);
        if (map && map.current) {
            (map.current as any)?.flyTo({
                center: [_longitude, _latitude],
                zoom: 10,
            });
            const marker = new mapboxgl.Marker({
                color: '#e7453c',
            });
            marker.setLngLat([_longitude, _latitude]).addTo(map.current);
            setCurrentMarkers((prev) => [...prev, marker]);
            form.setFieldValue('state', res.features[0].properties.region);
            form.setFieldValue('locationX', _latitude);
            form.setFieldValue('locationY', _longitude);
        }
    };

    const handleChange = (id: string) => {
        const stateInstance = states.find((x: any) => x.id === id) ?? states[0];
        if (stateInstance.name !== projectInfo.state) {
            form.setFieldValue('address', '');
            form.setFieldValue('city', '');
            form.setFieldValue('zip', '');
            if (currentMarkers !== null) {
                currentMarkers.forEach((marker) => marker.remove());
            }
        }
        setBoundingBox(stateInstance.box);
        setStateName(stateInstance.name);
    };

    const handleIntercept = (input: string) => {
        const suffix = ` ${stateName !== '--' ? stateName : ''}`;
        return input + suffix;
    };

    const stateZipContainer = () => {
        return (
            <CustomSpace>
                <CustomSelect
                    id="stateLabel"
                    label=""
                    name="state"
                    options={states}
                    placeholder="Select state"
                    props={{ onChange: (id: string) => handleChange(id) }}
                />
                <CustomInput
                    Component={Input}
                    label="ZIP"
                    name="zip"
                    props={{
                        autoComplete: 'postal-code',
                    }}
                    rules={[
                        {
                            max: 50,
                            message: 'Value should be less than 50 characters',
                        },
                    ]}
                />
            </CustomSpace>
        );
    };

    return (
        <div>
            <AddressAutofill
                accessToken={
                    'pk.eyJ1IjoidmhvbmNoYXJ1ayIsImEiOiJjbDk0NGZjOGgwNWl1M3JuM2JheTcyZjlvIn0.zfzt8Dpxl3mFGQE5Q85e-A'
                }
                options={{
                    language: 'en',
                    country: 'US',
                    bbox: boundingBox,
                }}
                interceptSearch={(input) => handleIntercept(input)}
                onRetrieve={(e) => handleRetrieve(e)}
            >
                <>
                    <CustomInput
                        Component={Input}
                        label="Street address"
                        name="address"
                        props={{
                            autoComplete: 'address-line1',
                            disabled
                        }}
                        rules={[
                            {
                                max: 200,
                                message: 'Value should be less than 200 characters',
                            },
                        ]}
                    />
                    <CustomInput
                        Component={Input}
                        label="City"
                        name="city"
                        props={{
                            autoComplete: 'address-level2',
                        }}
                        rules={[
                            {
                                max: 50,
                                message: 'Value should be less than 200 characters',
                            },
                        ]}
                    />
                    <CustomInput
                        label="State"
                        name="isConfidential"
                        Component={stateZipContainer}
                    />
                </>
            </AddressAutofill>
            <div id="input-container" className=" ">
                <div
                    className="ant-form-item sc-hhOBVt buyqfG ant-form-item-has-success"
                    style={{ display: 'block' }}
                >
                    <div className="ant-row ant-form-item-row">
                        <div className="ant-col ant-col-20 ant-form-item-label">
                            <label htmlFor="basic_city" className="" title="City"></label>
                        </div>
                        <div className="ant-col ant-form-item-control">
                            <div className="ant-form-item-control-input">
                                <div className="ant-form-item-control-input-content">
                                    <MailboxContainer
                                        ref={mapContainer}
                                        className="map-container"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapContainerWrapper;
