import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Space, Spin, Typography } from 'antd';
import { getCards } from './Subscription.mockData';
import SubscriptionCard from './SubscriptionCard';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getSubscriptionAction } from '../../redux/actions/subscriptionActions';
import { SubscriptionPlan } from '../../redux/actions/subscriptionActions/types';
import { alertService } from '../../services';
import { style } from './Subscription.styles';
import { useHistory } from 'react-router-dom';
import PriceHelper from '../../helpers/priceHelper';
import { ActionResponse } from '../../models/actionResponse/types';

const nonIndividualSubs = ['Office'];

const SubscriptionPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);

    const [subscriptionPlans, setSubscriptionPlans] = useState<Array<SubscriptionPlan>>([]);
    const [usersCount, setUsersCount] = useState(2);
    const helper = new PriceHelper(subscriptionPlans);

    for (let i = 0; i < subscriptionPlans.length; i++) {
        const condit = !nonIndividualSubs.includes(subscriptionPlans[i].name);
        subscriptionPlans[i].price = condit ? helper.getSubscriptionPrice(subscriptionPlans[i].name) : helper.getMultiUserSubscriptionPrice(
                  subscriptionPlans[i].name,
                  usersCount,
              );
    }

    const history = useHistory();
    useEffect(() => {
        getSubscriptions();
    }, []);

    const getSubscriptions = async () => {
        const { payload } = (await dispatch(getSubscriptionAction())) as ActionResponse<
            Array<SubscriptionPlan>
        >;
        if (payload.data) {
            setSubscriptionPlans(payload.data);
        } else {
            alertService.error('Sorry, plans is not available now');
        }
    };

    const choosePlan = async (planName: string) => {
        const plan = subscriptionPlans.find(
            (subscriptionPlan) => subscriptionPlan.name === planName,
        );
        if (plan) {
            history.push(`/register/${plan.id}/${usersCount}`);
        }
    };

    const handleUsersCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentCount = event.currentTarget.value;
        if (/^-?\d*\.?\d*$/.test(currentCount) && +currentCount < 101 && +currentCount > 1) {
            setUsersCount(+currentCount);
        }
    };

    return (
        <Spin size="large" spinning={selector.subscriptionReducer.pending}>
            <div style={style.container}>
                {!selector.subscriptionReducer.pending && (
                    <>
                        <Space direction="horizontal" style={style.tableTitle}>
                            <Typography.Title level={2} style={style.title}>
                                Compare & choose plans
                            </Typography.Title>
                        </Space>
                        <Divider type="horizontal" style={style.divider} />
                        <Space style={style.planContainer}>
                            <Row gutter={[0, 0]} className="choose-plans-container">
                                {getCards(subscriptionPlans).map((card, index) => {
                                    return (
                                        <Col span={8} style={style.gridItem} key={index}>
                                            <SubscriptionCard
                                                card={{
                                                    ...card,
                                                    plan: subscriptionPlans.find(
                                                        (item) => item.name === card.title,
                                                    ),
                                                }}
                                                choosePlan={choosePlan}
                                                handleUsersChange={handleUsersCountChange}
                                                usersValue={usersCount}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Space>
                    </>
                )}
            </div>
        </Spin>
    );
};

export default SubscriptionPage;
