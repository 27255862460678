import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CustomTabs from '../../components/common/tabs/CustomTabs';
import ProjectInformation from './tabs/projectInformation/ProjectInformation';
import {
    getProjectInformationAction,
    getProjectInformationWithCopyTokensOptionAction,
    getProjectReferencesAction,
    getProjectRolesAction,
    getTeamAction,
} from '../../redux/actions/projectActions';
import { GetProjectInformationResponse, GetTeamMembersResponse, TeamMember } from '../../redux/actions/projectActions/types';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import ProjectInfoActions from './projectInfoActions/ProjectInfoActions';
import { addPages, removePage } from '../../redux/reducers/navigationReducer/NavigationSlice';
import ProjectNotifications from './tabs/notifications/ProjectNotifications';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import ProductsTab from './tabs/productsTab/ProductsTab';
import { Division, Section } from '../../models/productDivision/types';
import {
    ConfidentialIcon,
    ProjectInfoTabContainer,
    ProjectStatusContainer,
} from './ProjectInfo.styles';
import { ProjectStatus } from './ProjectInfo.types';
import { Product } from '../../models/product/types';
import { ActionResponse } from '../../models/actionResponse/types';
import { ProjectReferences } from '../../models/project/types';
import EC3ExportTab from './tabs/ec3ExportTab/EC3ExportTab';
import { setViewingProject } from '../../redux/reducers/projectsReducer/ProjectSlice';
import { checkCookies } from '../../redux/persistedState';
import ProjectTeamTab from './tabs/ProjectTeamTab/ProjectTeam';
import { ProjectRole } from '../../models/common/types';

interface Params {
    id: string;
    tab?: string;
    share?: string;
    packageId?: string;
}
const ProjectInfo: React.FC = () => {
    const params = useParams<Params>();

    const [projectInfo, setProjectInfo] = useState<GetProjectInformationResponse | null>(null);
    const [projectReferences, setProjectReferences] = useState<ProjectReferences | null>(null);
    const [state, setState] = useState({
        divisions: Array<Division<Product>>(),
        installedDivision: Array<Division<Product>>(),
        filteredDivision: Array<Division<Product>>(),
        productIdsInfo: Array<InternalProduct>(),
        productsExternalInfo: Array<ExternalProduct>(),
        companiesExternalInfo: Array<BrandCompany>(),
    });
    const [activeTab, setActiveTab] = useState(params?.tab || '1');
    const [persistActiveTab, setPersistActiveTab] = useState<string | undefined>(undefined);
    const [isFilterMode, setIsFilterMode] = useState(false);
    const [isFilteringInProgress, setIsFilteringInProgress] = useState(false);
    const [searchText, setSearchText] = useState<string | undefined>(undefined);
    const [productsCount, setProductsCount] = useState({
        installedCount: 0,
        filteredCount: 0,
        totalCount: 0,
    });
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { authReducer, ec3Reducer } = selector;

    useEffect(() => {
        window.scroll(0, 0);
        getData();
        return () => {
            dispatch(setViewingProject(null));
        };
    }, []);

    const getData = async () => {
        await copyTokensFromCookiesIfAuthFailsAndGetProductInfo();
        await getReferences();
    };

    useEffect(() => {
        dispatch(
            addPages([
                {
                    key: NavigationKey.DASHBOARD,
                    value: `${authReducer.user?.firstName}’s Dashboard`,
                },
                {
                    key: NavigationKey.PROJECT_LIBRARY,
                    value: `${authReducer.user?.organization.name} Projects`,
                },
                {
                    key: `/project-info/${projectInfo?.id}`,
                    value: `${projectInfo?.name || ''}`,
                },
            ]),
        );
        return () => {
            dispatch(removePage());
            dispatch(removePage());
        };
    }, [projectInfo, ec3Reducer.exportedProject]);

    const copyTokensFromCookiesIfAuthFailsAndGetProductInfo = async () => {
        if (params.id) {
            console.log('Call GetProjectInformation from ProjectInfo.copyTokensFromCookiesIfAuthFails');
            const { payload }: any = await dispatch(getProjectInformationWithCopyTokensOptionAction(params.id));
            checkCookies();
            setProjectInfo({
                ...payload,
            });
            dispatch(setViewingProject(payload));
            setProductsCount({
                totalCount: payload.totalProductCount,
                filteredCount: 0,
                installedCount: payload.installedProductCount,
            });
        }
    }

    const getProjectInfo = async () => {
        if (params.id) {
            const { payload }: any = await dispatch(getProjectInformationAction(params.id));
            setProjectInfo({
                ...payload,
            });
            dispatch(setViewingProject(payload));
            setProductsCount({
                totalCount: payload.totalProductCount,
                filteredCount: 0,
                installedCount: payload.installedProductCount,
            });
        }
    };

    const clickProductsTabs = async (tab: string) => {
        if (tab === '2' || tab === '3') {
            setFilteredMode(false);
            setFilteringInProgress(false);
            setFilteredDivisions([]);
            setSearch(null);
        }
        return true;
    };


    const getReferences = async () => {
        const { payload } = (await dispatch(
            getProjectReferencesAction(),
        )) as ActionResponse<ProjectReferences>;
        setProjectReferences(payload.data);
    };

    const setDivisions = (divisions: Array<Division<Product>>) => {
        setState({ ...state, divisions });
    };

    const setAllDivisions = (divisions: Array<Division<Product>>) => {
        const copyDivision = JSON.parse(JSON.stringify(divisions)) as Array<Division<Product>>;
        const installedDivision = copyDivision.reduce((agg, value) => {
            const sections = value.sections.reduce((_agg, item) => {
                const products = item.products.filter((p) => p.isInstalled);
                if (products.length) {
                    item.products = products;
                    _agg.push(item);
                }
                return _agg;
            }, Array<Section<Product>>());
            if (sections.length) {
                value.sections = sections;
                agg.push(value);
            }
            return agg;
        }, Array<Division<Product>>());
        const installedProductsCount = installedDivision.reduce((agg, item) => {
            agg += item.sections.reduce((_agg, section) => {
                _agg += section.products.length;
                return _agg;
            }, 0);
            return agg;
        }, 0);
        const allProductsCount = divisions.reduce((agg, item) => {
            agg += item.sections.reduce((_agg, section) => {
                _agg += section.products.length;
                return _agg;
            }, 0);
            return agg;
        }, 0);

        setState({ ...state, divisions, installedDivision });
        setProductsCount({ installedCount: installedProductsCount, totalCount: allProductsCount });
        return installedDivision;
    };
    const setInstalledDivisions = (divisions: Array<Division<Product>>) => {
        const copyDivision = JSON.parse(JSON.stringify(divisions)) as Array<Division<Product>>;
        const installedDivision = copyDivision.reduce((agg, value) => {
            const sections = value.sections.reduce((res, item) => {
                const products = item.products.filter((p) => p.isInstalled);
                if (products.length) {
                    item.products = products;
                    res.push(item);
                }
                return res;
            }, Array<Section<Product>>());
            if (sections.length) {
                value.sections = sections;
                agg.push(value);
            }
            return agg;
        }, Array<Division<Product>>());
        const installedProductsCount = installedDivision.reduce((agg, item) => {
            agg += item.sections.reduce((_agg, section) => _agg + section.products.length, 0);
            return agg;
        }, 0);
        setState({ ...state, installedDivision: installedDivision });
        setProductsCount({ ...productsCount, installedCount: installedProductsCount });
        return installedDivision;
    };

    const setProductIdsInfo = (productIdsI: Array<InternalProduct>) => {
        console.log('Set productIds on ProjectInfo.setProductIds');
        console.log('ProductIdsI:');
        console.log(productIdsI);
        setState({ ...state, productIdsInfo: productIdsI });
        return;
    };

    const setProductsExternalInfo = (productsExternalI: Array<ExternalProduct>) => {
        console.log('Set productsExternal on ProjectInfo.setProductsExternal');
        console.log('ProductsExternalI:');
        console.log(productsExternalI);
        setState({ ...state, productsExternalInfo: productsExternalI });
        return;
    };

    const setCompaniesExternalInfo = (companiesExternalI: Array<BrandCompany>) => {
        console.log('Set companiesExternal on ProjectInfo.setCompaniesExternal');
        console.log('CompaniesExternalI:');
        console.log(companiesExternalI);
        setState({ ...state, companiesExternal: companiesExternalI });
        return;
    };

    const setFilteredDivisions = (divisions: Array<Division<Product>>) => {
        const copyDivision = JSON.parse(JSON.stringify(divisions)) as Array<Division<Product>>;
        const filteredDivision = copyDivision;
        const filteredProductsCount = filteredDivision.reduce((agg, item) => {
            agg += item.sections.reduce((_agg, section) => _agg + section.products.length, 0);
            return agg;
        }, 0);
        setState({ ...state, filteredDivision: filteredDivision });
        setProductsCount({ ...productsCount, filteredCount: filteredProductsCount });
        return filteredDivision;
    };

    const setFilteredMode = (filterMode: boolean) => {
        setIsFilterMode(filterMode ? true : false);
        return filterMode;
    };

    const setSearch = (text: string) => {
        setSearchText(text);
        return text;
    };

    const setFilteringInProgress = (filterInProgress: boolean) => {
        setIsFilteringInProgress(filterInProgress);
        return filterInProgress;
    };

    const [members, setMembers] = useState<Array<TeamMember>>([])
    const [projectRoles, setProjectRoles] = useState<Array<ProjectRole> | undefined>([])

    const getTeamMembers = async () => {
        if (projectInfo && (!members || members.length === 0)) {
            const { payload } = (await dispatch(
                getTeamAction({
                    projectId: projectInfo?.id,
                }),
            )) as ActionResponse<GetTeamMembersResponse>;
            if (payload.data) {
                setMembers(payload.data.collaborators)
            }
        }
    };

    const getProjectRoles = async () => {
        if (!projectRoles || projectRoles.length === 0) {
            const { payload } = (await dispatch(getProjectRolesAction())) as ActionResponse<
                Array<ProjectRole>
            >;
            if (payload.data) {
                setProjectRoles(payload.data);
            }
        }
    };

    useEffect(() => {
        if (projectInfo) {
            getTeamMembers()
            getProjectRoles()
        }
    }, [projectInfo])

    const tabs = [
        {
            name: 'Project information',
            tab: (
                <ProjectInformation
                    projectInfo={projectInfo}
                    reload={getProjectInfo}
                    setPersistActiveTab={setPersistActiveTab}
                />
            ),
            key: '1',
        },
        {
            name: `Products (${productsCount.totalCount || 0})`,
            tab: (
                <ProductsTab
                    projectInfo={projectInfo}
                    allDivisions={state.divisions}
                    installedDivisions={state.installedDivision}
                    isFilterMode={isFilterMode}
                    isFilteringInProgress={isFilteringInProgress}
                    filteredDivisions={state.filteredDivision}
                    projectReferences={projectReferences}
                    packageId={params.packageId}
                    setDivisions={setDivisions}
                    setInstalledDivisions={setInstalledDivisions}
                    setFilteredMode={setFilteredMode}
                    setFilteringInProgress={setFilteringInProgress}
                    setFilteredDivisions={setFilteredDivisions}
                    setAllDivisions={setAllDivisions}
                    searchText={searchText}
                    setSearch={setSearch}
                    productIdsInfo={state.productIdsInfo}
                    setProductIdsInfo={setProductIdsInfo}
                    productsExternalInfo={state.productsExternalInfo}
                    setProductsExternalInfo={setProductsExternalInfo}
                    companiesExternalInfo={state.companiesExternalInfo}
                    setCompaniesExternalInfo={setCompaniesExternalInfo}
                />
            ),
            key: '2',
        },
        {
            name: `Installed products (${productsCount.installedCount || 0})`,
            tab: (
                <ProductsTab
                    projectInfo={projectInfo}
                    projectReferences={projectReferences}
                    installedTab
                    allDivisions={state.divisions}
                    installedDivisions={state.installedDivision}
                    isFilterMode={isFilterMode}
                    isFilteringInProgress={isFilteringInProgress}
                    filteredDivisions={state.filteredDivision}
                    packageId={params.packageId}
                    setDivisions={setDivisions}
                    setInstalledDivisions={setInstalledDivisions}
                    setFilteredMode={setFilteredMode}
                    setFilteringInProgress={setFilteringInProgress}
                    setFilteredDivisions={setFilteredDivisions}
                    setAllDivisions={setAllDivisions}
                    searchText={searchText}
                    setSearch={setSearch}
                    productIdsInfo={state.productIdsInfo}
                    setProductIdsInfo={setProductIdsInfo}
                    productsExternalInfo={state.productsExternalInfo}
                    setProductsExternalInfo={setProductsExternalInfo}
                    companiesExternalInfo={state.companiesExternalInfo}
                    setCompaniesExternalInfo={setCompaniesExternalInfo}
                />
            ),
            key: '3',
        },
        {
            name: 'Notifications',
            tab: <ProjectNotifications projectInfo={projectInfo} />,
            key: '4',
        },
        {
            name: `EC3`,
            tab: <EC3ExportTab projectInfo={projectInfo} setPersistActiveTab={setPersistActiveTab} getProjectInfo={getProjectInfo} />,
            key: '5',
        },
        {
            name: `Project team (${members.length || 0})`,
            tab: <ProjectTeamTab projectInfo={projectInfo} reload={getTeamMembers} members={members} projectRoles={projectRoles} />,
            key: '6',
        }
    ];

    const trackActiveTab = (tab: string) => {
        setActiveTab(tab);
        window.history.replaceState(null, '', `/project-info/${projectInfo?.id}/0/${tab}`);
    };

    return (
        <>
            <PageHeader
                title={`${projectInfo?.name || ''}`}
                children={
                    <ProjectInfoActions
                        project={projectInfo}
                        projectReferences={projectReferences}
                        activeTab={activeTab}
                        setPersistActiveTab={setPersistActiveTab}
                    />
                }
                titleChildren={
                    <ProjectStatusMark
                        project={projectInfo}
                        projectReferences={projectReferences}
                    />
                }
            />
            <ProjectInfoTabContainer>
                <CustomTabs
                    tabs={tabs}
                    defaultKey={params?.tab || '1'}
                    onTabChange={(tab) => {
                        setActiveTab(tab);
                        trackActiveTab(tab);
                        clickProductsTabs(tab);
                    }}
                    activeKey={persistActiveTab}
                />
            </ProjectInfoTabContainer>
        </>
    );
};

export default ProjectInfo;

const ProjectStatusMark: React.FC<{
    project: GetProjectInformationResponse | null;
    projectReferences: ProjectReferences | null;
}> = ({ project, projectReferences }) => {
    const getStatus = () => {
        if (project?.isTemplate) {
            return project.isFromManufacturer
                ? ProjectStatus.ManufacturerTemplate
                : ProjectStatus.Template;
        }
        if (project?.isCompleted) {
            return ProjectStatus.Completed;
        }
        return ProjectStatus.Active;
    };
    const status = getStatus();
    return (
        <div>
            {project?.isConfidential && !project.isTemplate && (
                <ConfidentialIcon
                    src="src/assets/images/lock-keyhole-white.svg"
                    width={11}
                    height={12}
                    preview={false}
                />
            )}
            {projectReferences && (
                <ProjectStatusContainer>
                    {project && status === ProjectStatus.Active && <span className="blink" />}
                    {`Status: ${projectReferences?.allProjectStatuses.find(
                        (cp) => cp.id.toString() === project?.projectStatusId?.toString(),
                    )?.name
                        }`}
                </ProjectStatusContainer>
            )}
        </div>
    );
};
