import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'antd';
import { alertService } from '../../services';
import { AlertContainer } from './Alert.styles';

interface Props {
    id?: string;
    fade?: boolean;
}

const AlertDialog: React.FC<Props> = ({ id = 'default-alert', fade = true }) => {
    const history = useHistory();
    const [alerts, setAlerts] = useState(Array<any>());

    useEffect(() => {
        const subscription = alertService.onAlert(id).subscribe((alert) => {
            if (!alert.message) {
                setAlerts((currentAlerts) => {
                    const filteredAlerts = currentAlerts.filter((x) => x.keepAfterRouteChange);

                    filteredAlerts.forEach((x) => delete x.keepAfterRouteChange);
                    return filteredAlerts;
                });
            } else {
                setAlerts((currentAlerts) => [...currentAlerts, alert]);

                if (alert.autoClose) {
                    setTimeout(() => removeAlert(alert), 5000);
                }
            }
        });

        const historyUnlisten = history.listen(({ pathname }) => {
            if (pathname.endsWith('/')) {
                return;
            }

            alertService.clear(id);
        });

        return () => {
            subscription.unsubscribe();
            historyUnlisten();
        };
    }, []);

    const removeAlert = (alert: any) => {
        if (fade) {
            const alertWithFade = { ...alert, fade: true };
            setAlerts((currentAlerts) => currentAlerts.map((x) => (x === alert ? alertWithFade : x)));

            setTimeout(() => {
                setAlerts((currentAlerts) => currentAlerts.filter((x) => x !== alertWithFade));
            }, 250);
        } else {
            setAlerts((currentAllerts) => currentAllerts.filter((x) => x !== alert));
        }
    };

    if (!alerts.length) {
        return null;
    }

    return (
        <AlertContainer>
            {alerts.map((alert, index) => (
                <Alert
                    key={index}
                    message={alert.message}
                    type={alert.type}
                    closable
                    className={alert.fixed ? 'fixed' : ''}
                />
            ))}
        </AlertContainer>
    );
};

export default AlertDialog;
