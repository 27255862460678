import React from 'react';
import { Rollover } from './EC3TipListing.styles';
import * as MaterialProgramTip from '../materialPrograms/MaterialProgramTip';
import { TooltipStyles } from '../Tooltip.styles';

const EC3TipListing: React.FC<{ productsTab?: boolean }> = ({ productsTab }) => {
    return (
        <MaterialProgramTip.Container>
            <TooltipStyles className="shadow">
                <div className="tooltip tooltip-ec3listing">
    <h1>Embodied Carbon in Construction Calculator (EC3)</h1>
    <h2>Material Category Stages</h2>
    <p>EPDs in EC3 are grouped by material category. EC3 collaborates with manufacturers to develop functional / performance search parameters to facilitate appropriate product comparisons.</p>
    <p><br/>With hundreds of categories currently in EC3 and growing, categories are in various stages of development. To provide the most accurate comparative results in the Transparency Catalog, information about the category's stage in EC3 is displayed.<br/><br/></p>
    <ul style={{marginLeft: -30 + `px`}}>
        <li><strong>Public</strong> – Comparative quintile result is displayed</li>
        <li><strong>Public, high uncertainty</strong> – Too much uncertainty to be useful, result not displayed</li>
        <li><strong>Public, no comparisons</strong> – Comparison cannot be calculated due to comparability issues with EPDs in the category</li>
        <li><strong>Pilot</strong> – There are published EPDs, development in progress, results not yet public</li>
        <li><strong>Draft</strong> – Insufficient category data, development not yet started</li>
        <li><strong>No category</strong> – No category exists for these products yet</li>
    </ul>
    <p><a href='mailto:mikaela.derousseau@buildingtransparency.org'>Contact Building Transparency</a> to sponsor development or improve a category for Public status in EC3.</p>
    <p><br/><a href='https://www.buildingtransparency.org/tools/ec3/' target='_blank'>Visit EC3 to learn more.</a></p>
                </div>
            </TooltipStyles>
        </MaterialProgramTip.Container>
    );
};

export default EC3TipListing;
