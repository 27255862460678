import React from 'react';
import { Dropdown, Space } from 'antd';
import { usePermissions } from '../../../hooks/permissions';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { FavoriteProductAction } from '../../../models/productFavorite/enums';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { FavoriteType } from '../../../models/product/enums';
import { AccountPermissionTypes } from '../../../models/permission/enums';
import { MergedFavoriteProduct } from '../../favoritProducts/FavoriteProducts.types';
import { useModalState } from '../../../hooks/modalState';
import { AddProductButton, AddProductButtonContainer, AddProductMenu } from '../productsAction/ProductsAction.styles';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import DeleteModal from '../deleteModal/DeleteModal';
import { removeFavoriteInBulkAction } from '../../../redux/actions/favoriteProductsActions';
import { useAppDispatch } from '../../../hooks/redux';

interface Props {
    favoriteType: FavoriteType;
    selectedProducts: Array<MergedFavoriteProduct>;
    addProductsToProject: () => void;
    addProductsToFavorite: () => void;
    removeFavorites: () => void;
}

const FavoriteProductsAction: React.FC<Props> = ({
    favoriteType,
    selectedProducts,
    addProductsToProject,
    addProductsToFavorite,
    removeFavorites,
}) => {
    const dispatch = useAppDispatch();
    const actionDropdown = useModalState();
    const deleteModal = useModalState();

    const { getAccountSubscription, getAccountRole } = usePermissions();

    const getActionMenu = () => {
        const actionMenu: Array<ItemType & { "data-action"?: string }> = [
            {
                key: FavoriteProductAction.Add,
                label: 'Add selections to Project',
                disabled: !selectedProducts.length,
                "data-action": "add-product-to-project"
            },
        ];
        if (
            (getAccountSubscription() === SubscriptionTypes.OFFICE ||
                getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE) &&
            favoriteType === FavoriteType.OfficeFavorite
        ) {
            actionMenu.push({
                key: FavoriteProductAction.Favorite,
                label: `Copy to my Library`,
                disabled: !selectedProducts.length,
            });
        }
        if (
            getAccountRole() === AccountPermissionTypes.ACCOUNT_ADMIN ||
            favoriteType === FavoriteType.MyFavorite
        ) {
            actionMenu.push({
                key: FavoriteProductAction.Remove,
                label: `Delete selections`,
                disabled: !selectedProducts.length,
                "data-action": "delete-product-from-favorites"
            });
        }
        return actionMenu;
    };

    const handleMenuSelect = (data: { key: string }) => {
        switch (data.key) {
            case FavoriteProductAction.Favorite:
                addProductsToFavorite();
                break;
            case FavoriteProductAction.Add:
                addProductsToProject();
                break;
            case FavoriteProductAction.Remove:
                if (selectedProducts.length) {
                    console.log('deleteModal');
                    deleteModal.onOpen();
                }
                break;
            default:
                break;
        }
    };

    const removeFromFavorites = async () => {
        const { payload } = await dispatch(
            removeFavoriteInBulkAction({
                productFavoriteIds: selectedProducts.map((product) => product.id),
                favoriteType: favoriteType,
            }),
        );
        if (payload) {
            removeFavorites();
            deleteModal.onClose();
        }
    };

    return (
        <>
            <Space>
                <Dropdown
                    menu={{
                        items: getActionMenu(),
                        onClick: handleMenuSelect,
                        style: { top: -36, left: 34 },
                    }}
                    placement="bottom"
                    trigger={['click']}
                    onOpenChange={actionDropdown.onToggle}
                >
                    <AddProductButtonContainer>
                        <AddProductButton id="add-products-button">Actions</AddProductButton>
                        <AddProductMenu id="add-products-menu">
                            {actionDropdown.isOpen ? (
                                <CaretUpOutlined style={{ color: 'white' }} />
                            ) : (
                                <CaretDownOutlined style={{ color: 'white' }} />
                            )}
                        </AddProductMenu>
                    </AddProductButtonContainer>
                </Dropdown>
            </Space>
            <DeleteModal
                isOpen={deleteModal.isOpen}
                closeModal={deleteModal.onClose}
                reload={removeFromFavorites}
                icon=""
                title="product"
                text={`${selectedProducts.length} product${selectedProducts.length > 1 ? 's' : ''}`}
            />
        </>
    );
};

export default FavoriteProductsAction;