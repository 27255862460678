import React, { useState } from 'react';
import { Form } from 'antd';
import { CSVLink } from 'react-csv';
import ModalTitle from '../../../../components/common/modalTitle/ModalTitle';
import {
    ProjectTableFields,
} from '../../../../models/users/users';
import { Text, CloseIcon } from '../../../../styles/common.styles';
import { blueDark01 } from '../../../../styles/colors';
import { ExportUsersToCsvModal, ExportSpin } from './ExportUsersToCsv.styles';
import ModalFooter from '../../../../components/common/modalFooter/ModalFooter';
import { SelectData } from '../../../../models/common/types';
import { useAppDispatch } from '../../../../hooks/redux';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { CSVHeader } from './ExportUsersToCsv.mockData';
import { alertService } from '../../../../services';
import { getUserReferencesAction } from '../../../../redux/actions/userActions';
import { getSubscriptionAction } from '../../../../redux/actions/subscriptionActions';
import { SubscriptionPlan } from '../../../redux/actions/userActions/types';
import { getSMAdminUsersAction } from '../../../../redux/actions/smAdminActions';
import { extractFields } from '../../../../helpers/extract';
import {
    TableSMAdminUserData,
    SMAdminUserTableFields,
    SMAdminTableFields,
} from '../../../models/user/smAdminUser';
import { toShortLocalDate, toShortLocalTime } from '../../../../helpers/dateHelper';

type MyUnionType = ProjectTableFields | GetProjectInformationResponse | MFRProject | null;

interface Props {
    isOpen: boolean;
    users: MyUnionType;
    userIds: Array<any>;
    options: Array<SelectData>;
    wrapLinesInDropdown?: boolean;
    // reload: (modal: string) => void;
    closeModal: (data: { key: 'csv' }) => void;
}

interface State {
    exportData: Array<any>;
    fileName: string;
    pending: boolean;
}

const ExportUsersToCsv: React.FC<Props> = ({
    isOpen,
    projectReferences,
    options,
    users,
    userIds,
    wrapLinesInDropdown,
    closeModal,
}) => {

    const dispatch = useAppDispatch();

    const [tableData] = useState<TableData>({
        curPage: 1,
        pageSize: 10000,
        searchString: '',
        sortDirection: 'asc',
        sortExpression: null,
    });


    const [state, setState] = useState<State>({
        exportData: Array<any>(),
        fileName: 'export users',
        pending: false,
    });

    const handleCancel = () => {
        closeModal({ key: 'csv' });
    };

    const onSubmit = async (data: { exportType: string }) => {
        setState({ ...state, pending: true });
        getData();
    };


    const getData = async () => {
        if (users != null) {
            await getUsers(tableData, users);
        } else {
            await getUsers(tableData);
        }
    };

    const getSubscriptions = async () => {
        const { payload } = (await dispatch(getSubscriptionAction())) as ActionResponse<
            Array<SubscriptionPlan>
        >;
        if (payload.data) {
            return new Map<number, string>(
                payload.data.map((item: SelectData) => {
                    return [item.id, item.name];
                }),
            );
        } else {
            alertService.error('Sorry, plans is not available now');
            return undefined;
        }
    };

    const getDataForSelect = async () => {
        const { payload } = (await dispatch(getUserReferencesAction())) as any;
        if (payload.data) {
            const availableRoles = new Map<number, string>(
                payload.data.allAccountRoles.map((item: SelectData) => {
                    return [item.id, item.name];
                }),
            );
            const availableTitleRoles = new Map<number, string>(
                payload.data.allTitleRoles.map((item: SelectData) => {
                    return [item.id, item.name];
                }),
            );
            const availableOrganizationSizes = new Map<number, string>(
                payload.data.allOrganizationSizes.map((item: SelectData) => {
                    return [item.id, item.name];
                }),
            );
            return {availableRoles: availableRoles, availableTitleRoles: availableTitleRoles, availableOrganizationSizes: availableOrganizationSizes};
        }
        return undefined;
    };

    const getUsers = async (
        currentTableData: TableData,
        selectedUsers: Array<any>,
        newUsers?: Array<SMAdminUserTableFields | SMAdminTableFields>,
    ) => {
        let currentUsers = [];
        const subscriptionPlans = await getSubscriptions();
        const references = await getDataForSelect();
        if (selectedUsers === null) {
            const additionalUsers = Array.isArray(newUsers) ? newUsers : [];
            const { payload } =
                ((await dispatch(getSMAdminUsersAction(currentTableData))) as any);
            const result = extractFields<SMAdminUserTableFields | SMAdminTableFields>(
                Object.keys(new TableSMAdminUserData()),
                payload.data.items.concat(additionalUsers),
            );
            currentUsers = [
                ...new Map(
                    result.map((item: SMAdminUserTableFields | SMAdminTableFields) => [item.key, item]),
                ).values(),
            ] as Array<SMAdminUserTableFields | SMAdminTableFields>;
        } else {
            selectedUsers.forEach((item: SMAdminUserTableFields) => {
                const cloneUser = { ...item };
                currentUsers.push(cloneUser);
            });
        }
        currentUsers.forEach((item: SMAdminUserTableFields) => {
            item.roleName = references.availableRoles.get(item.roleId).replace('Account', '');
            item.titleRoleName = references.availableTitleRoles.get(item.titleRoleId);
            item.organizationSizeShortName = references.availableOrganizationSizes.get(item.organizationSizeId);
            item.subscriptionPlanName = subscriptionPlans.get(item.subscriptionPlanId);
            item.createdOn = toShortLocalDate(item.createdOn);
            item.lastLoginTime = toShortLocalDate(item.lastLoginTime) + ' ' + toShortLocalTime(item.lastLoginTime)
        });
        setTimeout(() => {
            const fileNameCalc = 'All Users.csv';
            setState({
                ...state,
                fileName: fileNameCalc || state.fileName,
                exportData: currentUsers,
            });
            const link = document.getElementById('csv-link') as any;
                link.click();
        }, 500);
    };

    return (
        <ExportUsersToCsvModal
            open={isOpen}
            onCancel={handleCancel}
            closeIcon={<CloseIcon />}
            centered
            footer={null}
        >
            <ExportSpin spinning={state.pending}>
                <ModalTitle title="Export to CSV" icon="ic-modal-title-export.svg" />
                <div>
                    <Text weight={400} size={15} height={20} color={blueDark01}>
                        Are you sure you want to export {users!= null ? users.length : ''} user(s) to CSV?
                    </Text>
                    <CSVLink
                        id="csv-link"
                        filename={state.fileName}
                        data={state.exportData}
                        headers={CSVHeader}
                    >
                        Download me
                    </CSVLink>
                </div>
                <Form onFinish={onSubmit} style={{ marginTop: '23' + `px` }}>
                    <ModalFooter action="Export" cancel={handleCancel} submit={() => {}} />
                </Form>
            </ExportSpin>
        </ExportUsersToCsvModal>
    );
};

export default ExportUsersToCsv;
