import React, { useState, useEffect } from 'react';
import { Form, Col, Checkbox } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useModalState } from '../../../../hooks/modalState';

import ModalTitle from '../../../../components/common/modalTitle/ModalTitle';
import { BoldText, CloseIcon, Text } from '../../../../styles/common.styles';
import {
    CreateAccountModal,
    CreateAccountRow,
    CreateAccountSpin,
    PhoneInputContainer,
    ModalInput,
    CheckboxInputContainer,
    CheckboxContainer,
    SectionLabel,
} from './SMAdminCreateOneAccountModal.styles';
import { blue01, blueDark01 } from '../../../../styles/colors';
import CustomInput from '../../../../components/common/inputs/CustomInput';
import CustomSelect from '../../../../components/common/select/CustomSelect';
import ModalFooter from '../../../../components/common/modalFooter/ModalFooter';
import ErrorModal from '../../../../components/common/errorModal/ErrorModal';
import {
    CreateSMAdminUserRequest,
    GetSMAdminReferencesResponse,
} from '../../../../redux/actions/smAdminActions/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SMAdminUserTableFields } from '../../../models/user/smAdminUser';
import { SelectData } from '../../../../models/common/types';
import {
    getCustomPricingAction,
    createSMAdminUserAction,
    getSMAdminReferencesAction,
} from '../../../../redux/actions/smAdminActions';
import { SMCustomPrice } from '../../../../redux/actions/smAdminActions/types';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { SubscriptionPlan } from '../../../../redux/actions/subscriptionActions/types';
import { createSalesforceLead } from '../../../../helpers/analytics';
import { formatSubscription } from '../../../../helpers/textFormatter';
import { SubscriptionTypes } from '../../../../models/subscription/enums';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'one' }) => void;
    reload: (modal: 'one', user: Array<SMAdminUserTableFields>) => void;
}

const SMAdminCreateOneAccountModal: React.FC<Props> = ({ isOpen, closeModal, reload }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);

    const [form] = Form.useForm();
    const [isManufacturerCheckboxState, setIsManufacturerCheckboxState] = useState(false);
    const [references, setReferences] = useState({
        allOrganizationSizes: Array<SelectData>(),
        subscriptionPlansForCreateUsers: Array<SubscriptionPlan>(),
    });
    const [customPrices, setCustomPrices] = useState(new Array<SelectData>());
    const [subscriptionType, setSubscriptionType] = useState('Office');
    const errorModal = useModalState();

    useEffect(() => {
        getReferences();
        getCustomPricing();
    }, []);

    const getReferences = async () => {
        const { payload } = (await dispatch(
            getSMAdminReferencesAction(),
        )) as ActionResponse<GetSMAdminReferencesResponse>;
        if (payload.data) {
            setReferences({
                allOrganizationSizes: payload.data.allOrganizationSizes || [],
                subscriptionPlansForCreateUsers: payload.data.subscriptionPlansForCreateUsers || [],
            });
        }
    };

    const getCustomPricing = async () => {
        const { payload } = (await dispatch(getCustomPricingAction())) as ActionResponse<
            Array<SMCustomPrice>
        >;
        if (payload.data) {
            setCustomPrices(
                payload.data.map((item) => ({
                    id: item.id,
                    name: item.nickname ? item.nickname : item.id,
                })),
            );
        }
    };

    const handleCancel = () => {
        closeModal({ key: 'one' });
    };

    const onSubmit = async (data: CreateSMAdminUserRequest) => {
        // Maybe needed in the future
        // const isTrialOffice =
        //    references.subscriptionPlansForCreateUsers
        //        .find((sp) => sp.name.toString() === SubscriptionTypes.FREE_TRIAL_OFFICE)
        //        ?.id.toString() === data.subscriptionPlanId.toString();
        const { payload } = (await dispatch(
            createSMAdminUserAction({
                ...data,
                userName: data.email,
                isManufacturer: isManufacturerCheckboxState,
                numOfUsers: parseInt(data.numOfUsers as string) || undefined,
                numOfAssociateUsers: parseInt(data.numOfAssociateUsers as string) || undefined,
                subscriptionPlanId: references.subscriptionPlansForCreateUsers
                    .find(
                        (subscription) =>
                            subscription.name.toLowerCase() === subscriptionType.toLowerCase(),
                    )
                    ?.id.toString()!,
            }),
        )) as any;
        if (payload?.data) {
            createSalesforceLead({
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                organizationName: data.organizationName,
            });
            reload('one', payload.data);
        }
    };

    const handleIsManufacturerCheckbox = (event: CheckboxChangeEvent) => {
        setIsManufacturerCheckboxState(event.target.checked);
    };

    const handleSubscriptionTypeSelect = (value: string) => {
        setSubscriptionType(
            references.subscriptionPlansForCreateUsers.find(
                (subscription) => subscription.id === parseInt(value),
            )?.name!,
        );
    };

    return (
        <CreateAccountModal
            open={isOpen}
            onCancel={handleCancel}
            footer={null}
            centered
            closeIcon={<CloseIcon />}
        >
            <CreateAccountSpin size="large" spinning={selector.userReducer.pending}>
                <ModalTitle
                    title="Create subscriber"
                    icon="ic-modal-title-create-accounts-bulk.svg"
                />
                <Form onFinish={onSubmit} form={form}>
                    <SectionLabel
                        weight={500}
                        size={13}
                        height={16}
                        color={blue01}
                        font={'DINNextLTPro'}
                    >
                        BASIC INFORMATION
                    </SectionLabel>
                    <CreateAccountRow>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="First name"
                                name="firstName"
                                required
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                        </Col>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Last name"
                                name="lastName"
                                required
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                        </Col>
                    </CreateAccountRow>
                    <CreateAccountRow>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Title/role"
                                name="titleRoleID"
                                required
                                rules={[{ required: true, message: '' }]}
                                block
                            />
                        </Col>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Email"
                                name="email"
                                required
                                rules={[
                                    { required: true, message: '' },
                                    {
                                        pattern: /^[\w-\w+\.]+@([\w-]+\.)+[\w-]{2,10}$/,
                                        message: 'Invalid email',
                                    },
                                ]}
                                block
                            />
                        </Col>
                    </CreateAccountRow>
                    <CreateAccountRow>
                        <Col>
                            <PhoneInputContainer>
                                <CustomInput
                                    Component={ModalInput}
                                    label="Phone"
                                    name="phoneNumber"
                                    rules={[
                                        {
                                            pattern:
                                                /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                                            message: 'Invalid phone',
                                        },
                                    ]}
                                    block
                                />

                                <CustomInput
                                    Component={ModalInput}
                                    label="Ext."
                                    name="phoneNumberExt"
                                    block
                                    rules={[
                                        {
                                            pattern: /^(\+?\d{1,3}|\d{1,4})$/,
                                            message: 'Invalid phone extension',
                                        },
                                    ]}
                                />
                            </PhoneInputContainer>
                        </Col>
                        <Col>
                            <CheckboxInputContainer>
                                <BoldText
                                    weight={400}
                                    size={13}
                                    height={16}
                                    width={100}
                                    color={blueDark01}
                                >
                                    Publisher
                                </BoldText>
                                <CheckboxContainer>
                                    <Checkbox
                                        checked={isManufacturerCheckboxState}
                                        name="isManufacturer"
                                        onChange={handleIsManufacturerCheckbox}
                                    />
                                    <Text
                                        weight={400}
                                        size={14}
                                        height={18}
                                        color={blueDark01}
                                        font={'Proxima Nova'}
                                        width={252}
                                    >
                                        User may upload/manage manufacturer templates.
                                    </Text>
                                </CheckboxContainer>
                            </CheckboxInputContainer>
                        </Col>
                    </CreateAccountRow>
                    <SectionLabel
                        weight={500}
                        size={13}
                        height={16}
                        color={blue01}
                        font={'DINNextLTPro'}
                        marginTop={35}
                    >
                        ABOUT YOUR ORGANIZATION
                    </SectionLabel>
                    <CreateAccountRow>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Organization name"
                                name="organizationName"
                                rules={[{ required: true, message: '' }]}
                                required
                                block
                            />
                        </Col>
                        <Col>
                            <CustomSelect
                                label="Organization size"
                                name="organizationSizeId"
                                options={references.allOrganizationSizes}
                                placeholder="Select organization size"
                                rules={[{ required: true, message: '' }]}
                                block
                                required
                            />
                        </Col>
                    </CreateAccountRow>
                    <CreateAccountRow>
                        <Col>
                            <CustomInput
                                Component={ModalInput}
                                label="Office"
                                name="office"
                                block
                            />
                        </Col>
                    </CreateAccountRow>
                    <SectionLabel
                        weight={500}
                        size={13}
                        height={16}
                        color={blue01}
                        font={'DINNextLTPro'}
                        marginTop={40}
                    >
                        SUBSCRIPTION
                    </SectionLabel>
                    <CreateAccountRow>
                        <Col>
                            <CustomSelect
                                label="Subscription type"
                                name="subscriptionPlanId"
                                options={references.subscriptionPlansForCreateUsers
                                    .map(
                                        (subscription): SelectData => ({
                                            id: subscription.id.toString(),
                                            name: subscription.name,
                                        }),
                                    )
                                    .map((s) => ({ ...s, name: formatSubscription(s.name) }))}
                                placeholder="Select subscription type"
                                rules={[{ required: true, message: '' }]}
                                props={{
                                    onChange: handleSubscriptionTypeSelect,
                                }}
                                block
                                required
                            />
                        </Col>
                        <Col>
                            {subscriptionType.includes('Office') && (
                                <CustomSelect
                                    label="Pricing model"
                                    name="pricingId"
                                    options={customPrices}
                                    placeholder="Select pricing model"
                                    rules={[{ required: true, message: '' }]}
                                    required
                                    block
                                />
                            )}
                        </Col>
                    </CreateAccountRow>
                    {subscriptionType === 'Office' && (
                        <CreateAccountRow>
                            <Col>
                                <CustomInput
                                    Component={ModalInput}
                                    label="Number of Admin/Member accounts"
                                    name="numOfUsers"
                                    required
                                    block
                                    rules={[{ required: true, message: '' }]}
                                    props={{ type: 'number', min: 2 }}
                                />
                            </Col>
                            <Col>
                                <CustomInput
                                    Component={ModalInput}
                                    label="Number of Associate accounts"
                                    name="numOfAssociateUsers"
                                    required
                                    block
                                    rules={[{ required: true, message: '' }]}
                                    props={{ type: 'number', min: 0 }}
                                />
                            </Col>
                        </CreateAccountRow>
                    )}
                    <ModalFooter
                        action="Create subscriber"
                        cancel={handleCancel}
                        submit={() => {}}
                        actionButtonWidth={118}
                        cancelButtonWidth={69}
                    />
                </Form>
            </CreateAccountSpin>
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </CreateAccountModal>
    );
};

export default SMAdminCreateOneAccountModal;
