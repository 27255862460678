import React, { useState } from 'react';
import { Checkbox, Image, Input, Radio, RadioChangeEvent, Select, Tooltip } from 'antd';
import { blueDark01, blueLink, grayDark05 } from '../../../../styles/colors';
import {
    ActionSection,
    InvitePeopleLink,
    MFRDateLabel,
    MFRDateValue,
    MFRFavoriteContainer,
    MFRFavoriteItem,
    MFRPricingInputContainer,
    MFRProductDateSection,
    MFRProductInfoContainer,
    MFRProductInfoSection,
    MFRProductItemLabel,
    MFRProductRadioButtonContainer,
    PricingError,
    FavoriteButton,
    FlexibleActionSection,
    MFRPricingContainer,
    MFRProductPricingInfoSection,
} from './MFRProductInfo.styles';
import { ProductReferences } from '../../../../redux/actions/productActions/types';
import { FavoriteProduct, Product } from '../../../../models/product/types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ProjectPermissionTypes } from '../../../../models/permission/enums';
import {
    changeBasisOfDesignAction,
    changePricingTypeAction,
    changeSubstitutionAction,
    switchProductInstalledAction,
    updateDescriptionAction,
    updatePricingAction,
} from '../../../../redux/actions/productActions';
import { alertService } from '../../../../services';
import { toShortLocalDate } from '../../../../helpers/dateHelper';
import {
    addToFavoriteAction,
    removeFromFavoriteAction,
} from '../../../../redux/actions/favoriteProductsActions';
import { FavoriteType } from '../../../../models/product/enums';
import { ActionResponse, CommonResponse } from '../../../../models/actionResponse/types';
import { Text } from '../../../../styles/common.styles';
import { usePermissions } from '../../../../hooks/permissions';
import { SubscriptionTypes } from '../../../../models/subscription/enums';
import { AccountPermissionTypes } from '../../../../models/permission/enums';
import ActiveOfficeFavoriteIcon from '../../../../components/common/activeOfficeFavoriteIcon/ActiveOfficeFavoriteIcon';
import { useModalState } from '../../../../hooks/modalState';
import OfficePromptModal from '../../../../components/common/officeFavoritePromptModal/OfficePromptModal';
import MyPromptModal from '../../../../components/common/myFavoritePromptModal/MyPromptModal';
import ShareProduct from './Components/ShareProduct';
import { MFRProject } from '../../../../models/project/types';
import { DescriptionInputContainerMFR, ProductItemLabelMFR } from '../../../projectInfo/tabs/productsTab/productItem/ProductItem.styles';
import { SelectData } from '../../../../models/common/types';

const { Option } = Select;

interface Props {
    references: ProductReferences | null;
    product: Product;
    editable: boolean;
    project: MFRProject;
    projectRoles: Array<SelectData>;
}

const MFRProductInfo: React.FC<Props> = ({ references, product, editable, project, projectRoles, collaborators, getCollaborators }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);
    const { productReducer } = selector;

    const { getAccountSubscription } = usePermissions();
    const promptModal = useModalState();
    const myPromptModal = useModalState();
    const { getAccountRole, getProjectPermission } = usePermissions();

    const [state, setState] = useState({ current: product });
    const [invitePeopleModalOpenState, setInvitePeopleModalOpenState] = useState(false)
    const [errors, setErrors] = useState({
        pricing: false,
    });

    const handleMyFavorite = async () => {
        if (productReducer.pending) {
            return;
        }
        const currentProduct = state.current;
        if (!state.current.isMyFavorite) {
            const { payload } = (await dispatch(
                addToFavoriteAction({
                    productId: product.productId,
                    projectId: product.projectId,
                    favoriteType: FavoriteType.MyFavorite,
                }),
            )) as ActionResponse<FavoriteProduct>;
            if (payload.data) {
                currentProduct.isMyFavorite = true;
                currentProduct.myFavoriteId = payload.data.id;
                myPromptModal.onToggle();
            }
        } else {
            if (state.current.myFavoriteId) {
                const { payload } = (await dispatch(
                    removeFromFavoriteAction({
                        favoriteType: FavoriteType.MyFavorite,
                        productFavoriteId: state.current.myFavoriteId,
                    }),
                )) as CommonResponse;
                if (payload.message) {
                    currentProduct.isMyFavorite = false;
                }
            }
        }
        setState({ ...state, current: currentProduct });
    };

    const handleOfficeFavorite = async () => {
        if (productReducer.pending) {
            return;
        }
        const currentProduct = state.current;
        if (!state.current.isOfficeFavorite) {
            const { payload } = (await dispatch(
                addToFavoriteAction({
                    productId: product.productId,
                    projectId: product.projectId,
                    favoriteType: FavoriteType.OfficeFavorite,
                }),
            )) as ActionResponse<FavoriteProduct>;
            if (payload.data) {
                currentProduct.isOfficeFavorite = true;
                currentProduct.officeFavoriteId = payload.data.id;
                promptModal.onToggle();
            }
        } else {
            if (product.officeFavoriteId) {
                const { payload } = (await dispatch(
                    removeFromFavoriteAction({
                        favoriteType: FavoriteType.OfficeFavorite,
                        productFavoriteId: product.officeFavoriteId,
                    }),
                )) as CommonResponse;
                if (payload.message) {
                    currentProduct.isOfficeFavorite = false;
                }
            }
        }
        setState({ ...state, current: currentProduct });
    };

    const handleDescription = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentProduct = state.current;
        currentProduct.description = event.target.value;
        setState({ ...state, current: currentProduct });
    };

    const handleDescriptionBlur = (event: any) => {
        event.target.scrollLeft = 0;
        saveDescription(event);
    }

    const saveDescription = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await dispatch(
            updateDescriptionAction({
                description: e.target.value || '',
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
    };

    const handleBasisOfDesign = async (basisOfDesignId: number) => {
        const currentProduct = state.current;
        currentProduct.basisOfDesignId = basisOfDesignId;
        setState({ ...state, current: currentProduct });
        await dispatch(
            changeBasisOfDesignAction({
                basisOfDesignId,
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
    };

    const handleSubstitution = async (substitutionTypeId: number) => {
        const currentProduct = state.current;
        currentProduct.substitutionTypeId = substitutionTypeId;
        setState({ ...state, current: currentProduct });
        await dispatch(
            changeSubstitutionAction({
                substitutionsId: substitutionTypeId,
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
    };

    const handlePricing = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const pricing = event.target.value;
        if (pricing.length > 75) {
            setErrors({ ...errors, pricing: true });
            setTimeout(() => {
                setErrors({ ...errors, pricing: false });
            }, 5000);
            return;
        }
        const currentProduct = state.current;
        currentProduct.pricing = pricing;
        setState({ ...state, current: currentProduct });
    };

    const savePricing = async () => {
        const currentProduct = state.current;
        await dispatch(
            updatePricingAction({
                pricing: currentProduct.pricing || '',
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
    };

    const handlePricingType = async (event: RadioChangeEvent) => {
        const pricingTypeId = event.target.value || ' ';
        if (pricingTypeId) {
            const currentProduct = state.current;
            currentProduct.pricingTypeId = pricingTypeId;
            setState({ ...state, current: currentProduct });
            await dispatch(
                changePricingTypeAction({
                    pricingTypeId,
                    productId: product.productId,
                    projectId: product.projectId,
                    projectProductId: product.projectProductId,
                }),
            );
        } else {
            alertService.error('Something went wrong');
        }
    };

    const handleInstalledProduct = async () => {
        const currentProduct = state.current;
        currentProduct.isInstalled = !currentProduct.isInstalled;
        setState({ ...state, current: currentProduct });
        await dispatch(
            switchProductInstalledAction({
                productId: product.productId,
                projectId: product.projectId,
                projectProductId: product.projectProductId,
            }),
        );
    };

    const handleInvitePeopleModalOpen = () => { setInvitePeopleModalOpenState(true) }
    const handleInvitePeopleModalClose = () => { setInvitePeopleModalOpenState(false) }

    const notAllowedClassName = 'not-allowed';

    return (
        <MFRProductInfoContainer>
            <ShareProduct isOpen={invitePeopleModalOpenState} closeModal={handleInvitePeopleModalClose} project={project} productId={product.productId} 
                projectRoles={projectRoles}
            />
            {getProjectPermission(
                        [ProjectPermissionTypes.OWNER, ProjectPermissionTypes.PROJECT_ADMIN],
                        project.userRole,
                    ) &&
               <InvitePeopleLink onClick={handleInvitePeopleModalOpen} color={blueLink} weight={600} height={24} size={12}><Image src={`src/assets/images/share-from-square-link.svg`} preview={false} width={18} height={16} /> Invite people to this product </InvitePeopleLink>
            }
            <ActionSection>
                <MFRProductInfoSection>
                    <ProductItemLabelMFR weight={400} size={9} height={11} color={grayDark05}>
                        DESCRIPTION
                    </ProductItemLabelMFR>
                    <DescriptionInputContainerMFR>
                        <Tooltip title={product.description}>
                            <Input
                                disabled={!editable}
                                value={product.description}
                                placeholder="Ex: SKU, color, use, etc."
                                onChange={handleDescription}
                                onBlur={handleDescriptionBlur}
                                width={187}
                            />
                        </Tooltip>
                    </DescriptionInputContainerMFR>
                </MFRProductInfoSection>
                <MFRProductInfoSection>
                    <MFRProductItemLabel weight={400} size={9} height={11} color={grayDark05}>
                        BASIS OF DESIGN
                    </MFRProductItemLabel>
                    <Select
                        disabled={!editable}
                        aria-required
                        onChange={handleBasisOfDesign}
                        value={state?.current?.basisOfDesignId}
                        suffixIcon={
                            <Image src="src/assets/images/ic-dropdown-arrow.svg" preview={false} />
                        }
                    >
                        {references?.allBasisOfDesignType
                            ? references.allBasisOfDesignType.map((item) => (
                                <Option value={item.id} key={item.id}>
                                    {item.name}
                                </Option>
                            ))
                            : null}
                    </Select>
                </MFRProductInfoSection>
                <MFRProductInfoSection>
                    <MFRProductItemLabel weight={400} size={9} height={11} color={grayDark05}>
                        SUBSTITUTIONS
                    </MFRProductItemLabel>
                    <Select
                        disabled={!editable}
                        aria-required
                        value={state.current.substitutionTypeId}
                        onChange={handleSubstitution}
                        suffixIcon={
                            <Image src="src/assets/images/ic-dropdown-arrow.svg" preview={false} />
                        }
                    >
                        {references?.allSubstitutionTypes
                            ? references.allSubstitutionTypes.map((item) => (
                                <Option value={item.id} key={item.id}>
                                    {item.name}
                                </Option>
                            ))
                            : null}
                    </Select>
                </MFRProductInfoSection>
                <MFRProductPricingInfoSection>
                    <MFRPricingContainer>
                        <MFRProductItemLabel weight={400} size={9} height={11} color={grayDark05}>
                            PRICING
                        </MFRProductItemLabel>
                        <MFRPricingInputContainer>
                            <Input
                                disabled={!editable}
                                placeholder="ex: $5-9/sq. ft."
                                value={product.pricing}
                                onChange={handlePricing}
                                onBlur={savePricing}
                            />
                            {errors.pricing && <PricingError>max size 75 characters</PricingError>}
                        </MFRPricingInputContainer>
                    </MFRPricingContainer>
                    <MFRProductRadioButtonContainer>
                        <Radio.Group
                            value={state?.current?.pricingTypeId}
                            onChange={handlePricingType}
                            disabled={!editable}
                        >
                            {references?.allPricingType.map((pricingType) => (
                                <Radio value={pricingType.id} key={pricingType.id}>
                                    {pricingType.name}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </MFRProductRadioButtonContainer>
                </MFRProductPricingInfoSection>
            </ActionSection>
            <FlexibleActionSection>
                <MFRProductDateSection>
                    <MFRDateLabel weight={400} size={9} height={11} color={grayDark05}>
                        DATE ADDED
                    </MFRDateLabel>
                    <MFRDateValue weight={400} size={12} height={14} color={blueDark01}>
                        {toShortLocalDate(product.dateAdded)} <span className='normal-weight'>({ product.createdBy })</span>
                    </MFRDateValue>
                    <MFRDateLabel weight={400} size={9} height={11} color={grayDark05}>
                        Last updated: {toShortLocalDate(product.lastUpdated)}
                    </MFRDateLabel>
                </MFRProductDateSection>
                <MFRProductInfoSection>
                    <MFRFavoriteContainer>
                        <MFRFavoriteItem>
                            {state.current.isMyFavorite ? (
                                <Image
                                    src="src/assets/images/star.svg"
                                    preview={false}
                                    width={16}
                                    style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                                    onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleMyFavorite}
                                />
                            ) : (
                                <Image
                                    src="src/assets/images/star-empty.svg"
                                    preview={false}
                                    width={16}
                                    style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                                    onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleMyFavorite}
                                />
                            )}
                            <FavoriteButton onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleMyFavorite} className="favoriteButtonText"
                               style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                            >
                                <Text weight={400} size={12} height={14} color={blueLink}>
                                    My Library
                                </Text>
                            </FavoriteButton>
                        </MFRFavoriteItem>
                        {(getAccountSubscription() === SubscriptionTypes.OFFICE ||
                            getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE) && (
                                <MFRFavoriteItem className="favoriteItem">
                                    {product.isOfficeFavorite ? (
                                        <ActiveOfficeFavoriteIcon
                                            handleOfficeFavorite={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleOfficeFavorite}
                                        />
                                    ) : (
                                        <>
                                            <Image
                                                src="src/assets/images/star-empty.svg"
                                                preview={false}
                                                width={16}
                                                style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                                                onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleOfficeFavorite}
                                            />
                                            <FavoriteButton onClick={getAccountRole() !== AccountPermissionTypes.ACCOUNT_ASSOCIATE && handleOfficeFavorite}
                                                className="favoriteButtonText"
                                                style={{ cursor: getAccountSubscription() === SubscriptionTypes.FREE || getAccountRole() === AccountPermissionTypes.ACCOUNT_ASSOCIATE ? notAllowedClassName : 'pointer' }}
                                                >
                                                <Text weight={400} size={12} height={14} color={blueLink}>
                                                    Office Library
                                                </Text>
                                            </FavoriteButton>
                                        </>
                                    )}
                                </MFRFavoriteItem>
                            )}
                        <MFRFavoriteItem>
                            <Checkbox
                                name={'installed'}
                                checked={product.isInstalled}
                                onChange={handleInstalledProduct}
                                disabled={!editable}
                            />
                            <Text weight={400} size={12} height={14} color={blueLink}>
                                Installed
                            </Text>
                        </MFRFavoriteItem>
                    </MFRFavoriteContainer>
                </MFRProductInfoSection>
            </FlexibleActionSection>
            <MyPromptModal
                isOpen={myPromptModal.isOpen}
                closeModal={myPromptModal.onClose}
                productId={product.myFavoriteId}
            />
            <OfficePromptModal
                isOpen={promptModal.isOpen}
                closeModal={promptModal.onClose}
                productId={product.officeFavoriteId}
            />
        </MFRProductInfoContainer>
    );
};

export default MFRProductInfo;
