import React from "react";
import {
    ImageContainer,
    ImageModalContainer,
    ImageModalWindow,
    ImagesPaginationContainer, 
} from "./ImageModal.styles";
import { UploadedImageData } from "../UploadsInfo";
import { CloseIcon } from "../../../../styles/common.styles";
import CustomPagination from "../../../common/pagination/CustomPagination";
import ImageModalForm from "./ImageModalForm";
import ImageModalReadOnlyForm from "./ImageModalReadOnlyForm";
import { downloadFile } from "../uploadTable/UploadTable";
import { FavoriteType } from "../../../../models/product/enums";
import { Product } from "../../../../models/product/types";
import { MergedFavoriteProduct } from "../../../favoritProducts/FavoriteProducts.types";
import { UploadFileData } from "../Uploads.types";

interface Props {
    image?: UploadedImageData;
    pageNumber: number;
    favoriteType?: FavoriteType;
    product: MergedFavoriteProduct | Product;
    imagesTotalCount: number;
    isImageModalOpen: boolean;
    isReadOnly: boolean;
    closeImageModal: () => void;
    openDeleteModal: () => void;
    updateImage: (data: UploadFileData) => void;
    handleCurrentPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changePageNumber: (pageNumber: number) => void;
};

const ImageModal: React.FC<Props> = ({
    image,
    pageNumber,
    favoriteType,
    product,
    imagesTotalCount,
    isImageModalOpen,
    isReadOnly,
    closeImageModal,
    openDeleteModal,
    updateImage,
    handleCurrentPage,
    changePageNumber,
}) => {
    if (!image) {
        return <></>
    }

    const onDownloadButtonClicked = () => {
        downloadFile(image.file);
    }

    const getForm = () => {
        return isReadOnly ? (
            <ImageModalReadOnlyForm
                image={image}
                onDownloadButtonClicked={onDownloadButtonClicked}
            />
        ) : (
            <ImageModalForm
                image={image}
                favoriteType={favoriteType}
                product={product}
                onDownloadButtonClicked={onDownloadButtonClicked}
                onDeleteButtonClicked={openDeleteModal}
                closeImageModal={closeImageModal}
                updateImage={updateImage}
            />
        );
    }

    return (
        <ImageModalWindow
            open={isImageModalOpen}
            onCancel={closeImageModal}
            centered={true}
            footer={null}
            closeIcon={<CloseIcon />}
            width={'850px'}
            className={isReadOnly ? 'readOnly' : ''}
            destroyOnClose={true}
        >
            <ImageModalContainer>
                <ImageContainer>
                    <img src={image.url}/>
                </ImageContainer>
                <ImagesPaginationContainer>
                    <CustomPagination
                        page={pageNumber}
                        total={imagesTotalCount}
                        pageSize={1}
                        changePageNumber={changePageNumber}
                        handleCurrentPage={handleCurrentPage}
                        paginationObjectsName='images'
                        maxPages={imagesTotalCount}
                    />
                </ImagesPaginationContainer>
                { getForm() }
            </ImageModalContainer>
        </ImageModalWindow>
    );
}

export default ImageModal;