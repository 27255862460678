import React from 'react';
import { Form, Space, Input, Image } from 'antd';
import { TableData } from '../../../models/tableData/types';

interface Props {
    searchString: string;
    changeSearch: (value: string) => void;
    cleanFilter: () => void;
    handleSearchSubmit: () => void;
}

const ProductsSearch: React.FC<Props> = ({ searchString, changeSearch, cleanFilter, handleSearchSubmit }) => {
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeSearch!(e.target.value);
    };

    return (
        <Form>
            <Space>
                <Input
                    placeholder="Brand or product name"
                    onPressEnter={handleSearchSubmit}
                    onChange={handleSearchChange}
                    value={searchString}
                    id="table-search-input"
                    style={{width: 220 + `px`}}
                    prefix={
                        <>
                            {!searchString && (
                                <Image
                                    src="src/assets/images/magnifying-glass.svg"
                                    preview={false}
                                />
                            )}
                        </>
                    }
                    suffix={
                        <>
                            {searchString && (
                                <Image
                                    preview={false}
                                    src="src/assets/images/close-circle-icon.svg"
                                    onClick={cleanFilter}
                                />
                            )}
                        </>
                    }
                ></Input>
            </Space>
        </Form>
    );
};

export default ProductsSearch;
