import { Divider, Form, Image, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import Dragger from 'antd/lib/upload/Dragger';
import styled from 'styled-components';
import {
    blue01,
    blueDark01,
    blueLink,
    error,
    orange,
    orange01,
    orange02,
    white,
} from '../../styles/colors';
import { BoldText, PrimaryButton, SpaceBetween, Text } from '../../styles/common.styles';
import { HiddenInputProps } from './ProjectInformation.types';
import { InvisibleButton } from '../../styles/common.styles';
import CustomSelect from '../common/select/CustomSelect';
import CustomInput from '../common/inputs/CustomInput';


export const ProjectVisibilityTextTipContainerProjectInfo = styled('div')`
    text-align: left !important;
    position: relative;
    cursor: pointer;
    width: 13px;
    height: 13px;
    top: 0px;
    left: 0px;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -8px;
            left: 13px;
            padding: 12px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 10px;
            line-height: 12px;
            font-family: "Proxima Nova";
            white-space: nowrap;
            z-index: 200;
            width: 342px;
            white-space: normal;
            a {
                color: ${blueLink};
                &:hover {
                    color: ${blueLink};
                }
            }
            ul {
                padding-left: 12px;
                padding-top: 2px;
            }
        }
    }
`;

export const ProjectVisibilityTextTipContainerStartProject = styled('div')`
    position: relative;
    cursor: pointer;
    width: 13px;
    top: -55px;
    left: 155px;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -150px;
            left: 13px;
            padding: 12px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 10px;
            line-height: 12px;
            font-family: "Proxima Nova";
            white-space: nowrap;
            z-index: 200;
            width: 342px;
            white-space: normal;
            a {
                color: ${blueLink};
                &:hover {
                    color: ${blueLink};
                }
            }
            ul {
                padding-left: 12px;
                padding-top: 2px;
            }
        }
    }
`;