import { Divider } from 'antd';
import styled from 'styled-components';
import {
    blue01,
    blue03,
    blueDark01,
    grayDark01,
    grayDark03,
    grayDark05,
    grayLight01,
    grayLight03,
    grayLight04,
    blueLink,
    green,
    white,
    black,
} from '../../../../../styles/colors';
import { ErrorMessage, InvisibleButton, Text } from '../../../../../styles/common.styles';

const GeneralSection = styled('div')`
    padding: 20px 20px;
    position: relative;
    border-bottom: 1px solid ${grayLight01};
    &:after {
        content: '';
        position: absolute;
        top: 20px;
        right: 0;
        bottom: 20px;
        display: inline-block;
        width: 1px;
        background-color: ${grayLight03};
    }
    &.without-after {
        &:after {
            content: none;
        }
    }
`;

export const ProductContainer = styled('div')`
    margin-left: 40px;
    display: flex;
`;

export const BrandSection = styled('div')`
    background-color: #e3eaf7;
    padding: 20px;
    flex: 1;
    width: 20%;
    min-width: 20%;
    .invisible {
        opacity: 0;
    }
    &.freeListing {
        background-color: ${blue03};
    }
    &.withBorder {
        border-bottom: 1px solid #ffffff;
    }
    @media screen and (max-width: 1500px) {
        flex: unset;
        width: 150px;
        min-width: unset;
    }
`;

export const BrandSectionInnerData = styled('div')`
    overflow-wrap: break-word;
`;

export const HelpButton = styled(InvisibleButton)`
    align-items: flex-start;
    margin-top: 2px;
    &.details {
        margin-top: 16px;
    }

    div {
        text-wrap: wrap;
        text-align: left;
        word-break: break-word;
        -ms-word-break: break-all;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
    }
`;

export const ProductNameSection = styled(GeneralSection)`
    flex: 1;
    width: 0;
    overflow-wrap: break-word;
    .ant-input {
        width: 125px;
        font-size: 12px;
        line-height: 14px;
    }
    &.withoutBorder {
        border-bottom: none;
    }
    @media screen and (max-width: 1500px) {
        flex: unset;
        width: unset;
    }
`;

export const ProductItemPropertiesSection = styled(GeneralSection)`
    padding-right: 1px !important;
    .ant-input {
        width: 103px;
        font-size: 12px;
        line-height: 14px;
    }
    .ant-select-selector {
        width: 103px !important;
        padding: 5px 7px !important;
        height: 28px !important;
        .ant-select-selection-item {
            font-weight: 400 !important;
            font-size: 12px !important;
            line-height: 14px !important;
            color: ${blueDark01} !important;
        }
    }
    &.withoutBorder {
        border-bottom: none;
    }
`;

export const ProductPropertiesColumnsContainer = styled('div')`
    margin-left: 0px;
    display: flex;
`;

export const ProductItemPropertiesLeftSection = styled(GeneralSection)`
    padding-top: 6px !important;
    .ant-select {
        display: block !important;
    }
    .ant-input {
        width: 103px;
        height: 28px !important;
        font-size: 12px;
        line-height: 14px;
    }
    .ant-select-selector {
        width: 103px !important;
        padding: 5px 7px !important;
        height: 28px !important;
        .ant-select-selection-item {
            font-weight: 400 !important;
            font-size: 12px !important;
            line-height: 14px !important;
            color: ${blueDark01} !important;
        }
        .ant-select-selection-search {
            height: 28px !important;
            display: block !important;
            .ant-select-selection-search-input {
                height: 28px !important;
            }
        }
    }
    &.withoutBorder {
        border-bottom: none;
    }
`;

export const ProductItemLabel = styled(Text)`
    margin-bottom: 4px;
    margin-top: 12px;
`;

export const ProductItemLabelNoTopMargin = styled(Text)`
    margin-bottom: 4px;
    margin-top: 0px;
`;

export const ProductItemLabelMFR = styled(Text)`
    margin-bottom: 4px;
`;

export const PricingInputContainer = styled('div')`
    position: relative;
`;

export const FinishScheduleIdInputContainer = styled('div')`
    position: relative;
`;

export const PricingError = styled(ErrorMessage)`
    left: 0;
    bottom: -15px;
`;

export const FinishScheduleIdError = styled(ErrorMessage)`
    left: 0;
    bottom: -15px;
`;

export const DescriptionInputContainer = styled('div')`
    position: relative;
    .ant-input {
        width: 100%;
        height: 28px !important;
    }
`;

export const DescriptionInputContainerMFR = styled('div')`
    position: relative;
    min-width: 187px;
`;

export const ProductItemPropertiesRightSection = styled(GeneralSection)`
    padding-top: 6px !important;
    .ant-input {
        width: 125px;
        height: 28px !important;
        font-size: 12px;
        line-height: 14px;
    }
    .ant-select-selector {
        width: 103px !important;
        padding: 5px 7px !important;
        .ant-select-selection-item {
            font-weight: 400 !important;
            font-size: 12px !important;
            line-height: 14px !important;
            color: ${blueDark01} !important;
        }
    }
    &.withoutBorder {
        border-bottom: none;
    }
`;

export const ProductRadioButtonContainer = styled('div')`
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    .ant-radio-inner::after {
        background-color: ${white};
    }
    .ant-radio-checked .ant-radio-inner {
        background-color: ${blue01};
    }
    .ant-radio-wrapper {
        color: ${grayDark01};
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        margin-right: 4px; 
    }
`;

export const ProductDatesContainer = styled('div')`
    margin-top: 0px;
    display: flex;
    align-items: left;
    gap: 16px;
`;

export const DateSection = styled(GeneralSection)`
    padding-top: 6px !important;
    min-width: 115px;
    &.withoutBorder {
        border-bottom: none;
    }
`;

export const ActionsSection = styled('div')`
    display: flex;
    min-width: 180px;
    padding: 0 10px 0 20px;
    border-bottom: 1px solid ${grayLight01};
    gap: 30px;
    &.withoutBorder {
        border-bottom: none;
    }
`;

export const ActionContainer = styled('div')`
    margin: 20px 0;
`;

export const ActionCloseContainer = styled('div')`
    margin-top: 10px;
    cursor: pointer;
`;
export const ProductItemDivider = styled(Divider)`
    border-top: 1px solid ${grayLight04};
    margin: 12px 0;
`;

export const ActionItem = styled('div')`
    align-items: center;
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
    div {
        white-space: nowrap;
    }
    .ant-checkbox-inner {
        border-radius: 50%;
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            border-radius: 50%;
            background-color: ${green};
        }
    }
`;

export const DetailItemsSection = styled('div')`
    display: flex;
    min-width: 950px;
`;

export const ProductDetailsWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 75%;
`;

export const EC3Url = styled('div')`
    display: flex;
    gap: 2px;
    a {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #134fbd;
        &:hover {
            opacity: 0.5;
        }
    }
`;

export const QuestionTipContainer = styled('div')`
    position: relative;
    cursor: pointer;
    width: 10px;
    .tip {
        display: none;
        div {
            margin-bottom: 10px;
        }
    }
    &:hover {
        .tip {
            display: block;
            position: absolute;
            top: -30px;
            left: 24px;
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            border: 1px solid grey;
            box-shadow: rgb(0 0 0 / 44%) 2px 12px 19px -6px;
            background: #fffbc4;
            color: black;
            font-size: 12px;
            font-family: sans-serif;
            white-space: pre-wrap;
            z-index: 2;
            width: 20px;
            text-wrap: wrap;
            word-break: break-word;
            -ms-word-break: break-all;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        }
    }
`;

export const FavoriteButton = styled(InvisibleButton)`
    align-items: flex-start;
    margin-top: 0px;
    padding-bottom: 0px;
    &.favoriteButtonText {
        padding-top: 8px;
    }
`;

export const OneLineContainer = styled('div')`
    margin-top: 2px;
    margin-bottom: 8px;
    display: flex;
`;

export const DiscussAndUploadAndCounts = styled('div')`
    margin: 0 0 4px 0;
    display: flex;
    gap: 10px;
`;

export const TextCounts = styled(Text)`
    color: ${blueLink};
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    font-family: 'Proxima Nova';
`;

export const TextCountsItem = styled('span')`
    margin: 0 0 0 8px;
    cursor: pointer;
`;

export const QuintileInformation = styled('div')`
    display: flex;
    column-gap: 14px;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
`;

export const ProgramRow = styled('div')`
    display: flex;
    flex-wrap: nowrap;
    max-width: inherit;
    position: relative;
    font-weight: 400;
    .program {
        display: flex;
        flex-wrap: nowrap;
        font-size: 10px;
        padding: 0 0 2px 0;
        line-height: 12px;
        a {
            font-family: Proxima N W15 Smbd, Arial, Helvetica Neue, Helvetica, sans-serif;
            color: ${blueLink};
            &:hover {
                opacity: 0.5;
            }
        }
        .greenRect {
            margin: 1.5px 0 0 -5px;
        }
    }
    .results {
        flex-wrap: nowrap;
        margin-left: 5px;
        font-size: 10px;
        padding: 0 0 5px 0;
        display: flex;
        align-items: center;
        font-family: 'Proxima Nova';
        line-height: 12px;
        &.circle {
            display: unset;
        }
    }
`;

export const TipContainer = styled('span')`
    text-transform: initial;
    white-space: normal;
    background: url(src/assets/images/icon-tooltip_darker.svg) no-repeat 0 0;
    background-size: 10px 10px;
    float: right;
    margin: 0 0 0 0;
    width: 11px;
    cursor: pointer;
    .ant-image {
        display: flex;
    }
    img {
        cursor: pointer;
    }
    .program-tip {
        display: none;
        &:hover {
            display: block;
        }
    }
    .tooltip-ec3 {
        display: none;
        &:hover {
            display: block;
        }
    }
    .tooltip-epdpt {
        display: none;
        &:hover {
            display: block;
        }
    }
    &:hover {
        .program-tip,
        .tooltip-ec3 {
            z-index: 1;
            display: block;
            margin-left: 9px;
            margin-top: -20px;
            position: absolute;
            border-radius: 3px;
            background: #ffffb8;
            color: ${black};
            &.products-tab {
                margin-left: 5px;
            }
        }

        .tooltip-ec3 {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }

        .tooltip-epdpt {
            z-index: 1;
            display: block;
            margin-left: 9px;
            margin-top: -20px;
            position: absolute;
            border-radius: 3px;
            background: #ffffb8;
            color: ${black};
            &.products-tab {
                margin-left: 5px;
            }
        }

        .tooltip-epdpt {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }

        .shadow {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }
    }
    &.ec3 {
        display: inline;
        margin-top: 0px;
        margin-left: 3px;
    }
`;

export const PercentageText = styled('div')`
    display: inline;
    margin-left: 3px;
    margin-top: 0;
`;

export const ColorCircle = styled('span')`
    border-radius: 5px;
    font-weight: 400;
    background-color: ${(props: { color: string }) => props.color};
    display: inline-block;
    height: 9px;
    margin-right: 0.4em;
    margin-top: 0.5px;
    padding: 0;
    width: 9px;
`;

export const ResultTextAfterCircle = styled('div')`
    margin-top: -12.5px;
    margin-left: 12px;
`;

export const TipContainerImpactAreas = styled('span')`
    text-transform: initial;
    white-space: normal;
    background: url(src/assets/images/mfr-question.svg) no-repeat 0 0;
    background-size: 10px 10px;
    float: inherit;
    margin: 0 0 0 20px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    .ant-image {
        display: flex;
    }
    img {
        cursor: pointer;
    }
    .program-tip {
        display: none;
        &:hover {
            display: block;
        }
    }
    .tooltip-ec3 {
        display: none;
        &:hover {
            display: block;
        }
    }
    .tooltip-epdpt {
        display: none;
        &:hover {
            display: block;
        }
    }
    &:hover {
        .program-tip,
        .tooltip-ec3 {
            z-index: 1;
            display: block;
            margin-left: 9px;
            margin-top: -20px;
            position: absolute;
            border-radius: 3px;
            background: #ffffb8;
            color: ${black};
            &.products-tab {
                margin-left: 5px;
            }
        }

        .tooltip-ec3 {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }

        .tooltip-epdpt {
            z-index: 1;
            display: block;
            margin-left: 9px;
            margin-top: -20px;
            position: absolute;
            border-radius: 3px;
            background: #ffffb8;
            color: ${black};
            &.products-tab {
                margin-left: 5px;
            }
        }

        .tooltip-epdpt {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }

        .shadow {
            border: 1px solid grey;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }
    }
    &.ec3 {
        display: inline;
        flex-wrap: wrap;
        margin-top: 0px;
        margin-left: 3px;
    }
`;

export const DotsAction = styled('div')`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${grayDark05};
    box-shadow: 0px 8px 0px ${grayDark05}, 0px 16px 0px ${grayDark05};
    position: absolute;
    top: 13px;
`;

export const OpenMenuButton = styled('button')`
    border: none;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    padding: 20px;
`;
export const ProductActionMenuItem = styled('span')`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${grayDark03};
    white-space: nowrap;
`;

