import { Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ChangeUsersModal from '../../../components/ChangeUsersModal/ChangeUsersModal';
import { toShortLocalDate } from '../../../helpers/dateHelper';
import { useModalState } from '../../../hooks/modalState';
import { usePermissions } from '../../../hooks/permissions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { SubscriptionTypes } from '../../../models/subscription/enums';
import { SubscriptionData } from '../../../models/subscription/types';
import { getCustomerPortalLinkAction } from '../../../redux/actions/checkoutActions';
import { AddMoreUsersProps, SettingPropertyProps } from '../SettingPage.types';
import SettingsGroupBox from '../settingsGroupBox/SettingsGroupBox';
import {
    AddMoreUsersContainer,
    ManageSubscriptionContent,
    OpenModalText,
    SettingActionLink,
    SettingLabelText,
    SettingLabelValue,
    SettingPropertyRow,
} from './SubscriptionInfo.styles';

interface Props {
    subscriptionData: SubscriptionData | undefined;
}

export const SubscriptionInfo: React.FC<Props> = ({ subscriptionData }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { getAccountSubscription } = usePermissions();
    const { isOpen, onOpen, onClose } = useModalState();
    const selector = useAppSelector((state) => state);
    const { authReducer } = selector;
    const goToSubscriptions = async () => {
        if (
            getAccountSubscription() === SubscriptionTypes.FREE ||
            getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_INDIVIDUAL ||
            getAccountSubscription() === SubscriptionTypes.FREE_TRIAL_OFFICE
        ) {
            history.push('/plan');
            return;
        }
        if (authReducer.user?.accountId) {
            const { payload } = (await dispatch(getCustomerPortalLinkAction())) as any;
            if (payload) {
                window.location.href = payload.data;
            }
        }
    };
    const getSubscriptionProperties = (): SettingPropertyProps[] => {
        let planName = authReducer.user?.subscriptionPlanName as string;
        if (planName && planName.toLowerCase().includes('trial')) {
            planName = planName.replace('FreeTrial', '') + '(Trial)';
        }
        const subscriptionProperties: Array<SettingPropertyProps> = [
            {
                name: 'Current subscription',
                value: `${planName || '-'}`,
            },

            {
                name: 'Contact name',
                value: `${subscriptionData?.contactName || '-'}`,
            },
            {
                name: 'Email address',
                value: `${subscriptionData?.ownerEmail || '-'}`,
            },
            {
                name: 'Phone number',
                value: `${subscriptionData?.phone || '-'}`,
            },
        ];

        if (getAccountSubscription() === SubscriptionTypes.INDIVIDUAL) {
            subscriptionProperties.splice(
                1,
                0,
                {
                    name: 'Subscription automatically renews',
                    value: `${toShortLocalDate(subscriptionData?.nextRenewOn) || '-'}`,
                },
                {
                    name: 'Payment method',
                    value: `${subscriptionData?.paymentMethod || '-'}`,
                },
            );
        }
        const subscrType = getAccountSubscription();
        if (subscrType === SubscriptionTypes.OFFICE) {
            subscriptionProperties.splice(
                1,
                0,
                {
                    name: 'Paid accounts assigned',
                    value: `${subscriptionData && subscriptionData?.paidUserInUse && subscriptionData?.maxUserCount ? (subscriptionData?.paidUserInUse + ' of '  + subscriptionData?.maxUserCount) : ''}`,
                    actionLink: '#',
                    actionText: undefined,
                },
                {
                    name: 'Associate accounts assigned',
                    value: `${subscriptionData?.maxAssociateUserCount ? subscriptionData?.freeUserInUse + ' of '  + subscriptionData?.maxAssociateUserCount : subscriptionData?.freeUserInUse + ' of 0'}`,
                },
                {
                    name: '',
                    value:
                        subscriptionData?.status !== 'Cancelled' &&
                        !subscriptionData?.cancelAtPeriodEnd &&
                        !authReducer.user?.parentAccountId ? (
                            <AddMoreUsers style={{marginTop:`-30`+`px`}}
                                openModal={onOpen}
                                usersCount={subscriptionData?.maxUserCount}
                            />
                        ) : undefined,
                    actionLink: '#',
                    actionText: undefined,
                    marginBottom: '24',
                },
                {
                    name: `${
                        subscriptionData?.status !== 'Cancelled' &&
                        !subscriptionData?.cancelAtPeriodEnd
                            ? 'Subscription automatically renews'
                            : 'Cancelled on'
                    }`,
                    value: `${toShortLocalDate(subscriptionData?.nextRenewOn)}`,
                    actionLink: '#',
                    actionText: `${
                        subscriptionData?.status === 'Cancelled' ||
                        subscriptionData?.cancelAtPeriodEnd
                            ? 'Renew plan'
                            : ''
                    }`,
                    openModal: () => {
                        goToSubscriptions();
                    },
                } as SettingPropertyProps,
                {
                    name: 'Payment method',
                    value: `${subscriptionData?.paymentMethod || ''}`,
                },
            );
        }
        if (subscrType === SubscriptionTypes.FREE_TRIAL_OFFICE) {
            subscriptionProperties.splice(
                1,
                0,
                {
                    name: 'User accounts',
                    value: `${subscriptionData?.maxUserCount || ''}`,
                    actionLink: '#',
                    actionText:
                        subscriptionData?.status !== 'Cancelled' &&
                        !subscriptionData?.cancelAtPeriodEnd &&
                        !authReducer.user?.parentAccountId ? (
                            <AddMoreUsers
                                openModal={onOpen}
                                usersCount={subscriptionData?.maxUserCount}
                            />
                        ) : undefined,
                },
                {
                    name: 'Accounts assigned',
                    value: `${subscriptionData?.userInUse || ''}`,
                    marginBottom: '24',
                },
            );
        }

        return subscriptionProperties;
    };

    return (
        <SettingsGroupBox title="Manage subscription">
            <Space direction="vertical" size={16}>
                {getSubscriptionProperties().map((prop) => (
                    <ManageSubscriptionContent key={prop.name}>
                        <SettingProperty {...prop} />
                    </ManageSubscriptionContent>
                ))}
            </Space>
            {isOpen && (
                <ChangeUsersModal
                    isOpen={isOpen}
                    handleCancel={onClose}
                    currentUsersCount={subscriptionData?.maxUserCount}
                    subscriptionName="Office"
                />
            )}
        </SettingsGroupBox>
    );
};

const SettingProperty: React.FC<SettingPropertyProps> = ({
    name,
    value,
    actionLink,
    actionText,
    marginBottom,
}) => {
    return (
        <SettingPropertyRow gutter={16} marginBottom={marginBottom}>
            <SettingLabelText>
                <div>{name}</div>
            </SettingLabelText>
            <SettingLabelValue>
                <div>{value}</div>
            </SettingLabelValue>
            {!!actionLink && !!actionText && (
                <SettingActionLink>
                    <div>{actionText}</div>
                </SettingActionLink>
            )}
        </SettingPropertyRow>
    );
};

const AddMoreUsers: React.FC<AddMoreUsersProps> = ({ openModal, usersCount }) => {
    const adminCanAddMoreUsers = false;
    if (!usersCount) {
        return <></>;
    }
    if (adminCanAddMoreUsers && usersCount && usersCount >= 10) {
        return (
            <AddMoreUsersContainer>
                Need more Admin, Member or Associate accounts?{' '}
                <a href={`mailto:support@transparencycatalog.com`}>Contact us</a> for custom pricing.
            </AddMoreUsersContainer>
        );
    } else if (usersCount) {
        return (
            <div style={{marginTop:`-10`+`px`}}><AddMoreUsersContainer>
                Need more Admin, Member or Associate accounts?{' '}
                <a href={`mailto:support@transparencycatalog.com?subject=We need more Project Builder and Library accounts`}>Contact us</a>.
            </AddMoreUsersContainer></div>
        );
    }
    if (adminCanAddMoreUsers) {
        return (
            <AddMoreUsersContainer>
                <OpenModalText onClick={openModal}>Add more users</OpenModalText>
                <div>
                    Need 11 or more user accounts3?{' '}
                    <a href={`mailto:support@transparencycatalog.com`}>Contact us</a> for custom pricing.
                </div>
            </AddMoreUsersContainer>
        );
    }
    return <></>;
};
