import React, { useState, useEffect } from 'react';
import { useModalState } from '../../../../hooks/modalState';
import { useAppDispatch } from '../../../../hooks/redux';
import { useHistory, useLocation } from 'react-router-dom';
import { alertService } from '../../../../services';
import { AlertMessages } from '../../../../components/alert/AlertMessages';
import { usePermissions } from '../../../../hooks/permissions';
import ExportUsersToCsv from '../../../components/admins/exportUsersToCsv/ExportUsersToCsv';
import { UsersAction } from '../../../../models/user/enums';
import {
    ActionButton,
    OpenActionsMenu,
    UsersActionDropdown,
    UsersActionSpace,
    UsersDotsAction,
} from './UsersActions..styles';
import { getUsersActionItems } from './UsersActions..helper';
import { ActionResponse } from '../../../models/actionResponse/types';

interface Props {

}

const UsersActions: React.FC<Props> = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [open, setOpen] = useState({
        csv: false,
    });

    const [productIds] = useState<Array<InternalProduct>>([]);

    const dropdownMenu = useModalState();

    useEffect(() => {
        const code = params.get('code');
        if (code && project) {
            setOpen({ ...open, csv: true });
        }
    }, [open]);

    const closeModal = (data: { key: string }) => {
        setOpen({ ...open, [data.key]: false });
    };

    const handleMenuSelect = (data: { key: string }) => {
        if (data.key === 'ec3' && !productIds?.length) {
            console.log('There are no products to export');
            alertService.warn('There are no products to export');
            return;
        }
        setOpen({ ...open, [data.key]: true });
    };

    return (
        <UsersActionSpace>
                <UsersActionDropdown
                    menu={{
                        items: getUsersActionItems(),
                        onClick: handleMenuSelect,
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                    onOpenChange={dropdownMenu.onToggle}
                >
                    <div>
                        <OpenActionsMenu>
                            <UsersDotsAction />
                        </OpenActionsMenu>
                    </div>
                </UsersActionDropdown>
            {open.csv && (
                    <ExportUsersToCsv
                        isOpen={open.csv}
                        closeModal={closeModal}
                        reload={reload}
                        options={[]}
                    />
            )}
        </UsersActionSpace>
    );
};

export default UsersActions;
