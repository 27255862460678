import React, { useEffect, useState } from 'react';
import { Form, Spin, UploadProps } from 'antd';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';
import { alertService } from '../../../services';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useModalState } from '../../../hooks/modalState';
import { createBulkUsersAction, getUserReferencesAction } from '../../../redux/actions/userActions';
import { CreateUserRequest } from '../../../redux/actions/userActions/types';
import { template } from './CreateBulkAccount.mockData';
import { UserTableFields } from '../../../models/user/user';
import ModalTitle from '../../common/modalTitle/ModalTitle';
import ErrorModal from '../../common/errorModal/ErrorModal';
import ErrorWarnAlertModal from '../../common/errorWarnAlertModal/ErrorWarnAlertModal';
import ErrorDoubleWarnAlertModal from '../../common/errorWarnAlertModal/ErrorDoubleWarnAlertModal';
import { SelectData } from '../../../models/common/types';

import '../../../assets/files/users_template.csv';

import ModalFooter from '../../common/modalFooter/ModalFooter';
import { Text, CloseIcon } from '../../../styles/common.styles';
import { blueDark01 } from '../../../styles/colors';
import { CreateInBulkModal } from './CreateBulkAccountModal.styles';
import FileInput from '../../common/fileInput/FileInput';

interface Props {
    isOpen: boolean;
    closeModal: (data: { key: 'bulk' }) => void;
    reload: (modal: 'bulk', newUsers: Array<UserTableFields>) => void;
}

const CreateBulkAccountModal: React.FC<Props> = ({ isOpen, closeModal, reload }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { authReducer } = selector;

    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [topLine, setTopLine] = useState('');
    const [topLine2, setTopLine2] = useState('');
    const [listStrings, setListStrings] = useState('');
    const [listStrings2, setListStrings2] = useState('');
    const [bottomLine, setBottomLine] = useState('');
    const [bottomLine2, setBottomLine2] = useState('');

    const [addingUsersResult, setAddingUsersResult] = useState(Array<any>());
    const [invalidRowData, setInvalidRowData] = useState(Array<any>());

    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [isPending, setIsPending] = useState(false);
    const [parsedUsers, setParsedUsers] = useState(Array<any>());
    const [selectData, setSelectedData] = useState({
        allTitleRoles: Array<SelectData>(),
        projectGroup: Array<SelectData>(),
        allAccountRoles: Array<SelectData>(),
    });
    const errorModal = useModalState();
    const errorWarnAlertModal = useModalState();
    const errorDoubleWarnAlertModal = useModalState();

    const accountsCreatedText = 'Project Builder & Library has created accounts for all other people listed in the uploaded file.';

    useEffect(() => {
        getDataForSelect();
    }, []);

    const getDataForSelect = async () => {
        const { payload } = (await dispatch(getUserReferencesAction())) as any;
        if (payload.data) {
            setSelectedData({
                projectGroup: selectData.projectGroup,
                allTitleRoles: payload.data.allTitleRoles,
                allAccountRoles: payload.data.allAccountRoles,
            });
        }
    };

    const handleCancel = () => {
        closeModal({ key: 'bulk' });
    };

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        beforeUpload: () => false,
        onChange(info: any) {
            if (!info?.file) {
                alertService.error('Something went wrong');
                return;
            }
            if (info.file.size < 1) {
                alertService.error('0 bytes size file cannot be attached', { fixed: true });
                return;
            }
            const fileExtension = info.file.name.split('.');
            if (fileExtension[fileExtension.length - 1] !== 'csv') {
                alertService.error('You should upload only CSV file');
                return;
            }
            setFileName(info.file.name);
            setFile(info.file);
        },
    };

    const removeFile = () => {
        setFileName('');
        setParsedUsers([]);
    };

    const isKeyRequired = (key: string): boolean => {
        return (key === 'First name' || key === 'Last name' || key === 'Title/role' ||
                key === 'Email' || key === 'Project Builder account type');
    }

    const getAccountRoleId = (parsedAccountRoleId: string | undefined): string | undefined => {
        if (!parsedAccountRoleId) {
            return undefined;
        }

        const parsedAccountRoleIdInLowerCase = parsedAccountRoleId.toLowerCase();
        if (parsedAccountRoleIdInLowerCase !== 'admin' && parsedAccountRoleIdInLowerCase !== 'accountadmin' &&
            parsedAccountRoleIdInLowerCase !== 'member' && parsedAccountRoleIdInLowerCase !== 'accountmember' &&
            parsedAccountRoleIdInLowerCase !== 'associate' && parsedAccountRoleIdInLowerCase !== 'accountassociate') {
            return undefined;
        }

        return selectData.allAccountRoles.find((role) =>
            role.name.toLowerCase().includes(parsedAccountRoleIdInLowerCase || '')
        )?.id;
    }

    const parseFile = () => {
        let rowWithBlank: Array<string> = [];
        let emailDuplicates: Array<string> = [];
        const parseFileStoppingError: Array<string> = [];
        let differentErrors: Array<string> = [];
        let additionalClause = '';
        const additionalEmailDuplicatesClause = '';
        const projectBuilderAccountKey = 'Project Builder account type';
        const andMoreString = ' and more...';
        setInvalidRowData([]);
        if (file) {
            setIsPending(true);
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: function (results: any) {
                    const parsedData = Array<any>();
                    const addingEmails = [];
                    results.data.map((data: any, index: number) => {
                        const differentErrorsList: Array<string> = [];
                        let fieldNumber = -1;
                        for (const key in data) {
                            fieldNumber++;
                            const errors: Array<string> = [];
                            const emailDuplicatesList: Array<string> = [];
                            if (index === 0 && fieldNumber === 0 && key !== 'Last name') {
                               parseFileStoppingError.push('First line must begin with "Last name" field.');
                               continue;
                            }
                            if (isKeyRequired(key) && !data[key]?.length) {
                                differentErrorsList.push(`There is no ${key} in row ${index + 1}`);
                                continue;
                            }
                            if (key === 'Email' && addingEmails.indexOf(data.Email) > -1 && data.Email !== '') {
                                emailDuplicatesList.push(data.Email);
                            } else if (key === 'Email') {
                                addingEmails.push(data.Email);
                            }
                            if (
                                key === 'Email' &&
                                !/^\w+([.-]?[.+]\w+)*@\w+([.-]?\w+)*\.\w{2,10}$/.test(data.Email)
                            ) {
                                differentErrorsList.push(`Invalid email format in row ${index + 1}`);
                            }
                            if (
                                key === 'Phone' &&
                                data.Phone &&
                                !/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(data.Phone)
                                /*(123) 456 7899
                                  (123).456.7899
                                  (123)-456-7899
                                  123-456-7899
                                  123 456 7899
                                  1234567899 */
                            ) {
                                differentErrorsList.push(`Invalid phone format in row ${index + 1}`);
                            }
                            if (rowWithBlank.length < 100) {
                                rowWithBlank = rowWithBlank.concat(errors);
                            } else {
                                additionalClause = andMoreString;
                            }
                            if (emailDuplicates.length < 100) {
                                emailDuplicates = emailDuplicates.concat(emailDuplicatesList);
                            } else if (emailDuplicates.length === 99) {
                                additionalEmailDuplicatesClause.push(andMoreString);
                            }
                        }
                        const parsedUser = template.reduce((agg, value) => {
                            agg[value.key] = data[value.column];
                            return agg;
                        }, {} as CreateUserRequest);
                        parsedUser.accountRoleID = getAccountRoleId(parsedUser.accountRoleID?.toString());
                        if (differentErrors.length < 100) {
                            differentErrors = differentErrors.concat(differentErrorsList);
                        } else if (differentErrors.length === 99) {
                            differentErrors.push(andMoreString);
                        }
                        if (!parsedUser.titleRoleID && differentErrors.length <= 100 && data['Title/role']?.length) {
                            differentErrors.push(`Invalid title/role in row ${index + 1}`);
                            differentErrorsList.push(`Invalid title/role in row ${index + 1}`);
                        }
                        if (!parsedUser.accountRoleID && differentErrors.length <= 100 && data[projectBuilderAccountKey]?.length) {
                            differentErrors.push(`Invalid Project Builder account type in row ${index + 1}`);
                            differentErrorsList.push(`Invalid Project Builder account type in row ${index + 1}`);
                        }
                        if (!differentErrorsList.length) {
                            parsedData.push({
                                ...parsedUser,
                                projectBuilderRoleID: 'SMUser',
                            });
                        }
                    });

                    if (!parsedData.length && !differentErrors?.length) {
                        alertService.error('Should be at least one user');
                    }

                    if (!parsedData.length && differentErrors?.length) {
                        checkInvalidRowExists(differentErrors);
                    }

                    if (!parsedData.length) {
                        setFileName('');
                        return;
                    }

                    setInvalidRowData(differentErrors);

                    if (!rowWithBlank.length && !emailDuplicates.length) {
                        setParsedUsers(parsedData);
                    }
                },
            });
        } else {
            alertService.error('Something went wrong');
        }
        setTimeout(() => {
            setIsPending(false);
            if (emailDuplicates.length) {
                setType('error');
                setTitle('Error');
                setTopLine('The following people appear more than once in the uploaded file:');
                setListStrings(emailDuplicates.join(','));
                setBottomLine('Please delete or correct duplicate email addresses in the file and upload it again.');
                errorWarnAlertModal.onOpen();
            }
            if (parseFileStoppingError.length) {
                setType('error');
                setTitle('Error');
                setTopLine('The following errors found in the uploaded file:');
                setListStrings(parseFileStoppingError.join(','));
                setBottomLine('Please correct the file and upload it again.');
                errorWarnAlertModal.onOpen();
            }
            if (rowWithBlank.length) {
                alertService.error(rowWithBlank.join(', ') + additionalClause, {
                    autoClose: false,
                });
            }
        }, 1000);
    };

    const onSubmit = async () => {
        setIsPending(true);
        const { payload } = (await dispatch(
            createBulkUsersAction({
                users: parsedUsers.map((user) => ({
                    ...user,
                    phoneNumber: user.phoneNumber || null,
                    PhoneNumberExt: user.phoneNumberExt || null,
                    parentAccountId:
                        authReducer.user?.parentAccountId || authReducer.user?.accountId,
                })),
            }),
        )) as any;
        if (payload?.data && payload?.data.users ) {
            setAddingUsersResult(payload.data.users);
        }
        const invalidRowExists = invalidRowData && invalidRowData.length > 0;
        const existingEmailsExists = payload?.data && payload?.data.existingEmails && payload?.data.existingEmails.length > 0;
        if (invalidRowExists && existingEmailsExists) {
            checkInvalidRowsAndExistingEmails(invalidRowData, payload.data.existingEmails);
        } else if (existingEmailsExists) {
            checkExistingEmails(payload.data.existingEmails);
        } else if (invalidRowExists) {
            checkInvalidRowExists(invalidRowData, payload?.data.users.length > 0);
        } else if (payload?.data.users && payload?.data.users.length > 0) {
            reload('bulk', payload.data.users);
        }
        setIsPending(false);
    };

    const onCloseErrorWarnAlertModal = async () => {
        errorWarnAlertModal.onClose();
        if (addingUsersResult) {
            reload('bulk', addingUsersResult);
        }
    };

    const onCloseErrorDoubleWarnAlertModal = async () => {
        errorDoubleWarnAlertModal.onClose();
        if (addingUsersResult) {
            reload('bulk', addingUsersResult);
        }
    };

    function checkInvalidRowsAndExistingEmails(invalidRowDataToCheck, existingEmails) {
        if (existingEmails && existingEmails.length > 0 && invalidRowDataToCheck && invalidRowDataToCheck.length > 0) {
            setType('error');
            setTitle('Error');
            setTopLine('The CSV file contains the errors below. Please correct these errors and upload the file again.');
            setListStrings(invalidRowDataToCheck.join(','));
            setBottomLine('');
            setTopLine2('The following people already have accounts:');
            setListStrings2(existingEmails.join(','));
            setBottomLine2(accountsCreatedText);
            errorDoubleWarnAlertModal.onOpen();
        }
    }

    function checkInvalidRowExists(invalidRowDataToCheck, otherAccountsCreated: boolean = false) {
        if (invalidRowDataToCheck && invalidRowDataToCheck.length > 0) {
            setType('error');
            setTitle('Error');
            setListStrings(invalidRowDataToCheck.join(','));
            if (otherAccountsCreated) {
                setTopLine('The CSV file contains the errors below. Please correct these errors and upload the file again.');
                setBottomLine(accountsCreatedText);
            } else {
                setTopLine('The CSV file contains the following errors:');
                setBottomLine('Please correct the errors and upload the file again.');
            }
            errorWarnAlertModal.onOpen();
        }
    }

    function checkExistingEmails(existingEmails) {
        if (existingEmails && existingEmails.length > 0) {
            setType('warning');
            setTitle('Warning');
            setTopLine('The following people already have accounts:');
            setListStrings(existingEmails.join(','));
            setBottomLine(accountsCreatedText);
            errorWarnAlertModal.onOpen();
        }
    }

    return (
        <CreateInBulkModal
            open={isOpen}
            onCancel={handleCancel}
            centered
            footer={null}
            className="bulk-modal-container"
            closeIcon={<CloseIcon />}
        >
            <Spin size="large" spinning={isPending}>
                <div className="upload-container">
                    <ModalTitle
                        title={`Create accounts in bulk`}
                        icon="ic-modal-title-create-accounts-bulk.svg"
                    />
                    <Form.Item>
                        <Text weight={400} size={14} height={18} color={blueDark01}>
                            Upload a CSV file to create multiple accounts at once. (
                            <Link to="src/assets/files/users_template.csv" target="_blank" download>
                                Download CSV template.
                            </Link>
                            ) Project Builder & Library will automatically assign a temporary password for
                            each account.
                            &nbsp;
                            <a href= "https://transparencycatalog.zendesk.com/hc/en-us/articles/12195101033106-Account-administrators-managing-your-organization-s-account" target="_blank">
                              Learn more about how to create accounts in bulk.
                            </a>
                        </Text>
                    </Form.Item>
                    <Form.Item>
                        <FileInput
                            uploadProps={props}
                            fileName={fileName}
                            parseFile={parseFile}
                            removeFile={removeFile}
                        />
                    </Form.Item>
                </div>
                <ModalFooter
                    action="Create accounts"
                    submit={onSubmit}
                    cancel={handleCancel}
                    primaryProps={{ disabled: !parsedUsers.length || isPending }}
                    actionButtonWidth={125}
                />
            </Spin>
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
            {errorWarnAlertModal.isOpen && (
                <ErrorWarnAlertModal isOpen={errorWarnAlertModal.isOpen}
                   type={type}
                   title={title}
                   topLine={topLine}
                   listStrings={listStrings.split(',')}
                   bottomLine={bottomLine}
                   closeModal={onCloseErrorWarnAlertModal}
                />
            )}
            {errorDoubleWarnAlertModal.isOpen && (
                <ErrorDoubleWarnAlertModal isOpen={errorDoubleWarnAlertModal.isOpen}
                   type={type}
                   title={title}
                   topLine={topLine}
                   topLine2={topLine2}
                   listStrings={listStrings.split(',')}
                   listStrings2={listStrings2.split(',')}
                   bottomLine={bottomLine}
                   bottomLine2={bottomLine2}
                   closeModal={onCloseErrorDoubleWarnAlertModal}
                />
            )}
        </CreateInBulkModal>
    );
};

export default CreateBulkAccountModal;
