import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { extractFields } from '../../../helpers/extract';
import { ProjectData } from '../../../models/project/types';
import {
    getAvailableProjectsAction,
    getCollaboratorsAction,
    getProjectActivitiesAction,
    getProjectInformationAction,
    getProjectLibraryAction,
    getProjectReferencesAction,
    getRecentlyProjectsAction,
    sendProjectInvitationAction,
    updateProjectInformationAction,
} from '../../actions/projectActions';
import { getExternalProductsAction } from '../../actions/transparencyCatalogActions';
import { GetProjectInformationResponse } from '../../actions/projectActions/types';

export interface ProjectReducerType {
    pending: boolean;
    addedNewProject: boolean;
    createdProject: { id: number } | null;
    completedProjectId: number | null;
    activatedProjectId: number | null;
    deletedProjectId: number | null;
    templateProjectId: number | null;
    acceptedInvitation?: number;
    packageIdData: { projectId: string; packageId: string } | undefined;
    viewingProject: GetProjectInformationResponse | null;
}

const initialState: ProjectReducerType = {
    pending: false,
    addedNewProject: false,
    createdProject: null,
    completedProjectId: null,
    activatedProjectId: null,
    deletedProjectId: null,
    templateProjectId: null,
    acceptedInvitation: undefined,
    packageIdData: undefined,
    viewingProject: null,
};

export const projectSlice = createSlice({
    name: 'projects',
    initialState: initialState,
    reducers: {
        setAddNewProjectMark(state, action: PayloadAction<boolean>) {
            state.addedNewProject = action.payload;
        },
        setCreatedProject(state, action: PayloadAction<{ id: number }>) {
            state.createdProject = extractFields(Object.keys(new ProjectData()), [
                action.payload,
            ])[0];
        },
        setCompletedProject(state, action: PayloadAction<number | null>) {
            state.completedProjectId = action.payload;
        },
        setActivatedProject(state, action: PayloadAction<number | null>) {
            state.activatedProjectId = action.payload;
        },
        setDeletedProject(state, action: PayloadAction<number | null>) {
            state.deletedProjectId = action.payload;
        },
        setTemplateProject(state, action: PayloadAction<number | null>) {
            state.templateProjectId = action.payload;
        },
        setAcceptedInvitation(state, action: PayloadAction<number | undefined>) {
            state.acceptedInvitation = action.payload;
        },
        setProjectPackageId(
            state,
            action: PayloadAction<{ projectId: string; packageId: string } | undefined>,
        ) {
            state.packageIdData = action.payload;
        },
        setViewingProject(state, action: PayloadAction<GetProjectInformationResponse | null>) {
            state.viewingProject = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectLibraryAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getProjectLibraryAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getProjectLibraryAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getProjectInformationAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getProjectInformationAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getProjectInformationAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getRecentlyProjectsAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getRecentlyProjectsAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getRecentlyProjectsAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getProjectActivitiesAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getProjectActivitiesAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getProjectActivitiesAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(updateProjectInformationAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(updateProjectInformationAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(updateProjectInformationAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getCollaboratorsAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getCollaboratorsAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getCollaboratorsAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(sendProjectInvitationAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(sendProjectInvitationAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(sendProjectInvitationAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getProjectReferencesAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getProjectReferencesAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getProjectReferencesAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getAvailableProjectsAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getAvailableProjectsAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getAvailableProjectsAction.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(getExternalProductsAction.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(getExternalProductsAction.fulfilled, (state) => {
            state.pending = false;
        });
        builder.addCase(getExternalProductsAction.rejected, (state) => {
            state.pending = false;
        });
    },
});

export const {
    setAddNewProjectMark,
    setCreatedProject,
    setCompletedProject,
    setActivatedProject,
    setDeletedProject,
    setTemplateProject,
    setAcceptedInvitation,
    setProjectPackageId,
    setViewingProject,
} = projectSlice.actions;

export default projectSlice.reducer;
