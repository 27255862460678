import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Input, Form, Select, Image } from 'antd';
import CustomInput from '../../../../components/common/inputs/CustomInput';
import { BlueTitle } from '../../../../styles/common.styles';
import {
    PromoBlockContainer,
    CustomButton,
    ReactQuillContainer,
} from './PromoBlockItem.styles';
import { SelectableData, UpdateBannerModel } from '../../../models/promoBlocks/types';
import { Banner } from '../../../../models/common/types';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateBannerAction, uploadFileAction } from '../../../../redux/actions/bannerActions';
import { SelectData } from '../../../../models/common/types';
import { alertService } from '../../../../services';
import { AlertMessages } from '../../../../components/alert/AlertMessages';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

enum LinkTarget {
    SAME,
    NEW,
}

const linkTargets: Array<SelectData> = [
    {
        id: LinkTarget.SAME.toString(),
        name: 'Open Link in same window',
    },
    {
        id: LinkTarget.NEW.toString(),
        name: 'Open Link in new window',
    }
];

interface Props {
    bannerInfo: Banner;
    promoTypes: Array<SelectData>;
    updateBanners: (newBanner: Banner) => void;
}

const PromoBlockItem: React.FC<Props> = ({ bannerInfo, promoTypes, updateBanners }) => {
    const dispatch = useAppDispatch();
    const quillRef = useRef<ReactQuill | undefined>();
    const { id, numberOnPage, promoTypeId } = bannerInfo;

    const [textError, setTextError] = useState(false);
    const [promoTypesList, setPromoTypesList] = useState<Array<SelectableData>>([]);
    const [linkTargetsList, setLinkTargetsList] = useState<Array<SelectableData>>([]);
    const [form] = Form.useForm();
    const [value, setValue] = useState('');

    useEffect(() => {
        const text = bannerInfo.text;
        if (text) {
            setValue(text);
        }
    }, [bannerInfo]);

    useEffect(() => {
        form.setFieldsValue(bannerInfo);
    }, [bannerInfo]);

    useEffect(() => {
        setPromoTypesList(
            promoTypes.map((promoType) => {
                return {
                    value: Number(promoType.id),
                    label: promoType.name,
                };
            }),
        );

        setLinkTargetsList(
            linkTargets.map((linkTarget) => {
                return {
                    value: Number(linkTarget.id),
                    label: linkTarget.name,
                };
            }),
        );
    }, [promoTypes]);

    const getInitialLinkTargetId = (): LinkTarget => {
        return bannerInfo.openLinksInTextInNewTab ? LinkTarget.NEW : LinkTarget.SAME;
    }

    const handleSubmit = async (data: UpdateBannerModel) => {
        const newBannerInfo = {
            bannerId: id,
            text: changeTargetForLink(value, data.linkTargetId),
            headLine: data?.headLine,
            headLineLink: data?.headLineLink === '' ? undefined : data?.headLineLink,
            buttonText: data?.buttonText === '' ? undefined : data?.buttonText,
            buttonLink: data?.buttonLink === '' ? undefined : data?.buttonLink,
            promoTypeId: data?.promoTypeId ?? promoTypeId,
            openLinksInTextInNewTab: data.linkTargetId === LinkTarget.NEW,
        };

        const clone = { ...bannerInfo };
        updateBanners(clone);

        const { payload } = await dispatch(updateBannerAction(newBannerInfo));
        if (payload.data) {
            window.scroll(0, 0);
            alertService.success(AlertMessages.BANNER_UPDATED);
        }
    };

    const imageHandler = async (_file: File) => {
        const editor = quillRef.current?.getEditor();
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input?.files[0];
            if (/^image\//.test(file.type)) {
                console.log(file);
                const formData = new FormData();
                formData.append('image', file);
                const uploadResponse = await dispatch(
                    uploadFileAction({
                        bannerId: id,
                        imageFile: file,
                    }),
                );
                const url = uploadResponse?.payload?.data;
                if (editor) {
                    editor.insertEmbed(editor.getSelection(), 'image', url);
                }
            }
        };
    };

    const changeTargetForLink = (data: string, linkTarget: LinkTarget) => {
        const parentElement = document.createElement('div');
        parentElement.innerHTML = data;
        const linkElements = parentElement.getElementsByTagName('a');
        const targetValue = linkTarget === LinkTarget.SAME ? '_self' : '_blank';

        for (let linkIdx = 0; linkIdx < linkElements.length; linkIdx++) {
            linkElements[linkIdx].setAttribute('target', targetValue);
        }

        return parentElement.innerHTML;
    };

    const onValueChange = (data: string, _delta: any, source: any) => {
        if (source !== 'user') {
            return;
        }

        setValue(data);
        const copy = { ...bannerInfo };
        copy.text = data;
        updateBanners(copy);
        setTextError(false);
    };

    const onFinishFailed = () => {
        setTextError(true);
    };

    const linkHandler = (isText: boolean) => {
        const editor = quillRef.current?.getEditor();
        if (!isText || !editor) {
            return;
        }

        if (!editor.getSelection()?.length) {
            alertService.warn('Highlight text first', { fixed: true });
            return;
        }

        editor.theme.tooltip.edit('link', 'http://');
    };

    const onFieldsChange = (changedFields: Array<any>) => {
        if (changedFields[0]?.name[0] !== 'text') {
            const copy = { ...bannerInfo };
            if (changedFields[0]?.name[0] === 'linkTargetId') {
                const linkTarget = changedFields[0]?.value;
                copy.text = changeTargetForLink(copy.text, linkTarget);
                copy.openLinksInTextInNewTab = linkTarget === LinkTarget.NEW;
            } else {
                copy[changedFields[0]?.name[0]] = changedFields[0]?.value;
            }

            updateBanners(copy);
        }
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    ['image', 'link'],
                ],
                handlers: {
                    image: imageHandler,
                    link: linkHandler,
                },
            },
        }),
        [],
    );

    const saveButton = () => {
        return <CustomButton htmlType="submit">Save</CustomButton>;
    };

    return (
        <PromoBlockContainer>
            <Form
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
                autoComplete={'false'}
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
                onFieldsChange={onFieldsChange}
                initialValues={{
                    ['promoTypeId']: promoTypeId,
                    ['linkTargetId']: getInitialLinkTargetId(),
                }}
            >
                <BlueTitle>{`Promo block ${numberOnPage}`}</BlueTitle>
                <CustomInput
                    Component={Select}
                    label="Promo type"
                    name="promoTypeId"
                    block
                    validateOnChange={true}
                    props={{
                        options: promoTypesList,
                        suffixIcon: (
                            <Image
                                src="src/assets/images/caret-down.svg"
                                preview={false}
                                alt="caret-down"
                            />
                        ),
                    }}
                />
                <CustomInput Component={Input} validateOnChange={true} label="Headline" name="headLine" block />
                <CustomInput Component={Input} validateOnChange={true} label="Headline link" name="headLineLink" block />
                <ReactQuillContainer
                    id="input-container"
                    className={`required input-block' ${textError ? 'error' : ''}`}
                >
                    <div className="ant-form-item ant-form-item-has-success">
                        <div className="ant-row ant-form-item-row">
                            <div className="ant-col-6 ant-form-item-label">
                                <label htmlFor="Text">Text</label>
                            </div>
                            <div className="ant-col ant-form-item-control">
                                <ReactQuill
                                    ref={quillRef}
                                    modules={modules}
                                    value={value}
                                    onChange={onValueChange}
                                />
                            </div>
                        </div>
                    </div>
                </ReactQuillContainer>
                <CustomInput
                    Component={Select}
                    label="Select link target"
                    name="linkTargetId"
                    block
                    validateOnChange={true}
                    props={{
                        options: linkTargetsList,
                        suffixIcon: (
                            <Image
                                src="src/assets/images/caret-down.svg"
                                preview={false}
                                alt="caret-down"
                            />
                        ),
                    }}
                />
                <CustomInput Component={Input} validateOnChange={true} label="Button text" name="buttonText" block />
                <CustomInput Component={Input} validateOnChange={true} label="Button link" name="buttonLink" block />
                <div className="button-container">
                    <CustomInput Component={saveButton} label="button" name="button" block />
                </div>
            </Form>
        </PromoBlockContainer>
    );
};

export default PromoBlockItem;
