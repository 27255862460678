import { Image, Select } from 'antd';
import React from 'react';
import { SelectData } from '../../../models/common/types';
import { CreateProjectInitialValue } from '../../../models/project/types';
import { CustomSelectFormItem, OptionSpan } from './CustomSelect.styles';

const { Option } = Select;

interface Props {
    options: Array<SelectData | CreateProjectInitialValue>;
    name?: string;
    label?: string | React.ReactNode;
    placeholder?: string;
    required?: boolean;
    rules?: any;
    props?: any;
    id?: string;
    block?: boolean;
    value?: any;
    wrapLines?: boolean;
}
const CustomSelect: React.FC<Props> = ({
    id = 'input-container',
    label,
    name,
    required,
    options,
    placeholder,
    rules,
    props,
    block,
    value,
    wrapLines,
}) => {
    const getWrapLinesClassName = () => {
        return wrapLines ? 'wrapLines' : '';
    }

    const availableOptions = options.map((option, i) => (
        <Option value={option.id} key={option.id} className={`${name}-${i.toString()}`}>
            <OptionSpan className={getWrapLinesClassName()}>{option.name}</OptionSpan>
        </Option>
    ));

    return (
        <div id={id} className={`${required ? 'required' : ''} ${block ? 'input-block' : ''}`}>
            <CustomSelectFormItem name={name} label={<>{label}</>} rules={rules}>
                <Select
                    aria-required
                    placeholder={placeholder}
                    value={value}
                    {...props}
                    suffixIcon={
                        <Image
                            src="src/assets/images/ic-dropdown-arrow.svg"
                            preview={false}
                            alt="caret-down"
                        />
                    }
                >
                    {availableOptions}
                </Select>
            </CustomSelectFormItem>
        </div>
    );
};

export default CustomSelect;
