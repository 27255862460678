import React from 'react';
import {
    GetProjectInformationResponse,
    ProjectActivities,
} from '../../../../../redux/actions/projectActions/types';
import ProjectAuthorNotifications from '../projectAuthorNotifications/ProjectAuthorNotifications';
import {
    ActivityItemContainer,
    ProjectNotificationsPageDivider,
} from '../ProjectNotifications.styles';
import { ActivityContainer } from './ProjectDateNotifications.styles';

interface Props {
    projectActivity: ProjectActivities;
    reload: () => Promise<void>;
    projectInfo: GetProjectInformationResponse | null;
}

const ProjectDateNotifications: React.FC<Props> = ({ projectActivity, reload, projectInfo }) => {
    return (
        <>
            <ActivityItemContainer>
                <ActivityContainer>
                    {projectActivity.authors
                        .map((author) => ({
                            ...author,
                            lastModified: new Date(
                                author.activities[author.activities.length - 1].modifiedOn,
                            ),
                        }))
                        .sort((a, b) => b.lastModified.getTime() - a.lastModified.getTime())
                        .map((author, i) => (
                            <ProjectAuthorNotifications
                                key={i}
                                activityAuthor={author}
                                projectInfo={projectInfo}
                                reload={reload}
                            />
                        ))}
                </ActivityContainer>
            </ActivityItemContainer>
        </>
    );
};

export default ProjectDateNotifications;
