import { Form, Input, Image, Checkbox, UploadProps, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';

import {
    ProjectVisibilityTextTipContainerProjectInfo,
    ProjectVisibilityTextTipContainerStartProject,
} from './WhoCanSeeThisProject.styles';


interface Props {
    page: string,
}

const WhoCanSeeThisProject: React.FC<Props> = ({ page = 'ProjectInfo' }) => {

    const TooltipContain = () => {
        return (
           <>
                                                    <Image
                                                        width={13}
                                                        preview={false}
                                                        src="src/assets/images/circle-question.svg"
                                                    />
                                                    <div className="tip">
                                                        <div style={{marginBottom: 0 + `px`}}>
                                                            <strong>Anyone in my organization with an account</strong>
                                                        </div>
                                                        <div>
                                                            <ul>
                                                                <li>Use to make a Project visible to everyone, such as a collection of recommended products.</li>
                                                                <li>People outside your organization can be invited to these Projects.</li>
                                                            </ul>
                                                        </div>

                                                        <div style={{marginBottom: 0 + `px`}}>
                                                            <strong>Only Members, people I invite and Admins</strong>
                                                        </div>
                                                        <div>
                                                            <ul>
                                                                <li>Use to make a Project visible to all Member account-holders to copy, create Templates, or add products to a Favorite Products Library.</li>
                                                                <li>Associate account-holders and people outside your organization must be invited to these Projects to see them.</li>
                                                            </ul>
                                                        </div>

                                                        <div style={{marginBottom: 0 + `px`}}>
                                                            <strong>Only people I invite and Admins</strong>
                                                        </div>
                                                        <div>
                                                            <ul>
                                                                <li>Use for Projects limited to specific team members. Best for confidential projects.</li>
                                                                <li>Any other account-holders and people outside your organization must be invited to these Projects to see them.</li>
                                                            </ul>
                                                        </div>

                                                        <div style={{marginBottom: 0 + `px`}}>
                                                            <a href="https://transparencycatalog.zendesk.com/hc/en-us/articles/12889819742098-Account-types" target="_blank"><strong>Learn more about account types</strong></a>
                                                        </div>
                                                     </div>
            </>
        );
    };

    return (
        <>
            {page === `StartProject` && (
                <ProjectVisibilityTextTipContainerStartProject>
                    <TooltipContain/>
                </ProjectVisibilityTextTipContainerStartProject>
            )}
            {page === `ProjectInfo` && (
                <ProjectVisibilityTextTipContainerProjectInfo>
                    <TooltipContain/>
                </ProjectVisibilityTextTipContainerProjectInfo>
            )}
        </>
    );
};

export default WhoCanSeeThisProject;
