import React, { useState } from 'react';
import { Input } from 'antd';
import { CreateAccountModal, PromptModalText } from './OfficePromptModal.styles';
import { CloseIcon } from '../../../styles/common.styles';
import ModalTitle from '../modalTitle/ModalTitle';
import ModalFooter from '../modalFooter/ModalFooter';
import CustomInput from '../inputs/CustomInput';
import { useAppDispatch } from '../../../hooks/redux';
import { changeFavoriteNotesAction } from '../../../redux/actions/favoriteProductsActions';
import { FavoriteType } from '../../../models/product/enums';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    productId?: number;
}

const OfficePromptModal: React.FC<Props> = ({ isOpen, closeModal, productId }) => {
    const dispatch = useAppDispatch();

    const [state, setState] = useState('');

    const saveNotes = async () => {
        if (productId) {
            closeModal();
            await dispatch(
                changeFavoriteNotesAction({
                    favoriteType: FavoriteType.OfficeFavorite,
                    productFavoriteId: productId,
                    notes: state?.trim() || '',
                }),
            );
        }
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(event.target.value);
    };
    return (
        <CreateAccountModal
            open={isOpen}
            onCancel={closeModal}
            footer={null}
            centered
            closeIcon={<CloseIcon />}
        >
            <ModalTitle
                title="Add to your office’s Favorite Products Library"
                icon="star-prompt-modal.svg"
            />
            <PromptModalText>
Add an explanation about why you're adding this to your organization's Library. This will display when people browse the Library. (50 words)
            </PromptModalText>
            <CustomInput
                Component={Input}
                label="Notes"
                name="notes"
                block
                props={{ onChange: handleInput }}
            />
            <ModalFooter action="Add product" cancel={closeModal} submit={saveNotes} />
        </CreateAccountModal>
    );
};

export default OfficePromptModal;
