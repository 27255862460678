import React from 'react';
import Switcher from '../../../../../components/common/switcher/Switcher';
import { Section } from '../../../../../models/productDivision/types';
import { BrandCompany, GroupedProduct, Product } from '../../../../../models/product/types';
import { ProductReferences } from '../../../../../redux/actions/productActions/types';
import { blueDark01 } from '../../../../../styles/colors';
import { Text } from '../../../../../styles/common.styles';
import ProductItem from '../productItem/ProductItem';
import {
    MFRTitleContainer,
    ProductsContainer,
    ProductScrollableContainer,
    SectionItemContainer,
    SectionItemDivider,
    SectionItemTitleContainer,
    SectionItemWrapper,
    SectionTitle,
} from './SectionItem.styles';
import { GetProjectInformationResponse } from '../../../../../redux/actions/projectActions/types';

interface Props {
    section: Section<Product>;
    references: ProductReferences | null;
    editable: boolean;
    projectInfo: GetProjectInformationResponse | null;
    brandsInfo: Array<BrandCompany>;
    lastSection: boolean;
    installedTab?: boolean;
    handleClicked: (id: string) => void;
    updateDivisions: (product: Product, isInstalled?: boolean) => void;
    handleDeleteProduct: (product: Product) => void;
}

const SectionItem: React.FC<Props> = ({
    section,
    references,
    editable,
    projectInfo,
    brandsInfo,
    lastSection,
    installedTab,
    updateDivisions,
    handleClicked,
    handleDeleteProduct,
}) => {
    let masterFormatProducts = Array<GroupedProduct<Product>>();
    const groupedMap = section.products.reduce(
        (entryMap, e) =>
            entryMap.set(e.lowestLevelNumber as any, [
                ...(entryMap.get(e.lowestLevelNumber as any) || []),
                e,
            ]),
        new Map<string | undefined, Array<Product>>(),
    );
    for (const [key, value] of groupedMap.entries()) {
        masterFormatProducts.push({
            products: value,
            number: key,
            label: value[0].lowestLevelLabel,
        });
    }
    masterFormatProducts = sortByKey(masterFormatProducts, 'number');
    for (const mfProducts of masterFormatProducts) {
       mfProducts.products = sortByKey(mfProducts.products, 'productName');
    }

function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];

        if (typeof x == "string")
        {
            x = (""+x).toLowerCase();
        }
        if (typeof y == "string")
        {
            y = (""+y).toLowerCase();
        }

        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}
    return (
        <>
            <SectionItemDivider />
            <SectionItemWrapper>
                <SectionItemContainer>
                    <SectionItemTitleContainer
                        onClick={() => {
                            handleClicked(section.id);
                        }}
                    >
                        <Switcher
                            id={section.id}
                            clicked={section.clicked}
                            handleSwitch={() => handleClicked(section.id)}
                            width={20}
                            height={20}
                            small
                        />
                        <SectionTitle className="section-id hover">{section.id}</SectionTitle>
                        <SectionTitle className="hover">{section.name}</SectionTitle>{' '}
                        <Text
                            weight={400}
                            size={16}
                            height={20}
                            color={blueDark01}
                            className="hover"
                        >
                            ({section.products.length})
                        </Text>
                    </SectionItemTitleContainer>
                </SectionItemContainer>
            </SectionItemWrapper>
            <ProductsContainer className={section.clicked ? 'clicked' : ''}>
                {masterFormatProducts.map((group, j) => (
                    <ProductScrollableContainer key={j}>
                        <SectionItemDivider />
                        <MFRTitleContainer className={j ? 'sec' : ''}>
                            <SectionTitle className="section-id mfr">{group.number}</SectionTitle>
                            <SectionTitle className="mfr">{`${group.label}`}</SectionTitle>{' '}
                            <Text
                                weight={400}
                                size={14}
                                height={18}
                                color={blueDark01}
                                className="hover"
                            >
                                ({group.products.length})
                            </Text>
                        </MFRTitleContainer>
                        <SectionItemDivider />
                        {sortByKey(group.products, 'company')
                            .map((product, i, products) => (
                                <ProductItem
                                    previousProduct={i ? products[i - 1] : undefined}
                                    key={product.id}
                                    product={product}
                                    references={references}
                                    divider={
                                        products[i + 1] !== undefined
                                            ? product.company !== products[i + 1].company
                                                ? true
                                                : false
                                            : false
                                    }
                                    projectInfo={projectInfo}
                                    updateDivisions={updateDivisions}
                                    handleDeleteProduct={handleDeleteProduct}
                                    editable={editable}
                                    brandInfo={brandsInfo.find((b) => b.id === product.companyID)!}
                                    lastProduct={
                                        masterFormatProducts.length === j + 1 &&
                                        group.products.length === i + 1 &&
                                        lastSection
                                    }
                                    installedTab={installedTab}
                                />
                            ))}
                    </ProductScrollableContainer>
                ))}
            </ProductsContainer>
        </>
    );
};

export default SectionItem;
