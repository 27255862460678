import React, { useEffect, useState } from 'react';
import { PageContainer, Text, SpaceEnd } from '../../../../styles/common.styles';
import {
    FilterContainer,
    ProjectNotificationsPageDivider,
    NotificationContainer,
    CustomTopContainer,
    CustomBottomContainer,
    ChangePageSizeContainer,
    CustomTableSpace,
} from './ProjectNotifications.styles';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Spin, Select, Image, Space } from 'antd';
import { UsersPageActionsButton } from '../../../users/UsersPage.styles';
import { grayDark03 } from '../../../../styles/colors';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useModalState } from '../../../../hooks/modalState';
import { getProjectActivitiesAction } from '../../../../redux/actions/projectActions';
import {
    GetProjectInformationResponse,
    ProjectActivities,
    ProjectActivitiesResponse,
} from '../../../../redux/actions/projectActions/types';
import { actionMenu, activitiesGroup } from './ProjectNotifications.mockData';
import { ActionResponse } from '../../../../models/actionResponse/types';
import { getExternalProductsAction } from '../../../../redux/actions/transparencyCatalogActions';
import { GetProductsFromTransparencyCatalogAPIResponse } from '../../../../redux/actions/transparencyCatalogActions/types';
import { ExternalProduct } from '../../../../models/product/types';
import CustomPagination from '../../../../components/common/pagination/CustomPagination';
import ErrorModal from '../../../../components/common/errorModal/ErrorModal';
import NotificationDateSection from './notificationDateSection/NotificationDateSection';
import { toShortLocalDate } from '../../../../helpers/dateHelper';
import { NotificationSection } from '../../../../models/projectNotification/types';

interface Props {
    projectInfo: GetProjectInformationResponse | null;
}

const ProjectNotifications: React.FC<Props> = ({ projectInfo }) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state);
    const { projectReducer } = selector;

    const [currentNotifications, setCurrentNotifications] = useState('All notifications');
    const [allActivities, setAllActivities] = useState(Array<ProjectActivities>());
    const [currentActivities, setCurrentActivities] = useState(Array<ProjectActivities>());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedShowAction, setSelectedShowAction] = useState(actionMenu[0].key);
    const [pageSize] = useState(10);
    const [pageNumberSet, setPageNumberSet] = useState(1);
    const [notificationSections, setNotificationSections] = useState<Array<NotificationSection>>(
        [],
    );
    const errorModal = useModalState();

    useEffect(() => {
        getActivities();
    }, [projectInfo, pageSize]);

    useEffect(() => {
        const sections: NotificationSection[] = [];
        currentActivities.forEach((activity, i) => {
            sections.push({
                id: String(i + 1),
                name: toShortLocalDate(activity.date),
                key: i,
                activities: activity,
                clicked: i === 0 ? true : false,
            });
        });
        setNotificationSections(sections);
    }, [currentActivities]);

    const getActivities = async (pageNumber: number = currentPage, selectedShow: string = selectedShowAction) => {
        if (projectInfo) {
            const { payload } = (await dispatch(
                getProjectActivitiesAction({
                    projectId: projectInfo.id,
                    curPage: pageNumber,
                    pageSize: pageSize,
                    showAction: selectedShow,
                }),
            )) as ActionResponse<ProjectActivitiesResponse>;
            if (payload?.data) {
                await addProductsName(payload.data.notifications);
                setAllActivities(payload.data.notifications);
                setCurrentActivities(payload.data.notifications);
                setTotalPages(payload?.data.totalPages);
                if (Math.ceil(payload?.data.totalPages / pageSize) < pageNumber) {
                    setPage(Math.ceil(payload?.data.totalPages / pageSize), "no");
                }
            } else {
                errorModal.onOpen();
            }
        }
    };

    const handleMenuSelect = async (selectedItem: string) => {
        const data = {key: selectedItem};
        setSelectedShowAction(selectedItem);
        setPageNumberSet(1);
        setCurrentPage(1);
        getActivities(1, selectedItem);
        setCurrentNotifications(actionMenu.find((item) => item.key === data.key)?.label || '');
        if (data.key === 'all') {
            setCurrentActivities(allActivities);
            return;
        }
console.log(activitiesGroup);
        const filter = activitiesGroup[data.key] as Array<string>;
        const newAllActivities = JSON.parse(
            JSON.stringify(allActivities),
        ) as Array<ProjectActivities>;
        const activities = newAllActivities.reduce((acc, item) => {
            const filtered = filter ? item.authors.filter((author) => filter.includes(author.activityName)) : item.authors;
            if (filtered.length) {
                acc.push(item);
                item.authors = filtered;
            }
            return acc;
        }, Array<ProjectActivities>());
        setCurrentActivities(activities);
    };

    const addProductsName = async (activities: Array<ProjectActivities>) => {
        const productIds = [
            ...new Set(
                activities
                    .map((a) => a.authors)
                    .reduce((acc, item) => [...acc, item], [])
                    .reduce((acc, item) => (acc as any).concat(item), [])
                    .map((a: any) => a.activities)
                    .flat()
                    .map((a: any) => a.projectProduct)
                    .map((a: any) => a?.productId),
            ),
        ].filter((id: number) => id);
        if (productIds && productIds.length) {
            const { payload } = (await dispatch(
                getExternalProductsAction({
                    productIds: productIds as Array<number>,
                }),
            )) as ActionResponse<GetProductsFromTransparencyCatalogAPIResponse>;
            if (payload.data) {
                const products = Object.values(payload.data.mfSection).reduce(
                    (agg, item: any) => agg.concat(item),
                    Array<ExternalProduct>(),
                );
                activities.map((activity) => {
                    activity.authors = activity.authors.map((author) => {
                        author.activities = author.activities.map((authorActivity) => {
                            if (authorActivity.projectProduct) {
                                authorActivity.projectProduct.productName = products.find(
                                    (product) =>
                                        product.id === authorActivity.projectProduct.productId,
                                )?.productName;
                            }
                            return authorActivity;
                        });
                        return author;
                    });
                    return activity;
                });
            }
        }
    };

    const handleInstalledDivisionOpen = (id: string) => {
        const index = notificationSections.findIndex((division) => division.id === id);
        if (index !== -1) {
            const currentSections = [...notificationSections];
            currentSections[index].clicked = !currentSections[index].clicked;
            setNotificationSections(currentSections);
        }
    };

    const handleCurrentPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentCount = event.currentTarget.value;
        if (/^-?\d*\.?\d*$/.test(currentCount) || currentCount === '') {
            setCurrentPage(+event.currentTarget.value);
            if (+currentCount > Math.ceil(totalPages / pageSize)) {
                setTimeout(() => {
                    setPage(Math.ceil(totalPages / pageSize), "no");
                }, 1000);
            }
        }
        if (
            +event.currentTarget.value > 0 &&
            +event.currentTarget.value <= Math.ceil(totalPages / pageSize)
        ) {
            setPage(+event.currentTarget.value, "no");
        }
    };

    const setPage = (pageNumber: number, reloadActivities: string = "yes") => {
        setPageNumberSet(pageNumber);
        setCurrentPage(pageNumber);
console.log('setPage');
        if (reloadActivities === "yes") {
            getActivities(pageNumber);
        }
    };

    return (
        <>
            <PageContainer>

            <CustomTableSpace direction="horizontal" style={{marginBottom: 10 + `px`}}>
                <Space>
                    <FilterContainer>
                      <Text weight={400} height={16} size={13} color={grayDark03}>
                          Show
                      </Text>
                      <Select
                          aria-required
                          onChange={handleMenuSelect}
                          value={selectedShowAction}
                          suffixIcon={
                              <Image
                                  src="src/assets/images/ic-dropdown-arrow.svg"
                                     preview={false}
                              />
                          }
                       >
                           {actionMenu
                               ? actionMenu.map((item) => (
                                   <Option style={{fontSize: 12}} value={item.key} key={item.key}>
                                       {item.label}
                                   </Option>
                                 ))
                               : null}
                      </Select>
                      </FilterContainer>
                  </Space>
                  <SpaceEnd>
                      <CustomTopContainer direction="horizontal" style={{marginTop: 0 + `px`}}>
                          <CustomPagination
                              page={currentPage}
                              total={totalPages}
                              changePageNumber={setPage}
                              handleCurrentPage={handleCurrentPage}
                              pageSize={pageSize}
                          />
                      </CustomTopContainer>
                  </SpaceEnd>
            </CustomTableSpace>
                <Spin size="large" spinning={projectReducer.pending}>
                    <NotificationContainer>
                        {notificationSections.map((section, i) => (
                            <NotificationDateSection
                                notification={section}
                                key={i}
                                projectInfo={projectInfo}
                                handleClicked={handleInstalledDivisionOpen}
                                handleReload={getActivities}
                            />
                        ))}
                    </NotificationContainer>
                </Spin>
                <CustomBottomContainer direction="horizontal">
                    <CustomPagination
                        page={currentPage}
                        total={totalPages}
                        changePageNumber={setPage}
                        handleCurrentPage={handleCurrentPage}
                        pageSize={pageSize}
                    />
                </CustomBottomContainer>
            </PageContainer>
            {errorModal.isOpen && (
                <ErrorModal isOpen={errorModal.isOpen} closeModal={errorModal.onClose} />
            )}
        </>
    );
};

export default ProjectNotifications;
